import React, { useState } from 'react';
import { observer } from 'mobx-react';
import { API, useTitle } from 'htcore';
import apiMethods from 'common/api-methods';
import FormAgentData from 'pages/signup/form-agent-data';
import { Loader } from 'components/simple';
import { CachedForm } from 'components/form';
import { registrationAgentValidator } from 'components/form/validation';
import { $auth } from 'stores';

const PersonalInformation = observer(() => {
    useTitle('Personal Details');
    const [loading, setLoading] = useState(false);

    const submitAgentData = (values) => {
        setLoading(true);
        API.put({
            url: apiMethods.AGENT_PROPERTIES,
            body: values,
            success: (result) => {
                $auth.setInformation({
                    ...$auth.information,
                    ...result,
                });
            },
            after: () => setLoading(false),
        });
    };

    return (
        <div className="cabinet block">
            <section>
                {loading && <Loader page />}

                <h2>Personal Details</h2>

                <CachedForm
                    onSubmit={submitAgentData}
                    validationSchema={registrationAgentValidator}
                    initialValues={$auth.information}
                    render={(formik) => (
                        <div className="form agent-data">
                            <FormAgentData formik={formik} />
                            <div className="row controls">
                                <div className="field">
                                    <div className="inner">
                                        <button
                                            type="submit"
                                            className={
                                                'button' +
                                                __class(!formik.isValid || !formik.dirty, 'disabled')
                                            }
                                            disabled={!formik.isValid || !formik.dirty}
                                        >
                                            Save Changes
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}
                />
            </section>
        </div>
    );
});

export default PersonalInformation;
