import React, { useState } from 'react';
import { API } from 'htcore';
import apiMethods from 'common/api-methods';
import { INVOICE_TYPES } from 'enum';
import SendDocumentModal from './send-document-modal';
import { $notifications } from 'stores';

const createRequestParams = (allVouchers) => ({
    url: allVouchers ? apiMethods.BOOKING_ALL_VOUCHERS_ARCHIVE : apiMethods.BOOKING_VOUCHER_PDF,
    errorMessage: allVouchers ? "Couldn't get a Vouchers archive" : "Couldn't get a Voucher file",
    format: allVouchers ? 'zip' : 'pdf',
});

export const downloadVoucher = (bookingId, referenceCode, allVouchers) => {
    const { url, errorMessage, format } = createRequestParams(allVouchers);

    API.get({
        url: url(bookingId),
        response: (res) => {
            if (res.status === 400) $notifications.addNotification(errorMessage);
            if (res.status === 200)
                res.blob().then((blobby) => {
                    let anchor = document.createElement('a');
                    document.body.appendChild(anchor);

                    const objectUrl = window.URL.createObjectURL(blobby);
                    anchor.href = objectUrl;
                    anchor.download = `voucher-${referenceCode}.${format}`;
                    anchor.click();

                    window.URL.revokeObjectURL(objectUrl);
                });
        },
    });
};

const AccommodationConfirmationVoucherButtons = ({ isItineraryBooking, bookingId, referenceCode }) => {
    const [modalVisibility, setModalVisibility] = useState(false);

    return (
        <>
            <div onClick={() => downloadVoucher(bookingId, referenceCode)} className="line">
                Voucher File
            </div>
            {isItineraryBooking && (
                <div onClick={() => downloadVoucher(bookingId, referenceCode, true)} className="line">
                    Load All Vouchers
                </div>
            )}
            <div onClick={() => setModalVisibility(Date.now())} className="line">
                Send Voucher Email
            </div>
            <SendDocumentModal
                key={modalVisibility}
                isOpen={modalVisibility}
                onClose={() => setModalVisibility(false)}
                type={INVOICE_TYPES.VOUCHER}
                bookingId={bookingId}
                referenceCode={referenceCode}
            />
        </>
    );
};

export default AccommodationConfirmationVoucherButtons;
