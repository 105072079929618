import React, { useState } from 'react';
import { getIn } from 'formik';

const NestedTableRow = ({ row, onRowClick, parentColumns, columns }) => {
    const [isOpen, setIsOpen] = useState(false);

    return (
        <>
            <tr onClick={() => setIsOpen(!isOpen)} className={__class(onRowClick, 'clickable parent-row')}>
                {parentColumns.map((column, index) => (
                    <td key={index}>
                        <div className={__class(!index, 'parent-cell')}>
                            {!index && (
                                <div className={__class(isOpen, 'icon-rotated') + ' arrow-wrapper'}>
                                    <span className="icon icon-arrow-next"></span>
                                </div>
                            )}
                            <div>
                                <strong>{column.header}</strong>
                                {typeof column.cell === 'function'
                                    ? column.cell(row)
                                    : getIn(row, column.cell)}
                            </div>
                        </div>
                    </td>
                ))}
            </tr>

            {isOpen &&
                row.children.map((children, index) => (
                    <tr
                        key={index}
                        onClick={onRowClick ? () => onRowClick(children) : null}
                        className={__class(onRowClick, 'clickable table')}
                    >
                        <td style={{ padding: 0 }} colSpan={parentColumns.length}>
                            <table className="the-table children-row">
                                <tbody>
                                    <tr>
                                        {columns.map((column, index) => (
                                            <td key={index}>
                                                <strong>{column.header}</strong>
                                                {typeof column.cell === 'function'
                                                    ? column.cell(children)
                                                    : getIn(children, column.cell)}
                                            </td>
                                        ))}
                                    </tr>
                                </tbody>
                            </table>
                        </td>
                    </tr>
                ))}
        </>
    );
};

export default NestedTableRow;
