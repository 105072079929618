import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import { API } from 'htcore';
import apiMethods from 'common/api-methods';
import { date, PassengerName, price } from 'simple';
import { Loader } from 'components/simple';
import Table from 'components/table/table';
import ModalTemplate from 'common/template/modal-template';
import { $notifications } from '../../../stores';

const CancellationConfirmationModal = observer((props) => {
    const { booking, onCancel, isOpen, onClose, isRoomCancellationAvailable, totalPrice } = props;
    const { bookingId, bookingDetails } = booking;
    const { referenceCode } = bookingDetails;

    const [penalty, setPenalty] = useState(null);
    const [roomCancellationDetails, setRoomCancellationDetails] = useState(null);
    const [loading, setLoading] = useState(false);
    const [byRoomView, setByRoomView] = useState(isRoomCancellationAvailable);

    useEffect(() => {
        setByRoomView(isRoomCancellationAvailable);
        if (!isOpen) {
            setRoomCancellationDetails(null);
        }
    }, [isOpen, isRoomCancellationAvailable]);

    useEffect(() => {
        API.get({
            url: apiMethods.BOOKING_PENALTY(bookingId),
            success: setPenalty,
        });
    }, [bookingId]);

    const bookingCancel = () => {
        setLoading(true);
        API.post({
            url: apiMethods.BOOKING_CANCEL(bookingId),
            success: () => {
                $notifications.addNotification('Booking Cancelled!', null, 'success');
                onCancel();
                onClose();
            },
            error: () => setLoading(false),
        });
    };

    const checkRoomCancellation = (room) => {
        setLoading(true);
        API.post({
            url: apiMethods.BOOKING_ROOM_CANCELLATION_AVAILABILITY(referenceCode),
            body: [room.supplierRoomReferenceCode],
            success: (data) => {
                if (data?.isAllowed) {
                    setRoomCancellationDetails({ room, data });
                } else {
                    $notifications.addNotification('Can`t cancel this room');
                }
            },
            after: () => setLoading(false),
        });
    };

    const cancelRoom = () => {
        setLoading(true);
        API.post({
            url: apiMethods.BOOKING_CANCEL_ROOM(referenceCode),
            body: [roomCancellationDetails.room.supplierRoomReferenceCode],
            success: () => {
                $notifications.addNotification('Room Cancelled!', null, 'success');
                onCancel();
                onClose();
            },
            after: () => setLoading(false),
        });
    };

    if (!isOpen) return null;

    if (roomCancellationDetails) {
        return (
            <ModalTemplate addClass="cancellation-room-modal" {...props}>
                {loading && <Loader page />}

                <h2>Cancellation Confirmation</h2>

                <div className="text">
                    <div style={{ textAlign: 'left' }}>
                        Are you sure want to cancel the room?
                        <br />
                        <b>{roomCancellationDetails.room.contractDescription}</b>
                        <br />
                        Guest: <PassengerName passenger={roomCancellationDetails.room.passengers[0]} />
                    </div>
                    Room Cost: <b>{price(roomCancellationDetails.room.price)}</b>
                    <br />
                    Will be refunded:{' '}
                    <strong className="final-price">
                        {price(roomCancellationDetails.data.priceInfo.refundAmount)}
                    </strong>
                    <br />
                    {totalPrice.currency === roomCancellationDetails.data.priceInfo.refundAmount.currency && (
                        <>
                            Final Booking Cost: <span style={{ opacity: '.8' }}>{price(totalPrice)}</span> →{' '}
                            <strong className="final-price">
                                {price(
                                    totalPrice.currency,
                                    totalPrice.amount -
                                        roomCancellationDetails.data.priceInfo.refundAmount.amount
                                )}
                            </strong>
                        </>
                    )}
                </div>

                <div className="bottom">
                    <button className="button" onClick={onClose}>
                        Do Nothing
                    </button>
                    <button className="button main" onClick={cancelRoom}>
                        Cancel Room
                    </button>
                </div>
            </ModalTemplate>
        );
    }

    if (byRoomView) {
        return (
            <ModalTemplate addClass="cancellation-room-modal" {...props}>
                {loading && <Loader page />}

                <h2 style={{ textAlign: 'center' }}>Modify & Cancel Booking</h2>

                <Table
                    columns={[
                        {
                            header: 'Room',
                            cell: (item) => (
                                <div>
                                    <div>
                                        {item.contractName}
                                        {item.contractDescription ? ` (${item.contractDescription})` : ''}
                                    </div>

                                    <span className="room-code" style={{ fontSize: 10, fontWeight: 600 }}>
                                        {item.roomReferenceCode || room.roomCode}
                                    </span>
                                    <br />
                                    <div style={{ fontSize: 10 }}>
                                        <PassengerName passenger={item.passengers[0]} />
                                    </div>
                                </div>
                            ),
                        },
                        {
                            header: 'Price',
                            cell: (item) => price(item.price),
                        },
                        {
                            header: 'Deadline',
                            cell: (item) =>
                                date.format.c(item.deadlineDetails.date) +
                                ' ' +
                                date.format.time(item.deadlineDetails.date),
                        },
                        {
                            header: '',
                            cell: (item) =>
                                item.cancelled ? (
                                    'Room Cancelled'
                                ) : (
                                    <button
                                        className="button main"
                                        onClick={() => checkRoomCancellation(item)}
                                    >
                                        Cancel&nbsp;Room
                                    </button>
                                ),
                        },
                    ]}
                    list={bookingDetails.roomDetails}
                />

                <div className="bottom">
                    <button className="button" onClick={() => setByRoomView(false)}>
                        Cancel Booking
                    </button>
                </div>
            </ModalTemplate>
        );
    }

    return (
        <ModalTemplate addClass="cancellation-room-modal" {...props}>
            {loading && <Loader page />}

            <h2>Cancellation Confirmation</h2>

            <div className="text">
                <div style={{ textAlign: 'left' }}>
                    Are you sure you want to cancel the booking?
                    <br />
                    {bookingDetails.referenceCode}
                    <br />
                    Guest: <PassengerName passenger={bookingDetails.roomDetails[0].passengers[0]} />
                </div>
                Booking Cost: {price(totalPrice)}
                <br />
                Cancellation cost: <strong className="cancellation-cost">{price(penalty)}</strong>
                <br />
                {totalPrice.currency === penalty?.currency && (
                    <>
                        Will be refunded:{' '}
                        <strong className="final-price">
                            {price(totalPrice.currency, totalPrice.amount - penalty.amount)}
                        </strong>
                        <br />
                    </>
                )}
                Final Booking Cost: <span style={{ opacity: '.8' }}>{price(totalPrice)}</span> →{' '}
                <strong className="final-price">{price(penalty)}</strong>
            </div>

            <div className="bottom">
                <button className="button" onClick={onClose}>
                    Do Nothing
                </button>
                <button className="button main" onClick={bookingCancel}>
                    Cancel Booking
                </button>
            </div>
        </ModalTemplate>
    );
});

export default CancellationConfirmationModal;
