import React, { useEffect } from 'react';
import { observer } from 'mobx-react';
import { FieldArray } from 'formik';
import { GroupRoomTypesAndCount, price } from 'simple';
import { Loader } from 'components/simple';
import { useTitle } from 'htcore';
import {
    CachedForm,
    FORM_NAMES,
    FieldText,
    FieldCheckbox,
    FieldSelect,
    FieldChildren,
    FieldTextarea,
} from 'components/form';
import Breadcrumbs from 'components/navigation/breadcrumbs';
import { Link, useNavigate } from 'react-router-dom';
import { accommodationBookingValidator } from 'components/form/validation';
import { Allotment } from 'components/accommodation';
import transliterate from 'components/external/transliterate';
import ViewFailed from 'common/misc/view-failed';
import { PAYMENT_METHODS } from 'enum';
import BookingSummary from '../parts/booking-summary';
import taskSubmitBookingForm from 'tasks/booking/booking-submit';
import { loadAgency } from 'tasks/utils/agent-settings';
import PaymentMethodSelector from './booking-payment-method-selector';
import BookingSurrogateAgency from './booking-surrogate-agency';
import ItineraryNotification from '../itinerary-notification';
import { $accommodation, $auth, $payment, $ui } from 'stores';

const AccommodationBookingPage = observer(() => {
    useTitle('Accommodation Booking');
    const navigate = useNavigate();

    useEffect(() => {
        $accommodation.setBookingRequest(null);
        if (!$auth.agency) {
            loadAgency();
        }
    }, []);

    if (!$accommodation.selected?.accommodationFinal?.accommodation?.htId)
        return <ViewFailed button="View Other Options" link="/search/results" />;

    let accommodation = $accommodation.selected.accommodationFinal.accommodation,
        baseInfo = $accommodation.selected.accommodationFinal,
        contract = $accommodation.selected.roomContractSet,
        initialValues = {
            room:
                contract?.rooms?.map((item) => ({
                    passengers: [
                        ...Array(item?.adultsNumber),
                        ...Array(item?.childrenAges.length).fill({
                            title: 'Mr',
                        }),
                    ],
                })) || [],
            accepted: true,
            itineraryNumber: '',
            surrogateAgencyId: $ui.itnModeAdditionalData?.surrogateAgencyId || null,
            surrogateAgencyIdCode: $ui.itnModeAdditionalData?.surrogateAgencyName || null,
        };

    if (!contract) return null;

    const isOnRequestOnly = Boolean(contract.rooms.find((item) => item.isAvailableImmediately === false));

    const ContinueButton = ({ formik }) => (
        <button type="submit" className={'button main' + __class(!formik.isValid, 'disabled')}>
            {formik.isValid || !formik.values.accepted
                ? isOnRequestOnly
                    ? 'Send Booking Request'
                    : PAYMENT_METHODS.ACCOUNT === $payment.paymentMethod
                    ? 'Book & Pay' + price(contract.rate.finalPrice)
                    : 'Book & Confirm'
                : 'You have not filled guests information'}
        </button>
    );

    const SpecialRequest =
        baseInfo.specialRequestsSupport === 'PartiallySupported' ||
        baseInfo.specialRequestsSupport === 'FullySupported'
            ? ({ formik }) => (
                  <div>
                      <div>
                          <FieldTextarea
                              formik={formik}
                              id="specialRequest"
                              label="Special Request"
                              placeholder="Add Special Request Here"
                              maxLength={250}
                          />
                      </div>
                      <div style={{ marginTop: 12 }}>
                          Please note that the special request is not guaranteed from Happytravel.com side
                      </div>
                  </div>
              )
            : null;

    return (
        <div className="booking block">
            <ItineraryNotification />
            <div className="hide">{JSON.stringify($payment.paymentMethod)}</div>
            <section>
                <Breadcrumbs backText="Back to Room Selection" backLink="/search/contract" />
                <CachedForm
                    id={FORM_NAMES.BookingForm}
                    cacheValidator={(cache) => {
                        if (cache?.room?.length !== initialValues?.room.length) return false;
                        for (let i = 0; i < initialValues?.room.length; i++)
                            if (
                                cache?.room?.[i]?.passengers?.length !==
                                initialValues?.room[i].passengers.length
                            )
                                return false;
                        return true;
                    }}
                    initialValues={initialValues}
                    validationSchema={accommodationBookingValidator}
                    onSubmit={(values, formikBag, addons) =>
                        taskSubmitBookingForm(values, formikBag, addons, navigate)
                    }
                    render={(formik) => (
                        <div className="billet-wrapper">
                            <div className="billet">
                                <BookingSummary
                                    details={accommodation}
                                    contract={contract}
                                    checkInDate={baseInfo.checkInDate}
                                    checkOutDate={baseInfo.checkOutDate}
                                    numberOfNights={$accommodation.search.numberOfNights}
                                    numberOfGuests={
                                        $accommodation.search.request.adultsTotal +
                                        $accommodation.search.request.childrenTotal
                                    }
                                />
                                {$auth.permitted('CreateBooking') &&
                                Boolean(contract.availablePaymentMethods.length) ? (
                                    <>
                                        {isOnRequestOnly && (
                                            <>
                                                <div className="accent-frame" style={{ marginBottom: 0 }}>
                                                    <div className="data only">
                                                        Please note, that this booking is available on request
                                                        only.
                                                    </div>
                                                </div>
                                            </>
                                        )}
                                        {!isOnRequestOnly && (
                                            <PaymentMethodSelector
                                                contractPaymentMethods={contract.availablePaymentMethods}
                                            />
                                        )}
                                        {!!contract.priceChangedAlert && (
                                            <div className="accent-frame">
                                                <div className="data only">
                                                    <div>Please note the booking price has changed</div>
                                                    To speed up a search on a large number of accommodations,
                                                    we use preloaded data. Sometimes the data may become
                                                    outdated while you work with the site. When this happens,
                                                    you may see a change in price or in cancellation policies
                                                    on this screen. The last shown price is final.
                                                    <ContinueButton formik={formik} />
                                                </div>
                                            </div>
                                        )}
                                        <div>
                                            {!contract.priceChangedAlert && (
                                                <ContinueButton formik={formik} />
                                            )}
                                        </div>
                                        <div className="checkbox-holder">
                                            <FieldCheckbox
                                                formik={formik}
                                                id="accepted"
                                                label={
                                                    <>
                                                        I have read and accepted
                                                        <Link
                                                            target="_blank"
                                                            to="/terms"
                                                            className="underlined link"
                                                            onClick={(event) => event.stopPropagation()}
                                                        >
                                                            Terms & Conditions
                                                        </Link>
                                                    </>
                                                }
                                            />
                                        </div>
                                    </>
                                ) : (
                                    <div style={{ marginTop: 40, fontSize: '15px', lineHeight: '24px' }}>
                                        Please contact your Happy Travel manager if you want to book this
                                        accommodation
                                    </div>
                                )}
                            </div>
                            <div className="another">
                                {formik.isSubmitting && <Loader page />}
                                <div className="form">
                                    <FieldArray
                                        render={() =>
                                            contract?.rooms.map((room, r) => (
                                                <div className="room-container" key={r}>
                                                    <h2>
                                                        <GroupRoomTypesAndCount text contracts={[room]} />
                                                    </h2>
                                                    {Boolean(contract.availablePaymentMethods.length) && (
                                                        <table className="room-details">
                                                            <tbody>
                                                                <FieldArray
                                                                    render={() =>
                                                                        formik.values.room[r].passengers.map(
                                                                            (passengers, index) => (
                                                                                <tr key={index}>
                                                                                    <td>
                                                                                        {index <
                                                                                        room.adultsNumber ? (
                                                                                            <FieldSelect
                                                                                                formik={
                                                                                                    formik
                                                                                                }
                                                                                                id={`room.${r}.passengers.${index}.title`}
                                                                                                placeholder="Select"
                                                                                                label="Title"
                                                                                                options={[
                                                                                                    {
                                                                                                        value: 'Mr',
                                                                                                        text: 'Mr.',
                                                                                                    },
                                                                                                    {
                                                                                                        value: 'Ms',
                                                                                                        text: 'Ms.',
                                                                                                    },
                                                                                                    {
                                                                                                        value: 'Miss',
                                                                                                        text: 'Miss',
                                                                                                    },
                                                                                                    {
                                                                                                        value: 'Mrs',
                                                                                                        text: 'Mrs.',
                                                                                                    },
                                                                                                ]}
                                                                                            />
                                                                                        ) : (
                                                                                            <FieldChildren
                                                                                                formik={
                                                                                                    formik
                                                                                                }
                                                                                                id={`room.${r}.passengers.${index}.title`}
                                                                                                age={__plural(
                                                                                                    room
                                                                                                        .childrenAges[
                                                                                                        index -
                                                                                                            room.adultsNumber
                                                                                                    ],
                                                                                                    'year'
                                                                                                )}
                                                                                            />
                                                                                        )}
                                                                                    </td>
                                                                                    <td>
                                                                                        <FieldText
                                                                                            formik={formik}
                                                                                            id={`room.${r}.passengers.${index}.firstName`}
                                                                                            placeholder="Name"
                                                                                            label="First Name"
                                                                                            onChange={
                                                                                                transliterate
                                                                                            }
                                                                                        />
                                                                                    </td>
                                                                                    <td>
                                                                                        <FieldText
                                                                                            formik={formik}
                                                                                            id={`room.${r}.passengers.${index}.lastName`}
                                                                                            placeholder="Surname"
                                                                                            label="Last Name"
                                                                                            onChange={
                                                                                                transliterate
                                                                                            }
                                                                                        />
                                                                                    </td>
                                                                                </tr>
                                                                            )
                                                                        )
                                                                    }
                                                                />
                                                                {contract.rooms.length === 1 &&
                                                                SpecialRequest ? (
                                                                    <tr>
                                                                        <td colSpan={3}>
                                                                            <SpecialRequest formik={formik} />
                                                                        </td>
                                                                    </tr>
                                                                ) : null}
                                                            </tbody>
                                                        </table>
                                                    )}
                                                    <Allotment
                                                        contract={contract}
                                                        room={room}
                                                        checkInDate={baseInfo.checkInDate}
                                                    />
                                                </div>
                                            ))
                                        }
                                    />

                                    {contract?.rooms.length !== 1 && SpecialRequest ? (
                                        <div style={{ marginTop: 40 }}>
                                            <SpecialRequest formik={formik} />
                                        </div>
                                    ) : null}

                                    {!$ui.itnMode || $ui.itnModeAdditionalData?.surrogateAgencyName ? (
                                        <BookingSurrogateAgency
                                            formik={formik}
                                            readOnly={Boolean($ui.itnModeAdditionalData?.surrogateAgencyName)}
                                        />
                                    ) : null}
                                </div>
                            </div>
                        </div>
                    )}
                />
            </section>
        </div>
    );
});

export default AccommodationBookingPage;
