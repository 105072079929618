import { makeAutoObservable } from 'mobx';

const calculateId = () => Math.trunc(Math.random() * 1000000000);

class NotificationsStore {
    list = [];

    constructor() {
        makeAutoObservable(this);
    }

    addPermanentNotification(text, title, style = 'warning', temporary, traceId) {
        const id = calculateId();
        if (text?.length > 1) {
            this.list.push({
                id,
                text,
                title,
                style,
                temporary,
                traceId,
            });
            return id;
        }
    }

    addNotification(text, title, style, traceId) {
        const id = this.addPermanentNotification(text, title, style, true, traceId);
        setTimeout(() => {
            this.closeNotification(id);
        }, 25000);
    }

    addServerNotification(data) {
        const id = calculateId();
        if (data)
            this.list.push({
                id,
                data,
                temporary: true,
            });
        setTimeout(() => {
            this.closeNotification(id);
        }, 30000);
    }

    closeAllNotifications() {
        this.list = [];
    }

    closeNotification(id) {
        let index;
        this.list.forEach((item, i) => {
            if (item.id === id) index = i;
        });
        if (index !== undefined) this.list.splice(index, 1);
    }
}

export default new NotificationsStore();
