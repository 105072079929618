import React, { useEffect, useState } from 'react';
import { API } from 'htcore';
import apiMethods from 'common/api-methods';
import { Loader } from 'components/simple';
import PermissionsSelectorElement from './parts/permission-selector-element';

const PermissionsSelector = ({ formik }) => {
    const [rolesList, setRolesList] = useState(null);

    useEffect(() => {
        API.get({
            url: apiMethods.AGENT_ALL_ROLES,
            success: setRolesList,
        });
    }, []);

    if (!rolesList) {
        return <Loader />;
    }

    return <PermissionsSelectorElement formik={formik} rolesList={rolesList} />;
};

export default PermissionsSelector;
