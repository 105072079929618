import { API } from 'htcore';
import apiMethods from 'common/api-methods';
import { windowLocalStorage } from 'htcore/misc/window-local-storage';
import { submitPayfortPaymentForm } from '../submitter';
import { $payment } from 'stores';

export const payDirectByForm = (values) => {
    const directLinkCode = windowLocalStorage.get($payment.subject.referenceCode);

    return API.post({
        noAuth: true,
        url: apiMethods.DIRECT_LINK_PAY.SIGN(directLinkCode),
        body: $payment.service,
        success: (signature) => submitPayfortPaymentForm(values, signature),
    });
};
