import React from 'react';
import { Link } from 'react-router-dom';
import { remapStatus } from 'simple';

export default ({ message }) => (
    <div className="content">
        <div className="style success">
            <i />
        </div>
        <div className="text">
            <h2>Booking {message.bookingDetails.referenceCode} Completed</h2>
            <div>
                Reservation in {message.bookingDetails.accommodationName} from{' '}
                {message.bookingDetails.checkInDate}
                <br />
                Status changed to <b>{remapStatus(message.bookingDetails.status)}</b>
            </div>
            <span>
                <Link to={`/booking/${message.bookingDetails.referenceCode}`} target="_blank">
                    Open Booking
                </Link>
            </span>
        </div>
    </div>
);
