import React, { useState } from 'react';
import { API } from 'htcore';
import apiMethods from 'common/api-methods';
import { INVOICE_TYPES } from 'enum';
import { CachedForm, FieldText } from 'components/form';
import { Loader } from 'components/simple';
import { observer } from 'mobx-react';
import { emailFormValidator } from 'components/form/validation';
import ModalTemplate from 'common/template/modal-template';

const getRequestUrl = (type, isService) => {
    if (type === INVOICE_TYPES.VOUCHER) {
        return apiMethods.BOOKING_VOUCHER_SEND;
    }

    return isService ? apiMethods.SERVICE_INVOICE_SEND : apiMethods.BOOKING_INVOICE_SEND;
};

const SendDocumentModal = observer((props) => {
    const { isService, type, bookingId, referenceCode } = props;

    const [success, setSuccess] = useState(null);
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(false);

    const submit = (values) => {
        setLoading(true);
        API.post({
            url: getRequestUrl(type, isService)(bookingId),
            body: values,
            success: () => {
                setSuccess(true);
            },
            error: () => setError(true),
            after: () => setLoading(false),
        });
    };

    if (!props.isOpen) return null;

    return (
        <ModalTemplate {...props}>
            <h2>{type === INVOICE_TYPES.VOUCHER ? 'Send Voucher' : 'Send Invoice'}</h2>

            {error && <div>An error occurred</div>}

            {success && (
                <div>
                    {type === INVOICE_TYPES.VOUCHER
                        ? 'Booking voucher has been sent'
                        : 'Booking invoice has been sent'}
                </div>
            )}

            {loading && <Loader page />}

            {!error && !success && (
                <CachedForm
                    initialValues={{ email: '' }}
                    validationSchema={emailFormValidator}
                    onSubmit={submit}
                    render={(formik) => (
                        <>
                            <div className="form">
                                <div style={{ marginBottom: 25, width: 400 }}>
                                    Enter Email to Receive Booking{' '}
                                    {type === INVOICE_TYPES.VOUCHER ? 'Voucher' : 'Invoice'}
                                    <br />
                                    <b>{referenceCode}</b>
                                </div>
                                <div className="row">
                                    <FieldText formik={formik} id="email" placeholder="Email" />
                                </div>
                                <div className="bottom">
                                    <button className="button" type="submit">
                                        {type === INVOICE_TYPES.VOUCHER ? 'Send Voucher' : 'Send Invoice'}
                                    </button>
                                </div>
                            </div>
                        </>
                    )}
                />
            )}
        </ModalTemplate>
    );
});

export default SendDocumentModal;
