import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import { FieldSelectWithSearch } from 'components/form';
import { API } from 'htcore';
import apiMethods from 'common/api-methods';

const BookingSurrogateAgency = observer(({ formik, readOnly }) => {
    const [agenciesList, setAgenciesList] = useState([]);

    useEffect(() => {
        API.get({
            url: apiMethods.AGENCIES,
            success: (list) => setAgenciesList(list),
        });
    }, []);

    if (!agenciesList.length) {
        return null;
    }

    let options = [{ name: 'None', id: null }, ...agenciesList].map(({ name, id }) => ({
        value: id,
        text: name,
    }));

    return (
        <div className="alternate-agency-block">
            <div>
                <h4>Select Agency</h4>
                <p>
                    You can select an alternative agency to create this booking. This agency will be mentioned
                    in voucher, invoice and reports.
                </p>
            </div>
            <div className="form" style={{ width: 'auto' }}>
                <FieldSelectWithSearch
                    id="surrogateAgencyId"
                    placeholder="Select Agency"
                    options={options}
                    formik={formik}
                    label="Agency"
                    className="size-medium"
                    readOnly={readOnly}
                />
            </div>
        </div>
    );
});

export default BookingSurrogateAgency;
