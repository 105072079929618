import React, { useEffect } from 'react';
import { getQueryParams, useTitle } from 'htcore';
import { API } from 'htcore';
import apiMethods from 'common/api-methods';
import { Loader } from 'components/simple';
import { paymentCallback } from 'tasks/payment/finalize';
import { directPaymentCallback } from 'tasks/payment/direct/direct-finalize';
import { windowLocalStorage } from 'htcore/misc/window-local-storage';
import { useNavigate, useParams } from 'react-router-dom';

// todo: result-first and result-second is the same page but
//  at first :
//      service_command=TOKENIZATION
//  at second :
//      command=AUTHORIZATION

const PaymentResultPage = () => {
    useTitle('Processing');
    const navigate = useNavigate();
    const { referenceCode } = useParams();
    const { token_name, response_message } = getQueryParams();
    const directLinkCode = windowLocalStorage.get(referenceCode);

    useEffect(() => {
        if (directLinkCode) {
            API.post({
                noAuth: true,
                url: apiMethods.DIRECT_LINK_PAY.PAY(directLinkCode),
                body: token_name,
                after: (data, error) => directPaymentCallback(data, error, navigate),
            });
            return;
        }

        if (!token_name) {
            let detail = 'Payment processing error: No token received from payment system.';
            if (response_message) detail += ' Details: ' + response_message;

            paymentCallback(null, { detail }, navigate);
            return;
        }

        API.post({
            url: apiMethods.PAYFORT_PAY_NEW(referenceCode.includes('MPS')),
            body: {
                referenceCode,
                token: token_name,
            },
            after: (data, error) => paymentCallback(data, error, navigate),
        });
    }, []);

    return (
        <>
            <Loader white page />
            {__devEnv && (
                <div className="development-block">
                    <a
                        className="button"
                        href={'http://localhost:4000' + window.location.pathname + window.location.search}
                    >
                        Process to localhost
                    </a>
                </div>
            )}
        </>
    );
};

export default PaymentResultPage;
