import React from 'react';
import { date, remapStatus } from 'simple';

export default ({ message }) => (
    <div className="content">
        <div className="style information">
            <i />
        </div>
        <div className="text">
            <h2>Credit Card Payment Received</h2>
            <span>
                Payment {message.amount} status changed to <b>{message.paymentStatus}</b>
                <br />
                Reservation in {message.accommodation} from {date.format.day(message.checkInDate)} status is{' '}
                {remapStatus(message.status)}
            </span>
        </div>
    </div>
);
