import React from 'react';
import { Link } from 'react-router-dom';
import { useTitle } from 'htcore';

const NotFoundPage = () => {
    useTitle('Not Found');

    return (
        <div className="error-page block">
            <section>
                <div>
                    <div className="picture">
                        <div className="text">
                            <h1>404</h1>
                            <h2>Page not found</h2>
                        </div>
                    </div>

                    <Link to="/search">
                        <span className="button">Back to Homepage</span>
                    </Link>
                </div>
            </section>
        </div>
    );
};

export default NotFoundPage;
