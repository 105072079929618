import React from 'react';
import NotificationInternalItem from './notification-internal-item';
import NotificationExternalItem from './notification-external-item';

const NotificationItem = ({ notification }) => {
    if (notification?.data && notification.data.sendingStatus !== 'Read')
        // todo: remove hardcode of enum - wrong API answer. mixed enum answers
        return <NotificationExternalItem pure id={notification.id} notification={notification.data} />;

    return <NotificationInternalItem notification={notification} />;
};

export default NotificationItem;
