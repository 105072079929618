import React, { useState } from 'react';
import { API } from 'htcore';
import { date } from 'simple';
import apiMethods from 'common/api-methods';
import { Loader } from 'components/simple';
import { CachedForm, FieldDatepicker } from 'components/form';
import { $notifications } from 'stores';

const AccountStatementDownload = ({ accountId, range, setRange }) => {
    const [loading, setLoading] = useState(false);

    const downloadStatement = (fileType) => {
        if (!range?.[0] || !range?.[1]) {
            return;
        }
        setLoading(true);
        API.get({
            url: apiMethods.AGENCY_ACCOUNT_DOWNLOAD(accountId, fileType),
            body: {
                $filter: `date ge ${date.format.api(range[0])} and date lt ${date.format.apiDateOnly(
                    range[1]
                )}T23:59:59Z`,
                $orderBy: 'date desc, created desc',
            },
            response: (res) => {
                if (res.status === 400) {
                    $notifications.addNotification('Unable to download a statement');
                    setLoading(false);
                }
                if (res.status === 200)
                    res.blob().then((blobby) => {
                        let anchor = document.createElement('a');
                        document.body.appendChild(anchor);

                        const objectUrl = window.URL.createObjectURL(blobby);
                        anchor.href = objectUrl;
                        const contentDisposition = res.headers.get('content-disposition');
                        const fileName = contentDisposition.split('filename="')[1].split('";')[0];
                        anchor.download = fileName;
                        anchor.click();

                        window.URL.revokeObjectURL(objectUrl);
                        setLoading(false);
                    });
            },
            error: () => {
                setLoading(false);
                $notifications.addNotification('Unable to download a file');
            },
        });
    };

    return (
        <div className="form">
            {loading && <Loader page />}
            <button className="button main" onClick={() => downloadStatement('pdf')}>
                Load The Statement in PDF
            </button>
            <button className="button main" onClick={() => downloadStatement('xlsx')}>
                Load The Statement in EXCEL
            </button>
            <CachedForm
                initialValues={{ start: range?.[0], end: range?.[1] }}
                onSubmit={downloadStatement}
                render={(formik) => (
                    <div className="row">
                        <FieldDatepicker
                            formik={formik}
                            id="range"
                            first="start"
                            second="end"
                            label="Dates"
                            placeholder="Dates"
                            disabledDates={[
                                {
                                    after: new Date(),
                                    before: date.addMonth(new Date(), -12),
                                },
                            ]}
                            className="wide"
                            onChange={setRange}
                        />
                    </div>
                )}
            />
        </div>
    );
};

export default AccountStatementDownload;
