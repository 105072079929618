import React, { useEffect, useState } from 'react';

export const importExternalScript = (url) => {
    const script = document.createElement('script');
    script.src = url;
    document.getElementsByTagName('head')[0].appendChild(script);
};

const useGoogleAnalytics = () => {
    const [inited, setInited] = useState(false);

    useEffect(() => {
        if (!inited) {
            importExternalScript('https://www.googletagmanager.com/gtag/js?id=AW-16575538614');
            setInited(true);
        } else {
            window.dataLayer = window.dataLayer || [];
            function gtag() {
                dataLayer.push(arguments);
            }
            gtag('js', new Date());
            gtag('config', 'AW-16575538614', { page_path: window.location.pathname });
        }
    }, []);

    return null;
};

export default useGoogleAnalytics;
