import React from 'react';

export default ({ message }) => (
    <div className="content">
        <div className="style information">
            <i />
        </div>
        <div className="text">
            <h2>Agent Invitation</h2>
            <div>Invited {message.userName}</div>
        </div>
    </div>
);
