import React from 'react';
import { getIn } from 'formik';

const FieldChildren = (props) => {
    const { formik, id, age } = props;

    const select = (value, event) => {
        event.preventDefault();
        formik.setFieldValue(id, value);
    };

    const value = getIn(formik?.values, id);

    return (
        <div className="field-children">
            <div className="age">{age}</div>
            <button className={'button' + __class(value === 'Mr', 'main')} onClick={(e) => select('Mr', e)}>
                <i className="icon icon-boy" />
            </button>
            <button
                className={'button' + __class(value === 'Miss', 'main')}
                onClick={(e) => select('Miss', e)}
            >
                <i className="icon icon-girl" />
            </button>
        </div>
    );
};

export default FieldChildren;
