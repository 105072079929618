import { HOTEL_STARS } from 'enum';
import { $accommodation } from 'stores';

export const atLeastOne = (obj) => {
    if (!obj) return false;
    if (obj.length) return true;
    let result = false;
    for (let i in obj) if (obj.hasOwnProperty(i)) result = result || obj[i];
    return result;
};

const TEMPORARY_MAX_PRICE = 3000;

const enabledFiltersForList = (obj) => Object.keys(obj).filter((v) => obj[v]);

export const generateFiltersObject = (filters) => {
    let result = {};
    if (!filters) {
        return result;
    }
    if (filters.price?.min > 0) result.minPrice = filters.price.min * $accommodation.search.numberOfNights;
    if (filters.price?.max < TEMPORARY_MAX_PRICE)
        result.maxPrice = filters.price.max * $accommodation.search.numberOfNights;
    if (atLeastOne(filters.boardBasis)) result.boardBasisTypes = enabledFiltersForList(filters.boardBasis);
    if (atLeastOne(filters.ratings)) result.ratings = enabledFiltersForList(filters.ratings);
    if (atLeastOne(filters.supplier)) result.suppliers = enabledFiltersForList(filters.supplier);
    if (atLeastOne(filters.district)) {
        const { districts } = $accommodation.search.filters;
        const enabled = enabledFiltersForList(filters.district);
        result.districts = enabled.map((item) => districts.find(({ id }) => id === item)?.ids).flat();
    }
    if (atLeastOne(filters.chain)) {
        const { chains } = $accommodation.search.filters;
        const enabled = enabledFiltersForList(filters.chain);
        result.chains = enabled.map((item) => chains.find(({ id }) => id === item)?.ids).flat();
    }
    if (filters.hotelName) result.hotelName = filters.hotelName;
    return result;
};

const createEntityFilter = (elements, name_key, filter_key, smallest_size, other_title) => {
    let result = null;

    if (elements?.length) {
        let otherItem = {
            title: other_title,
            ids: [],
            count: 0,
            id: 'Other',
        };
        result = [];
        elements.forEach((item) => {
            const id = item[name_key];
            const count = item.count;

            const nowEnabled = $accommodation.selected.filters?.[filter_key]?.[id];
            if (!nowEnabled && (id === '' || count < smallest_size)) {
                otherItem.ids.push(id);
                otherItem.count += count;
            } else {
                result.push({
                    title: id,
                    ids: [id],
                    count,
                    id,
                });
            }
        });
        result.sort((a, b) => (a.title < b.title ? 1 : -1));
        result.sort((a, b) => (a.count < b.count ? 1 : -1));
        if (other_title) {
            result.push(otherItem);
        }
    }

    return result;
};

const createDistrictsFilter = (districts = []) => {
    const NAME_KEY = 'districtName';
    const FILTER_KEY = 'district';
    const SMALLEST_DISTRICT_SIZE = 4;
    const OTHER_DISTRICTS_TITLE = 'Other Areas';

    return createEntityFilter(districts, NAME_KEY, FILTER_KEY, SMALLEST_DISTRICT_SIZE, OTHER_DISTRICTS_TITLE);
};

const createChainsFilter = (chains = []) => {
    const NAME_KEY = 'chainName';
    const FILTER_KEY = 'chain';
    const SMALLEST_CHAIN_SIZE = 1;
    const OTHER_CHAINS_TITLE = '';

    return createEntityFilter(chains, NAME_KEY, FILTER_KEY, SMALLEST_CHAIN_SIZE, OTHER_CHAINS_TITLE);
};

export const createFilters = (hotels, districts, chains) => ({
    price: {
        min: 0,
        max: TEMPORARY_MAX_PRICE,
        currency: hotels?.[0]?.roomContractSets?.[0]?.rate.currency || 'USD',
    },
    boardBasis: [
        'RoomOnly',
        'SelfCatering',
        'BedAndBreakfast',
        'HalfBoard',
        'FullBoard',
        'AllInclusive',
        'NotSpecified',
    ],
    ratings: [HOTEL_STARS[5], HOTEL_STARS[4], HOTEL_STARS[3]],
    districts: createDistrictsFilter(districts),
    chains: createChainsFilter(chains),
});

export const applyFilters = (hotels) => {
    if (!hotels) return null;
    return hotels;
};

export const generateSortersObject = (sorters) => {
    let result = {};
    if (sorters?.price) {
        result = {
            order: 'price',
            direction: sorters.price > 0 ? 'desc' : 'asc',
        };
    }
    return result;
};
