import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { authorized } from 'htcore';
import { Loader } from 'components/simple';

export default () => {
    const navigate = useNavigate();

    useEffect(() => {
        if (authorized()) {
            navigate('/search');
        }
    }, [navigate]);

    return <Loader white page />;
};
