import React from 'react';
import { Link } from 'react-router-dom';

export default ({ message }) => (
    <div className="content">
        <div className="style success">
            <i />
        </div>
        <div className="text">
            <h2>Payment Completed</h2>
            <div>
                Reservation in {message.accommodationName} from {message.checkInDate}
            </div>
            <span>
                <Link to={`/booking/${message.referenceCode}`} target="_blank">
                    Open Booking
                </Link>
            </span>
        </div>
    </div>
);
