import React from 'react';
import { Link } from 'react-router-dom';

export default ({ message }) => (
    <div className="content">
        <div className="style information">
            <i />
        </div>
        <div className="text">
            <h2>Booking {message.bookingDetails.referenceCode} Cancelled</h2>
            <div>
                Cancelled reservation in {message.bookingDetails.accommodationName} from{' '}
                {message.bookingDetails.checkInDate}
            </div>
            <span>
                <Link to={`/booking/${message.bookingDetails.referenceCode}`} target="_blank">
                    Open Booking
                </Link>
            </span>
        </div>
    </div>
);
