import React from 'react';
import { date, price } from 'simple';

const optimizeDailyRates = (rooms) => {
    let result = [];

    try {
        let dailyRateByRoom = [];
        let dailyRateByDay = [];

        rooms.forEach((room) => {
            let sorted = JSON.parse(JSON.stringify(room.dailyRoomRates));
            sorted = sorted.sort((a, b) => Number(new Date(a.fromDate)) - Number(new Date(b.fromDate)));

            let roomRates = [];
            for (let i = 0; i < sorted.length; i++) {
                for (
                    let j = sorted[i].fromDate;
                    new Date(j) < new Date(sorted[i].toDate);
                    j = date.addDay(j, 1)
                ) {
                    roomRates.push({
                        date: j,
                        price: sorted[i].totalPrice,
                    });
                }
            }

            dailyRateByRoom.push(roomRates);
        });

        for (let i = 0; i < dailyRateByRoom.length; i++) {
            let room = dailyRateByRoom[i];
            let roomRates = [];
            let start = 0;
            for (let j = 1; j < room.length + 1; j++) {
                if (JSON.stringify(room[start].price) !== JSON.stringify(room[j]?.price)) {
                    roomRates.push({
                        fromDate: room[start].date,
                        toDate: room[j - 1].date,
                        price: room[start].price,
                    });
                    start = j;
                }
            }
            result.push(roomRates);
        }
    } catch (e) {}

    return result;
};

const DailyRatesContract = ({ rooms }) => {
    const optimized = optimizeDailyRates(rooms);

    if (!optimized?.length) {
        return null;
    }

    const big = rooms.length > 1;

    return (
        <div className={'daily-rates-contract-table' + __class(big, 'rates-big')}>
            <div>
                {big && <div className="room-name">Room</div>}
                <div className="left">Date</div>
                <div className="right">Price</div>
            </div>
            {optimized.map((room, index) => (
                <div key={index}>
                    {big && (
                        <div className="room-name">
                            {rooms[index].contractName ||
                                rooms[index].contractDescription ||
                                `Room ${index + 1}`}
                        </div>
                    )}
                    <div className="room-inner">
                        {room.map((line) => (
                            <div key={line.fromDate} className="room-dates">
                                <div className="left">
                                    {date.format.g(line.fromDate)}
                                    {line.fromDate !== line.toDate ? (
                                        <>
                                            {' '}
                                            – <span>{date.format.g(date.addDay(line.toDate, 1))}</span>
                                        </>
                                    ) : (
                                        ''
                                    )}
                                </div>
                                <div className="right">{price(line.price)}</div>
                            </div>
                        ))}
                        {big && (
                            <div className="room-dates">
                                <div className="right">
                                    <b>Total: {price(rooms[index].rate.finalPrice)}</b>
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            ))}
        </div>
    );
};

export default DailyRatesContract;
