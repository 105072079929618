import { API } from 'htcore';
import apiMethods from 'common/api-methods';
import { submitPayfortPaymentForm } from './submitter';
import { $payment } from 'stores';

export const loadPayfortPaymentServiceData = () => API.get({ url: apiMethods.DIRECT_LINK_PAY.SETTINGS });

export const payByForm = (values) =>
    API.post({
        url: apiMethods.PAYFORT_CARDS_SIGN,
        body: $payment.service,
        success: (signature) => submitPayfortPaymentForm(values, signature),
    });
