import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import NotificationExternalItem from 'common/notifications/notification-external-item';
import { NOTIFICATION_TYPES } from 'enum';
import { API, useTitle } from 'htcore';
import apiMethods from 'common/api-methods';
import { Loader } from 'components/simple';
import NotificationsReceived from './notifications-received';

const NotificationsLog = observer(() => {
    useTitle('Notifications');
    const [loading, setLoading] = useState(true);
    const [log, setLog] = useState(null);

    useEffect(() => {
        API.get({
            url: apiMethods.NOTIFICATIONS,
            success: (result) => {
                if (!result?.length) return setLog([]);

                setLog(result.sort((a, b) => Number(new Date(b.created)) - Number(new Date(a.created))));
            },
            after: () => setLoading(false),
        });
    }, []);

    let isSomethingToShow = false;
    if (log?.length) {
        isSomethingToShow = log.slice(0, 50).some((item) => item.type in NOTIFICATION_TYPES);
    }

    return (
        <div className="cabinet block">
            <section>
                {loading ? (
                    <Loader />
                ) : (
                    <div className="notifications">
                        {isSomethingToShow ? (
                            log
                                .slice(0, 50)
                                .map((notification) => (
                                    <NotificationExternalItem
                                        key={notification.id}
                                        notification={notification}
                                    />
                                ))
                        ) : (
                            <div className="table">
                                <div className="empty">
                                    Further notifications about any events will appear here
                                </div>
                            </div>
                        )}
                    </div>
                )}
                <NotificationsReceived />
            </section>
        </div>
    );
});

export default NotificationsLog;
