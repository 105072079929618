import React from 'react';

const TextBlock = ({ title, text, children }) => (
    <div className="text-block">
        <h2>{title}</h2>
        <div className="text-wrapper">
            <p>
                <span className="icon icon-plane" />
                <span className="text">{text}</span>
            </p>

            {children}
        </div>
    </div>
);

export default TextBlock;
