import * as Yup from 'yup';

const LETTERS = /^[0-9A-Za-zÀ-ÖØ-öø-ÿ $-)+-/:-?{-~!"^_`\[\]]+$/;

export default Yup.object().shape({
    address: Yup.string().matches(LETTERS, 'Only latin letters allowed').required('Required'),
    phone: Yup.string().max(50, 'Too long').required('Required'),
    notificationEmail: Yup.string().nullable().max(50, 'Too long').email('Incorrect email'),
    billingEmail: Yup.string().email('Incorrect email').required('Required'),
    taxRegistrationNumber: Yup.string()
        .nullable()
        .when('nonTRN', {
            is: false,
            then: Yup.string().min(15, '*').max(15, 'Too long').required('Required'),
        }),
});
