import React from 'react';
import { FieldText, FieldSelect, FieldPhone } from 'components/form';

const FormAgentData = ({ formik }) => (
    <>
        <div className="row">
            <FieldSelect
                formik={formik}
                id="title"
                label="Salutation"
                required
                placeholder="Select"
                options={[
                    { value: 'Mr', text: 'Mr.' },
                    { value: 'Ms', text: 'Ms.' },
                    { value: 'Miss', text: 'Miss' },
                    { value: 'Mrs', text: 'Mrs.' },
                ]}
            />
        </div>
        <div className="row">
            <FieldText formik={formik} id="firstName" label="First Name" placeholder="First Name" required />
        </div>
        <div className="row">
            <FieldText formik={formik} id="lastName" label="Last Name" placeholder="Last Name" required />
        </div>
        <div className="row">
            <FieldPhone
                formik={formik}
                id="mobilePhone"
                label="Mobile Phone Number"
                placeholder="Mobile Phone Number"
            />
        </div>
        <div className="row">
            <FieldText
                formik={formik}
                id="position"
                label="Position (Designation)"
                placeholder="Position (Designation)"
                required
            />
        </div>
    </>
);

export default FormAgentData;
