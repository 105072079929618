import * as Yup from 'yup';

export default Yup.object().shape({
    emailAddress: Yup.string().email('Incorrect email').required('*'),
    firstName: Yup.string().required('*'),
    lastName: Yup.string().required('*'),
    address1: Yup.string(),
    city: Yup.string(),
    countryCode: Yup.string(),
});
