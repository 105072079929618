import React from 'react';
import { getIn } from 'formik';

const DefaultTableRow = ({ row, columns, onRowClick }) => (
    <tr onClick={onRowClick ? () => onRowClick(row) : null} className={__class(onRowClick, 'clickable')}>
        {columns.map((column, j) => (
            <td key={j}>
                <strong>{column.header}</strong>
                {typeof column.cell === 'function' ? column.cell(row) : getIn(row, column.cell)}
            </td>
        ))}
    </tr>
);

export default DefaultTableRow;
