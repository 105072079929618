import React, { useState, useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import { API, useTitle } from 'htcore';
import apiMethods from 'common/api-methods';
import { date, PassengerName } from 'simple';
import { Loader } from 'components/simple';
import Breadcrumbs from 'components/navigation/breadcrumbs';
import { $auth } from 'stores';

const InvitationResendPage = () => {
    useTitle('Invitation');
    const [success, setSuccess] = useState(false);
    const [invitation, setInvitation] = useState(false);
    const navigate = useNavigate();
    const { id } = useParams();

    useEffect(() => {
        if (!$auth.activeAgency) return;

        API.get({
            url: $auth.permitted('ObserveAgencyInvitations')
                ? apiMethods.AGENCY_INVITATIONS
                : apiMethods.AGENT_INVITATIONS,
            success: (result) => setInvitation(result.filter((item) => String(item.id) === String(id))[0]),
        });
    }, []);

    const resend = () => {
        API.post({
            url: apiMethods.AGENT_INVITE_RESEND(id),
            success: () => setSuccess(true),
            error: () => setSuccess(false),
        });
        window.scrollTo(0, 0);
    };

    const disable = () => {
        API.post({
            url: apiMethods.AGENT_INVITE_DISABLE(id),
            success: () => navigate('/settings/agency/invitations'),
        });
    };

    return (
        <div className="cabinet block">
            <section>
                <Breadcrumbs backLink="/settings/agency/invitations" backText="Back to Invitations" />
                <h2>Unaccepted Invitation</h2>
                {!invitation ? (
                    <Loader />
                ) : (
                    <>
                        <div className="row">
                            <b>Name</b>: {PassengerName({ passenger: invitation })}
                        </div>
                        <div className="row">
                            <b>Position</b>: {invitation.position}
                        </div>
                        <div className="row">
                            <b>Email</b>: {invitation.email}
                        </div>
                        <div className="row">
                            <b>Status</b>: {invitation.status}
                        </div>
                        <div className="row">
                            <b>Inviter</b>: {invitation.createdBy}
                        </div>
                        <div className="row">
                            <b>Created</b>: {date.format.c(invitation.created)}{' '}
                            {invitation.isExpired && `(Expired)`}
                        </div>

                        {false === success && (
                            <>
                                {'Active' === invitation.status && (
                                    <div className="row">
                                        {!invitation.isExpired && (
                                            <>
                                                <button
                                                    onClick={disable}
                                                    className="button"
                                                    style={{
                                                        margin: '0 20px 0 0',
                                                        paddingLeft: '20px',
                                                        paddingRight: '20px',
                                                    }}
                                                >
                                                    Disable Invitation
                                                </button>
                                                <button
                                                    onClick={resend}
                                                    className="button"
                                                    style={{
                                                        margin: '0 20px 0 0',
                                                        paddingLeft: '20px',
                                                        paddingRight: '20px',
                                                    }}
                                                >
                                                    Resend Invitation
                                                </button>
                                            </>
                                        )}
                                    </div>
                                )}
                            </>
                        )}
                    </>
                )}

                {success && (
                    <div>
                        <div>
                            <h3>Invitation sent</h3>
                            <br />
                        </div>
                        <Link to="/settings/agency/invitations">
                            <button className="button">Back to Invitations</button>
                        </Link>
                    </div>
                )}
            </section>
        </div>
    );
};

export default InvitationResendPage;
