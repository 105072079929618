import React, { useState, useEffect } from 'react';
import { observer } from 'mobx-react';
import { useNavigate } from 'react-router-dom';
import { API, useTitle } from 'htcore';
import apiMethods from 'common/api-methods';
import { date } from 'simple';
import Table from 'components/table/table';
import { $auth } from 'stores';

const agentsColumns = [
    {
        header: 'Name',
        cell: 'name',
    },
    {
        header: 'Sign Up Date',
        cell: (item) => date.format.c(item.created * 1000),
    },
    {
        header: 'Status',
        cell: (item) => (
            <>
                {item.isActive ? 'Active' : 'Inactive'}
                {Boolean(item.markupSettings) && <div>Markup: {item.markupSettings}</div>}
            </>
        ),
    },
];

const Searches = (v) => [v.name];

const AgentsManagement = observer(() => {
    useTitle('Agent Management');
    const [agents, setAgents] = useState(null);
    const navigate = useNavigate();

    useEffect(() => {
        if (!$auth.activeAgency) return;

        API.get({
            url: apiMethods.AGENCY_AGENTS,
            success: (result) => setAgents(result.sort((a, b) => b.isActive - a.isActive)),
        });
    }, [$auth.activeAgency]);

    return (
        <div className="cabinet block">
            <section>
                <h2 className="in-table">Agents</h2>
                <Table
                    list={agents}
                    columns={agentsColumns}
                    onRowClick={
                        $auth.permitted('PermissionManagement')
                            ? (item) => navigate(`/settings/agency/agents/${item.agentId}`)
                            : null
                    }
                    textEmptyResult="No agents found"
                    textEmptyList="The agents list is empty"
                    searches={Searches}
                />
            </section>
        </div>
    );
});

export default AgentsManagement;
