import React, { useState } from 'react';
import { Formik } from 'formik';
import { price } from 'simple';
import { Loader } from 'components/simple';
import { FieldText } from 'components/form';
import { billingDetailsValidator } from 'components/form/validation';

const BillingDetailsForm = ({ total, pay, email, withAddress }) => {
    const [everSubmitted, setEverSubmitted] = useState(false);
    const [loading, setLoading] = useState(false);

    const submit = (values) => {
        setEverSubmitted(true);
        setLoading(true);
        pay(values, () => setLoading(false)).catch(() => setLoading(false));
    };

    return (
        <Formik
            initialValues={{
                emailAddress: email || '',
                firstName: '',
                lastName: '',
                address: '',
                city: '',
            }}
            validateOnChange={true}
            validationSchema={billingDetailsValidator}
            onSubmit={submit}
        >
            {(formik) => (
                <form onSubmit={formik.handleSubmit} className={everSubmitted ? '' : 'never-submitted'}>
                    {loading && <Loader page />}
                    <div className="form">
                        <div className="row">
                            <FieldText
                                formik={formik}
                                id="emailAddress"
                                label="Billing Email"
                                placeholder="Please Enter Billing Email"
                                required
                            />
                        </div>
                        <div className="row">
                            <FieldText
                                formik={formik}
                                id="firstName"
                                label="First Name"
                                placeholder="Please Enter First Name"
                                required
                            />
                        </div>
                        <div className="row">
                            <FieldText
                                formik={formik}
                                id="lastName"
                                label="Last Name"
                                placeholder="Please Enter Last Name"
                                required
                            />
                        </div>
                        {withAddress && (
                            <>
                                <div className="row">
                                    <FieldText
                                        formik={formik}
                                        id="city"
                                        label="Billing City"
                                        placeholder="Please Enter Billing City"
                                    />
                                </div>
                                <div className="row">
                                    <FieldText
                                        formik={formik}
                                        id="address"
                                        label="Billing Address"
                                        placeholder="Please Enter Billing Address"
                                    />
                                </div>
                            </>
                        )}
                        <button type="submit" className="button main">
                            <span className="icon icon-white-lock" />
                            {'Pay' + price(total)}
                        </button>
                    </div>
                </form>
            )}
        </Formik>
    );
};

export default BillingDetailsForm;
