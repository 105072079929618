import React, { useEffect, useState } from 'react';
import { API } from 'htcore';
import apiMethods from 'common/api-methods';
import { date, remapStatus } from 'simple';
import { Loader } from 'components/simple';

const BookingHistoryView = ({ isService, booking }) => {
    const [loading, setLoading] = useState(true);
    const [history, setHistory] = useState(null);

    const loadBookingHistory = () => {
        API.get({
            url: booking?.isOffline
                ? apiMethods.OFFLINE_BOOKING_HISTORY(booking.bookingId)
                : apiMethods.BOOKING_HISTORY(booking.bookingId, isService),
            success: (data) => data && setHistory(data.reverse()),
            after: () => setLoading(false),
        });
    };

    useEffect(() => {
        loadBookingHistory();
    }, [booking]);

    if (!booking?.bookingId) return null;

    if (loading) return <Loader />;

    if (history?.length < 2) return null;

    return (
        <div className="history-status">
            <h3>History of Booking Status Changes</h3>
            <ol reversed>
                {history?.map((item) => (
                    <li key={item.createdAt}>
                        <b>{date.format.a(item.createdAt)}</b>
                        <span style={{ color: '#777' }}> at {date.format.timeSeconds(item.createdAt)}. </span>
                        <b>{item.event === 'CancelRoom' ? 'Room Cancelled' : remapStatus(item.status)}</b>
                        {item.agentName ? (
                            <> by {item.agentName}</>
                        ) : (
                            <>{item.adminName && <b> by Admin: {item.adminName}</b>}</>
                        )}

                        {Boolean(item.reason) && <> (Reason: {item.reason})</>}
                        {item.event && (
                            <>
                                {' '}
                                (Event: <b>{item.event}</b>)
                            </>
                        )}
                    </li>
                ))}
            </ol>
        </div>
    );
};

export default BookingHistoryView;
