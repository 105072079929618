import React, { useState } from 'react';
import { Link } from 'react-router-dom';

const NavigationBar = ({ items, active }) => {
    const [isMinified, setMinified] = useState(true);

    return (
        <div className={'navigation' + (isMinified ? ' minified' : '')}>
            <section>
                {items.map(({ text, value, link }) => (
                    <Link
                        key={value}
                        className={'item' + (value === active ? ' active' : '')}
                        to={link}
                        onClick={() => setMinified((value === active && !isMinified) || value !== active)}
                    >
                        {text}
                    </Link>
                ))}
            </section>
        </div>
    );
};

export default NavigationBar;
