import { API } from 'htcore';
import apiMethods from 'common/api-methods';
import { FORM_NAMES } from 'components/form';
import { PAYMENT_METHODS } from 'enum';
import { $payment, $ui } from 'stores';

export const paymentCallback = (data, error, navigate) => {
    if ('Secure3d' === data?.status) {
        if (data.secure3d) window.location.href = data.secure3d;
        return;
    }

    $payment.setPaymentResult(data?.status, error);

    if (error) {
        navigate('/accommodation/confirmation');
        return;
    }

    const isService = $payment.subject.referenceCode.includes('MPS');

    API.post({
        url:
            $payment.subject.previousPaymentMethod !== PAYMENT_METHODS.CARD
                ? apiMethods.BOOKING_PAY_WITH_CARD($payment.subject.referenceCode, isService)
                : apiMethods.A_BOOKING_FINALIZE($payment.subject.referenceCode),
        after: () => {
            $ui.dropFormCache(FORM_NAMES.BookingForm);
            navigate('/accommodation/confirmation');
        },
    });
};
