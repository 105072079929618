export const NOTIFICATION_STATUSES = {
    None: 0,
    Sent: 1,
    Received: 2,
    Read: 3,
};

// todo: implement not implemented notifications
export const NOTIFICATION_TYPES = {
    BookingVoucher: 'BookingVoucher',
    BookingInvoice: 'BookingInvoice',
    DeadlineApproaching: 'DeadlineApproaching',
    SuccessfulPaymentReceipt: 'SuccessfulPaymentReceipt',
    BookingCancelled: 'BookingCancelled',
    BookingFinalized: 'BookingFinalized',
    BookingStatusChanged: 'BookingStatusChanged',
    CreditCardPaymentReceived: 'CreditCardPaymentReceived',
    AgentInvitation: 'AgentInvitation',
    AgentSuccessfulRegistration: 'AgentSuccessfulRegistration',
    // AgencyActivityChanged: "AgencyActivityChanged", // not implemented
    // BookingSummaryReportForAgent: "BookingSummaryReportForAgent", // not implemented
    // ExternalPaymentLinks: "ExternalPaymentLinks", // not implemented
    // PaymentLinkPaidNotification: "PaymentLinkPaidNotification", // not implemented
};
