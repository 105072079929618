import { initOidcInstance } from '../auth/oidc-instance';

const HTCore = {};

const initApplication = ({ settings, onLogin, onLogout, showNotification, Loader }) => {
    HTCore.settings = settings;
    HTCore.onLogin = onLogin;
    HTCore.onLogout = onLogout;
    HTCore.showNotification = showNotification;
    HTCore.Loader = Loader;

    initOidcInstance(settings);
};

export { initApplication, HTCore };
