import React, { useState } from 'react';
import { Form, Formik } from 'formik';
import { $ui } from 'stores';

const CachedForm = ({
    initialValues = {},
    cacheValidator,
    valuesOverwrite = (values) => values,
    id,
    onSubmit,
    validationSchema,
    render,
    enableReinitialize = true,
}) => {
    const [everSubmitted, setEverSubmitted] = useState(false);
    const [initialized, setInitialized] = useState(false);
    const [isSubmitting, setSubmitting] = useState(false);

    const getInitialValues = () => {
        let cached = $ui.getFormCache(id),
            isValid = false;

        if (!cached) {
            return valuesOverwrite(initialValues);
        }
        if (cacheValidator) {
            try {
                isValid = cacheValidator(cached);
            } catch (e) {}
        } else isValid = true;

        if (isValid) return valuesOverwrite(cached);

        return valuesOverwrite(initialValues);
    };

    const firstInitialValues = getInitialValues();

    const handleReset = (formik) => {
        setEverSubmitted(true);
        formik.resetForm();
        formik.setValues(initialValues);
        $ui.setFormCache(id, null);
    };

    const handleChange = (values) => {
        if (initialized) {
            $ui.setFormCache(id, values);
        } else {
            setInitialized(true);
        }
    };

    const handleSubmit = (props, extended) => {
        setEverSubmitted(true);
        if (onSubmit) onSubmit(props, extended, { isSubmitting, setSubmitting });
    };

    return (
        <Formik
            onSubmit={handleSubmit}
            initialValues={firstInitialValues}
            validate={handleChange}
            validationSchema={validationSchema}
            validateOnChange
            validateOnMount
            enableReinitialize={enableReinitialize}
        >
            {(formik) => (
                <Form className={everSubmitted ? '' : 'never-submitted'}>
                    {render(
                        {
                            ...formik,
                            isSubmitting,
                        },
                        () => handleReset(formik)
                    )}
                </Form>
            )}
        </Formik>
    );
};

export default CachedForm;
