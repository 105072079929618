import React from 'react';
import { observer } from 'mobx-react';
import AccommodationFiltersForm from './accommodation-filters-form';
import { $accommodation } from 'stores';

const AccommodationFiltersDropdown = observer(({ close, filterAction, secondStep }) => {
    const { search, hotelArray, hasAnyFilters } = $accommodation;
    const { filters } = search;

    if (!filters || (!hotelArray?.length && !hasAnyFilters)) return null;

    return (
        <div className="dropdown filters">
            <div className="close-button" onClick={close}>
                <span className="icon icon-close" />
            </div>
            <AccommodationFiltersForm filterAction={filterAction} secondStep={secondStep} />
        </div>
    );
});

export default AccommodationFiltersDropdown;
