import React from 'react';
import { Routes, Route } from 'react-router-dom';

import HomePage from '../landing/pages/home/home-page';
import ProductPage from '../landing/pages/product/product-page';
import AboutUsPage from '../landing/pages/about-us/about-us-page';
import BlogPage from '../landing/pages/blog/blog';
import TermsPage from '../landing/pages/terms';
import PrivacyPage from '../landing/pages/privacy';
import PublicRoutes from './public-routes';

const PublicRoutesPlusLanding = () => (
    <Routes>
        <Route index element={<HomePage />} />
        <Route path="/product" element={<ProductPage />} />
        <Route path="/about-us" element={<AboutUsPage />} />
        <Route path="/blog/*" element={<BlogPage />} />
        <Route path="/terms" element={<TermsPage />} />
        <Route path="/privacy" element={<PrivacyPage />} />
        <Route path="*" element={<PublicRoutes />} />
    </Routes>
);

export default PublicRoutesPlusLanding;
