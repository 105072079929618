import React from 'react';
import { Link } from 'react-router-dom';
import { date as dateFormatter, price } from 'simple';

export const Columns = [
    {
        header: 'Date',
        cell: (row) => dateFormatter.format.c(row.date),
    },
    {
        header: 'Booking Reference',
        cell: (row) =>
            row.referenceCode ? (
                <Link className="link" to={`/booking/${row.referenceCode}`}>
                    {row.referenceCode}
                </Link>
            ) : (
                '-'
            ),
    },
    {
        header: 'Invoice No.',
        cell: ({ invoiceNumber }) => (invoiceNumber ? invoiceNumber : '-'),
    },
    {
        header: 'Description',
        cell: ({ operationDescription, description, userName, created }) => (
            <>
                <b>{operationDescription}:</b> {description}
                {userName ? (
                    <>
                        {' '}
                        <b>by</b> {userName} at {dateFormatter.format.time(created)}
                    </>
                ) : null}
            </>
        ),
    },
    {
        header: 'Debit',
        cell: (row) => (row.debit ? price(row.currency, row.debit) : '-'),
    },
    {
        header: 'Credit',
        cell: (row) =>
            row.credit ? <span style={{ color: '#1CBE69' }}>{price(row.currency, row.credit)}</span> : '-',
    },
    {
        header: 'Balance',
        cell: (row) => price(row.currency, row.balance),
    },
];

export const Searches = (v) => [v.referenceCode, v.operationDescription, v.description, v.userName];
