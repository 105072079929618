import React from 'react';
import { useTitle } from 'htcore';
import Breadcrumbs from 'components/navigation/breadcrumbs';
import BookingConfirmationView from './parts/booking-confirmation-view';
import { useParams } from 'react-router-dom';

const AccommodationViewBookingPage = () => {
    useTitle('Booking');
    const { code } = useParams();

    const serviceCode = code.includes('MPS') ? code : null;

    return (
        <div className="booking block">
            <section>
                <Breadcrumbs backLink="/bookings" backText="Back to Bookings List" />
                <BookingConfirmationView referenceCode={code} serviceCode={serviceCode} />
            </section>
        </div>
    );
};

export default AccommodationViewBookingPage;
