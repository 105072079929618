import React from 'react';
import { Link } from 'react-router-dom';

const Breadcrumbs = ({ items = [], noBackButton, backLink, backText }) => {
    if (!backText) {
        backText = 'Back';
    }

    return (
        <div className="breadcrumbs">
            {!!items.length && (
                <div className="links">
                    {items.map((item, index) => (
                        <React.Fragment key={index}>
                            {item.link ? (
                                <Link to={item.link} onClick={item.onClick}>
                                    {item.text}
                                </Link>
                            ) : (
                                item.text
                            )}
                            {index + 1 < items.length ? <span className="small-arrow-right" /> : ' '}{' '}
                        </React.Fragment>
                    ))}
                </div>
            )}
            {!noBackButton && (
                <Link to={backLink}>
                    <span className="back-button">
                        <span className="small-arrow-left" />
                        {backText}
                    </span>
                </Link>
            )}
        </div>
    );
};

export default Breadcrumbs;
