import { API } from 'htcore';
import apiMethods from 'common/api-methods';
import { $auth } from 'stores';

const settingsCleaner = (values) => ({
    nationality: values.nationality,
    residency: values.residency,
    nationalityCode: values.nationalityCode,
    residencyCode: values.residencyCode,
    weekStarts: values.weekStarts,
    availableCredit: values.availableCredit,
});

export const loadAgency = (callback = () => {}) => {
    API.get({
        url: apiMethods.AGENCY,
        success: (information) => {
            $auth.setAgency(information);
            callback(information);
        },
    });
};

export const fillEmptyAgentSettings = () => {
    loadAgency((result) => {
        if (!result?.countryName || !result?.countryCode) return;
        saveAgentSettings({
            residency: result.countryName,
            residencyCode: result.countryCode,
            nationality: result.countryName,
            nationalityCode: result.countryCode,
        });
    });
};

export const loadAgentSettings = () => {
    API.get({
        url: apiMethods.AGENT_SETTINGS,
        ignoreErrors: true,
        success: (result) => {
            $auth.setSettings(result);
            if (!Object.keys(result || {}).length) fillEmptyAgentSettings();
        },
    });
};

export const saveAgentSettings = (values, after) => {
    API.put({
        url: apiMethods.AGENT_SETTINGS,
        ignoreErrors: true,
        body: settingsCleaner(values),
        success: () => {
            $auth.setSettings(values);
        },
        after,
    });
};
