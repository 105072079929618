import React from 'react';
import { Link } from 'react-router-dom';
import { date } from 'simple';

export default ({ message }) => (
    <div className="content">
        <div className="style success">
            <i />
        </div>
        <div className="text">
            <h2>Booking {message.referenceCode} Voucher</h2>
            <div>
                Voucher is ready for reservation in {message.accommodation.name} from{' '}
                {date.format.day(message.checkInDate)}.
            </div>
            <span>
                <Link to={`/booking/${message.referenceCode}`} target="_blank">
                    Open Booking
                </Link>
            </span>
        </div>
    </div>
);
