const HEADER_SCROLL_CORRECTION_DESKTOP = 160;
const HEADER_SCROLL_CORRECTION_MOBILE = 180;
const HEADER_SCROLL_CORRECTION_PHONE = 100;
const PHONE_WIDTH = 768;
const DESKTOP_WIDTH = 1000;

const FORCED_CORRECTION = 80;

export const scroll = (event, id, forceCorrection) => {
    let correction = HEADER_SCROLL_CORRECTION_DESKTOP;
    if (window.innerWidth < DESKTOP_WIDTH) correction = HEADER_SCROLL_CORRECTION_MOBILE;
    if (window.innerWidth < PHONE_WIDTH) correction = HEADER_SCROLL_CORRECTION_PHONE;

    if (forceCorrection) correction = FORCED_CORRECTION;

    if (!id && event?.target?.dataset['scroll']) {
        [...document.querySelectorAll('[data-scroll]')].forEach((item) => (item.className = 'item'));
        event.target.className = 'item active';
    }

    window.scrollTo(
        0,
        (document.getElementById(id || event.target.dataset['scroll'])?.offsetTop || 0) - correction
    );
};

export const subscribeScroller = (forceCorrection) => {
    let correction = HEADER_SCROLL_CORRECTION_DESKTOP + 50;
    if (forceCorrection) correction = FORCED_CORRECTION + 50;

    let buttons, elements, throttle;
    const onScroll = () => {
        if (window.innerWidth < DESKTOP_WIDTH) return;
        if (throttle) return;
        if (!elements?.length) {
            buttons = [...document.querySelectorAll('[data-scroll]')];
            elements = buttons.map((item) => document.getElementById(item.dataset['scroll']));
        }
        throttle = setTimeout(() => {
            if (!buttons.length || elements.find((el) => !el)) return;
            const scrollTop = window.scrollY + correction;
            const tops = [...elements.map((item) => item.offsetTop), scrollTop].sort((a, b) => a - b);
            const selected = Math.max(0, Math.min(elements.length, tops.indexOf(scrollTop)) - 1);
            if (tops[0] === window.scrollY + correction) {
                buttons.forEach((item) => (item.className = 'item'));
            } else if (buttons[selected]?.className !== 'item active') {
                buttons.forEach((item) => (item.className = 'item'));
                buttons[selected].className = 'item active';
            }
            throttle = null;
        }, 250);
    };

    document.addEventListener('scroll', onScroll);
    return () => {
        document.removeEventListener('scroll', onScroll);
    };
};
