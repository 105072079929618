import React from 'react';
import { getIn } from 'formik';

const FieldSwitch = ({ formik, id, label }) => {
    let value = Boolean(getIn(formik.values, id));

    const changing = () => {
        formik.setFieldValue(id, !value);
    };

    return (
        <>
            <div onClick={changing} className={'switch-control' + __class(value, 'active')} />
            {label && (
                <div onClick={changing} className="vertical-label">
                    {label}
                </div>
            )}
        </>
    );
};

export default FieldSwitch;
