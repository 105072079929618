import React from 'react';
import { API } from 'htcore';
import apiMethods from 'common/api-methods';
import { useNavigate } from 'react-router-dom';
import { $ui } from 'stores';

const BookingItinerary = ({
    itineraryNumber,
    isContinuePossible,
    bookingCurrency,
    surrogateAgencyName,
    surrogateAgencyId,
}) => {
    const navigate = useNavigate();

    const addBooking = () => {
        API.put({
            url: apiMethods.PREFERRED_CURRENCY,
            body: {
                preferredCurrency: bookingCurrency,
            },
        });
        $ui.setIntMode(itineraryNumber, { surrogateAgencyName, surrogateAgencyId });
        $ui.dropFormCache('SearchForm');
        navigate('/search');
    };

    return (
        <div className="itinerary-number">
            <p>
                <span>Itinerary Number:</span> <strong>{itineraryNumber}</strong>
            </p>
            {isContinuePossible && (
                <button className="button main" onClick={addBooking}>
                    Add New Booking to Itinerary
                    {surrogateAgencyName ? (
                        <>
                            <br />
                            {` for ${surrogateAgencyName}`}
                        </>
                    ) : null}
                </button>
            )}
        </div>
    );
};

export default BookingItinerary;
