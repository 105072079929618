import React, { useEffect, useState } from 'react';
import { API, useTitle } from 'htcore';
import apiMethods from 'common/api-methods';
import { Loader } from 'components/simple';
import Amenities from './room-contract-sets/amenities';
import { HotelStars } from 'components/accommodation';
import Gallery from 'components/gallery';
import { useParams } from 'react-router-dom';

const AccommodationDetailsTemporaryPage = () => {
    useTitle('Accommodation Details');
    const [details, setDetails] = useState(null);
    const { htId } = useParams();

    useEffect(() => {
        API.get({
            url: apiMethods.ACCOMMODATION_HTID(htId),
            success: setDetails,
        });
    }, []);

    if (!details) return <Loader page />;

    const description =
        details.textualDescriptions?.[0]?.description || details.textualDescriptions?.[1]?.description;

    return (
        <div className="search-results block">
            <section>
                <h1 style={{ paddingTop: 60, textAlign: 'center' }}>
                    Details for <strong>{htId}</strong>
                </h1>
                <div className="head" id="overview">
                    <div style={{ lineHeight: '24px', fontSize: 16 }}>
                        <a
                            href={`https://www.google.com/maps/place/${details.location.coordinates.latitude},${details.location.coordinates.longitude}`}
                            className="link"
                            target="_blank"
                        >
                            {details.location.coordinates.latitude}, {details.location.coordinates.longitude}
                        </a>
                        <br />
                        {details.location.locality}, {details.location.address}
                    </div>
                    <div className="title">
                        <h1>{details.name}</h1>
                        <HotelStars count={details.rating} />
                    </div>
                    <div style={{ lineHeight: '24px', fontSize: 14, margin: '16px 0' }}>
                        Phones: {details.contacts.phones.join(', ') || 'None'}
                        <br />
                        Websites: {details.contacts.webSites.join(', ') || 'None'}
                        <br />
                        Emails: {details.contacts.emails.join(', ') || 'None'}
                        <br />
                        {!!details.contacts.faxes.length && (
                            <>
                                Faxes: {details.contacts.faxes.join(', ')}
                                <br />
                            </>
                        )}
                    </div>
                </div>
                <div className="text">
                    <div dangerouslySetInnerHTML={{ __html: description }} />
                    {details.schedule.checkInTime && (
                        <div className="checkin-time">
                            Check-in from{' '}
                            {details.schedule.checkInTime?.substr(0, 5) || details.schedule.checkInTime}
                        </div>
                    )}
                    {details.schedule.checkInTime && (
                        <div className="checkin-time">
                            Check-out till{' '}
                            {details.schedule.checkOutTime?.substr(0, 5) || details.schedule.checkOutTime}
                        </div>
                    )}
                </div>

                {Boolean(details.suppliers?.length) && (
                    <div>
                        <h3>
                            <span style={{ marginRight: 20 }}>Suppliers</span>
                            {details.suppliers.map((item) => (
                                <div className="tag" key={item.supplierCode}>
                                    <strong>{item.supplierCode}</strong>: {item.accommodationId}
                                </div>
                            ))}
                        </h3>
                    </div>
                )}

                <div className="billet-wrapper contract">
                    <div className="another" id="information">
                        <Amenities hotel={details} />
                    </div>
                </div>
                <Gallery pictures={details?.photos} />
            </section>
        </div>
    );
};

export default AccommodationDetailsTemporaryPage;
