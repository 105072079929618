import React from 'react';

export default ({ message }) => (
    <div className="content">
        <div className="style success">
            <i />
        </div>
        <div className="text">
            <h2>Agent Registered</h2>
            <div>
                {message.title} {message.agentName} registered
            </div>
        </div>
    </div>
);
