import React from 'react';
import { Routes, Route } from 'react-router-dom';
import CabinetHeader from './header/cabinet-header';

import NotificationsLog from './personal/notifications-log';
import ApplicationSettings from './personal/application-settings';
import PersonalInformation from './personal/personal-information';
import InvitationsManagement from './agency/invitations-management';
import LogoManagement from './agency/logo-management';
import InvitationSend from './agency/invitation-send-page';
import InvitationResend from './agency/invitation-resend-page';
import AgentsManagement from './agency/agents-management';
import AgentManagement from './agency/agent-management';
import AgencyLegalInformation from './agency/agency-legal-information';
import AccountStatement from './account/account-statement';

const CabinetPage = () => (
    <>
        <CabinetHeader />
        <Routes>
            <Route path="/agent/application" element={<ApplicationSettings />} />
            <Route path="/agent/personal" element={<PersonalInformation />} />
            <Route path="/agent" element={<NotificationsLog />} />
            <Route path="/agency/agents/:agentId" element={<AgentManagement />} />
            <Route path="/agency/agents" element={<AgentsManagement />} />
            <Route path="/agency/invitations/send" element={<InvitationSend />} />
            <Route path="/agency/invitations/:id" element={<InvitationResend />} />
            <Route path="/agency/invitations" element={<InvitationsManagement />} />
            <Route path="/agency/logo" element={<LogoManagement />} />
            <Route path="/agency" element={<AgencyLegalInformation />} />
            <Route path="/account/:accountId" element={<AccountStatement />} />
            <Route path="/*" element={<NotificationsLog />} />
        </Routes>
    </>
);

export default CabinetPage;
