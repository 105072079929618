import React, { useState } from 'react';
import { NOTIFICATION_STATUSES, NOTIFICATION_TYPES } from 'enum';
import { date } from 'simple';

import BookingStatusChanged from './templates/notification-booking-status-changed';
import CreditCardPaymentReceived from './templates/notification-credit-card-payment-received';
import BookingInvoice from './templates/notification-booking-invoice';
import BookingVoucher from './templates/notification-booking-voucher';
import BookingFinalized from './templates/notification-booking-finalized';
import BookingCancelled from './templates/notification-booking-cancelled';

import DeadlineApproaching from './templates/notification-deadline-approaching';
import SuccessfulPaymentReceipt from './templates/notification-successful-payment-receipt';
import AgentInvitation from './templates/notification-agent-invitation';
import AgentSuccessfulRegistration from './templates/notification-agent-successful-registration';

import { $notifications } from 'stores';

const RenderNotificationByType = ({ message, type }) => {
    try {
        if (type === NOTIFICATION_TYPES.BookingStatusChanged)
            return <BookingStatusChanged message={message} />;
        if (type === NOTIFICATION_TYPES.CreditCardPaymentReceived)
            return <CreditCardPaymentReceived message={message} />;
        if (type === NOTIFICATION_TYPES.BookingInvoice) return <BookingInvoice message={message} />;
        if (type === NOTIFICATION_TYPES.BookingVoucher) return <BookingVoucher message={message} />;
        if (type === NOTIFICATION_TYPES.BookingFinalized) return <BookingFinalized message={message} />;
        if (type === NOTIFICATION_TYPES.BookingCancelled) return <BookingCancelled message={message} />;
        if (type === NOTIFICATION_TYPES.DeadlineApproaching) return <DeadlineApproaching message={message} />;
        if (type === NOTIFICATION_TYPES.SuccessfulPaymentReceipt)
            return <SuccessfulPaymentReceipt message={message} />;
        if (type === NOTIFICATION_TYPES.AgentInvitation) return <AgentInvitation message={message} />;
        if (type === NOTIFICATION_TYPES.AgentSuccessfulRegistration)
            return <AgentSuccessfulRegistration message={message} />;
    } catch {}

    return null;
};

const NotificationExternalItem = ({ notification, id, pure }) => {
    const [hidden, setHidden] = useState(false);

    const hideAlert = () => {
        if (pure) $notifications.closeNotification(id);
        else setHidden(true);

        if (window.notificationConnection)
            window.notificationConnection.invoke('SendFeedback', {
                messageId: notification.id,
                sendingStatus: NOTIFICATION_STATUSES.Read,
                statusChangeTime: date.format.apiTime(new Date()),
            });
    };

    let { message, type, sendingStatus, created } = notification;
    if (typeof message === 'string') message = JSON.parse(message);
    if (!(type in NOTIFICATION_TYPES)) return null;

    if (pure)
        return (
            <div className="item">
                <RenderNotificationByType message={message} type={type} />
                <div className="progress-timer double">
                    <div className="bar" />
                </div>
                <div className="close-button" onClick={hideAlert}>
                    <span className="icon icon-close" />
                </div>
            </div>
        );

    if (hidden) return null;

    return (
        <div className="item">
            <div className="time">
                {date.format.time(created)} {date.format.day(created)}
            </div>
            <RenderNotificationByType message={message} type={type} />
            {/* todo: perspective feature. make an ability to highlight notifies before
                notification.sendingStatus !== "Read" &&
                    <div className="close-button" onClick={hideAlert}>
                        <span className="icon icon-close" />
                    </div>
            */}
        </div>
    );
};

export default NotificationExternalItem;
