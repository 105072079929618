export const price = (currencyOrObject, value) => {
    if (undefined === value && currencyOrObject)
        return price(currencyOrObject.currency, currencyOrObject.finalPrice || currencyOrObject.amount);

    let currency = currencyOrObject;
    value = parseFloat(value || 0).toFixed(2);

    if (!currency) {
        return '';
    }
    if (currency === 'USD') {
        currency = '$';
    } else if (currency === 'EUR') {
        currency = '€';
    } else {
        currency = currency + ' ';
    }

    if (!currency.includes(' ') && value < 0) {
        value = ' ' + value;
    }

    return ` ${currency}${value} `;
};
