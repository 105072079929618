import React, { useState, useEffect } from 'react';
import { observer } from 'mobx-react';
import { Link, useNavigate } from 'react-router-dom';
import { API, useTitle } from 'htcore';
import apiMethods from 'common/api-methods';
import { date, PassengerName } from 'simple';
import { Loader } from 'components/simple';
import Table from 'components/table/table';
import { $auth } from 'stores';

const invitationsColumns = [
    {
        header: 'Email',
        cell: 'email',
    },
    {
        header: 'Name',
        cell: (invite) => PassengerName({ passenger: invite }),
    },
    {
        header: 'Position',
        cell: 'position',
    },
    {
        header: 'Sender',
        cell: 'createdBy',
    },
    {
        header: 'Date',
        cell: (invite) => <>{invite.isExpired ? 'Expired' : date.format.c(invite.created)}</>,
    },
];

const InvitationsManagement = observer(() => {
    useTitle('Invitations');
    const [invitations, setInvitations] = useState(null);
    const navigate = useNavigate();

    useEffect(() => {
        API.get({
            url: $auth.permitted('ObserveAgencyInvitations')
                ? apiMethods.AGENCY_INVITATIONS
                : apiMethods.AGENT_INVITATIONS,
            success: (result) => {
                setInvitations(result.sort((a, b) => new Date(b.created) - new Date(a.created)));
                if (!result.length) navigate('/settings/agency/invitations/send');
            },
        });
    }, []);

    if (!invitations?.length) {
        return <Loader />;
    }

    return (
        <div className="cabinet block">
            <section>
                {$auth.permitted('AgentInvitation') && (
                    <Link to="/settings/agency/invitations/send" style={{ float: 'right' }}>
                        <button className="button main" style={{ marginTop: -20 }}>
                            Invite an Agent
                        </button>
                    </Link>
                )}
                <h2>Unaccepted Invitations</h2>
                <Table
                    list={invitations}
                    columns={invitationsColumns}
                    textEmptyList="There are no available invitations"
                    onRowClick={(item) => navigate(`/settings/agency/invitations/${item.id}`)}
                />
            </section>
        </div>
    );
});

export default InvitationsManagement;
