import React, { useState, useEffect } from 'react';
import { observer } from 'mobx-react';
import { FieldText } from 'components/form';
import DestinationDropdown from './dropdown-destination';
import { API } from 'htcore';
import osakaApiMethods from './osaka-api-methods';

const getIdOfInput = (id) => id + 'Input';

const FieldDestination = observer(({ formik, id, label, placeholder, short, filter, onChange, required }) => {
    const [options, setOptions] = useState([]);
    const [suggestion, setSuggestion] = useState(null);

    useEffect(() => {
        if (!formik.values[getIdOfInput(id)] && formik.values[id]) {
            API.get({
                osaka: osakaApiMethods.LOCATION_PREDICTION_RESTORE(formik.values[id]),
                success: (data) => {
                    if (data?.predictionText) formik.setFieldValue(getIdOfInput(id), data.predictionText);
                },
            });
        }
    }, []);

    const setDestinationSuggestion = (newOptions) => {
        if (newOptions?.length) {
            const prediction = newOptions[0];
            setSuggestion({ text: prediction.predictionText, value: prediction });
            return;
        }
        setSuggestion(null);
    };

    const setDestinationPredictionsAndSuggestion = (newOptions) => {
        setOptions(newOptions?.length ? newOptions : null);
        setDestinationSuggestion(newOptions);
    };

    let throttle;
    const inputChanged = (event) => {
        const currentValue = event.target.value.trim();

        if (!currentValue) {
            setDestinationPredictionsAndSuggestion(null);
            return;
        }

        if (formik) formik.setFieldValue(id, null);

        clearTimeout(throttle);
        throttle = setTimeout(() => {
            API.get({
                osaka: osakaApiMethods.LOCATION_PREDICTION_SEARCH,
                body: {
                    text: currentValue,
                    ...(filter ? { types: filter } : {}),
                },
                success: (data) => {
                    if (currentValue !== event.target.value.trim()) return;
                    setDestinationPredictionsAndSuggestion(
                        data.map((item) => item.predictions).flat() // todo: restore categories
                    );
                },
            });
        }, 200);
    };

    const setValue = (item, silent) => {
        if (!item) return;
        formik.setFieldValue(id, item.htId);
        if (silent !== true || short) {
            formik.setFieldValue(getIdOfInput(id), item.predictionText);
        }
        if (silent !== true) setDestinationPredictionsAndSuggestion(null);
        if (onChange) onChange(item);
    };

    const onFocusChanged = (focused, onlyStyles) => {
        if (short) {
            const formElement = document.querySelector('.form.short');
            if (formElement) {
                if (!formElement.style?.width && focused) {
                    formElement.style.width = formElement.offsetWidth + 'px';
                    formElement.style.borderColor = '#fff';
                }
                if (formElement.style?.width && !focused) {
                    setTimeout(() => {
                        formElement.style.width = '';
                        formElement.style.borderColor = '';
                    }, 15);
                }
            }
        }
        if (!focused && !onlyStyles && formik.values[getIdOfInput(id)] !== formik.values[id] && suggestion) {
            setValue(suggestion.value);
        }
    };

    const onClear = () => {
        formik.setFieldValue(id, null);
        formik.setFieldValue(getIdOfInput(id), '');
        if (onChange) {
            onChange(null);
        }
    };

    return (
        <FieldText
            formik={formik}
            id={getIdOfInput(id)}
            additionalFieldForValidation={id}
            label={label}
            placeholder={placeholder}
            Icon={<span className="icon icon-search-location" />}
            Dropdown={DestinationDropdown}
            options={options}
            setValue={setValue}
            onChange={inputChanged}
            className={'capitalize' + __class(short, 'overall')}
            noInput={short ? 'destination' : false}
            onFocusChanged={onFocusChanged}
            suggestion={suggestion}
            clearable
            onClear={onClear}
            required={required}
        />
    );
});

export default FieldDestination;
