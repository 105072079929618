export const remapBoardBasis = (boardBasis = '') => {
    boardBasis = String(boardBasis);

    return (
        {
            RoomOnly: 'Room Only',
            SelfCatering: 'Self-catering',
            BedAndBreakfast: 'Bed and Breakfast',
            HalfBoard: 'Half Board',
            FullBoard: 'Full Board',
            AllInclusive: 'All-inclusive',
            NotSpecified: 'Other',
        }[boardBasis] || boardBasis.replace(/([A-Z])/g, ' $1').trim()
    );
};
