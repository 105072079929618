import { makeAutoObservable } from 'mobx';
import autosave from 'htcore/misc/autosave';

class AuthStore {
    token = null;
    information = null;
    settings = {
        weekStarts: 1,
        availableCredit: true,
    };
    agency = null;
    agencySettings = {};
    initialized = false;

    constructor() {
        makeAutoObservable(this);
        autosave(this, '_auth_store_cache');
    }

    get activeAgency() {
        return this.information?.agencyRelations?.[0] || null;
    }

    permitted(permission) {
        return this.activeAgency?.inAgencyPermissions?.includes(permission) || false;
    }

    setToken(values) {
        this.token = values;
    }
    setInformation(values) {
        this.information = values;
        this.initialized = true;
    }

    setSettings(values) {
        this.settings = values;
    }

    setAgency(values) {
        this.agency = values;
    }

    setAgencySettings(values) {
        this.agencySettings = values;
    }
}

export default new AuthStore();
