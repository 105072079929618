import React from 'react';
import { Link } from 'react-router-dom';
import { authorized } from 'htcore';

const MainBlock = () => {
    const isAuthorized = authorized();

    return (
        <div className="home-main-block">
            <div className="container">
                <section className="section-wrapper">
                    <div className="heading">
                        <h1 className="title">Streamline your travel company's success</h1>
                        <p className="text">
                            Partner with us to unlock exclusive travel experiences and premium savings for
                            your clients
                        </p>
                        {isAuthorized ? (
                            <Link to="/search" className="request-button">
                                <button className="lg-button button-dark">Search Accommodation</button>
                            </Link>
                        ) : (
                            <Link to="/signup" className="request-button">
                                <button className="lg-button button-dark">Request Contact</button>
                            </Link>
                        )}
                    </div>

                    <div className="content">
                        <img className="first-block" src="/images/landing/home/nyedition.jpg" alt="Hotel" />

                        <div className="second-block">
                            <img src="/images/landing/home/lrm-card.png" alt="Accommodaion card" />
                        </div>

                        <a className="third-block" href="#technology">
                            <img src="/images/landing/home/maldives.jpg" alt="Maldives" />
                            <div className="inside-info moved-arrow-wrapper">
                                <div className="destination-plate">
                                    <p className="destination-label">Destination</p>
                                    <div className="destination-place">
                                        <span className="icon icon-place" />
                                        <span className="place-name">Maldives</span>
                                    </div>
                                </div>
                                <div className="arrow-wrapper">
                                    <div className="circle-arrow move-up-down-arrow">
                                        <span className="icon icon-arrow" />
                                    </div>
                                </div>
                            </div>
                        </a>

                        <Link to="/product" className="fourth-block">
                            <img src="/images/landing/home/gh.jpg" alt="Grosvenor House" />
                            <div className="inside-info moved-arrow-wrapper">
                                <div className="circle-arrow">
                                    <span className="icon icon-arrow" />
                                </div>
                                <p className="heading">Discover our product in details</p>
                            </div>
                        </Link>
                        <div className="fifth-block">
                            <div className="info">
                                <h3>3000 direct contracts</h3>
                                <p>and ensuring the most competitive B2B rates</p>
                            </div>
                            <Link to="/about-us" className="info moved-arrow-wrapper">
                                <div className="circle-arrow">
                                    <span className="icon icon-arrow" />
                                </div>

                                <h3>400+ years</h3>
                                <p>of combined experience</p>
                                <Link to="/about-us" className="dark-link">
                                    About us
                                </Link>
                            </Link>
                        </div>
                    </div>
                </section>
            </div>
        </div>
    );
};

export default MainBlock;
