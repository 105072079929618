import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { API } from 'htcore';
import { Loader } from 'components/simple';
import apiMethods from 'common/api-methods';
import ViewFailed from 'common/misc/view-failed';
import SimpleHeader from 'common/template/header/simple-header';
import PageTemplate from 'common/template/page-template';

const ChangeEmailConfirmation = () => {
    const { code } = useParams();
    const [isSuccess, setSuccess] = useState(null);
    const [error, setError] = useState('');

    useEffect(() => {
        API.post({
            noAuth: true,
            ignoreErrors: true,
            url: apiMethods.AGENT_CHANGE_EMAIL_CONFIRMATION(code),
            success: () => setSuccess(true),
            error: ({ detail }) => {
                setSuccess(false);
                setError(detail);
            },
        });
    }, []);

    if (isSuccess) {
        return (
            <PageTemplate AlternativeHeader={() => <></>}>
                <SimpleHeader />
                <ViewFailed title="Agent`s email changed!" link="/search" button="Return to Main Page" />
            </PageTemplate>
        );
    }

    if (error) {
        return (
            <PageTemplate AlternativeHeader={() => <></>}>
                <SimpleHeader />
                <ViewFailed
                    reason={'Unable to confirm email change: ' + error}
                    link="/search"
                    button="Return to Main Page"
                />
            </PageTemplate>
        );
    }

    return <Loader />;
};

export default ChangeEmailConfirmation;
