import React, { useState } from 'react';
import { FieldCheckbox } from 'components/form';

const SHORT_LENGTH = 10;

export const FilterCheckboxList = ({ filter, idPrefix, formik }) => {
    const [isAllShown, setShowAll] = useState(false);

    const somethingHidden = !isAllShown && filter.length > SHORT_LENGTH + 2;

    if (somethingHidden) {
        filter = filter.slice(0, SHORT_LENGTH);
    }

    return (
        <>
            {filter.map((item) => (
                <div key={item.title}>
                    <FieldCheckbox
                        formik={formik}
                        label={
                            <div>
                                {item.title} <span className="district-count">({item.count})</span>
                            </div>
                        }
                        id={idPrefix + item.id}
                        onChange={formik.handleSubmit}
                    />
                </div>
            ))}
            {somethingHidden && (
                <button className="link" onClick={() => setShowAll(true)}>
                    Show All
                </button>
            )}
        </>
    );
};
