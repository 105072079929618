import React from 'react';
import BookingRoom from './booking-room';
import BookingServiceRoom from './booking-service-room';

const BookingDetailsView = ({ isService, booking }) => {
    const details = booking.bookingDetails;
    const { roomDetails: rooms } = details;

    return rooms.map((room, index) =>
        isService ? (
            <BookingServiceRoom key={index} room={room} booking={booking} />
        ) : (
            <BookingRoom key={index} room={room} details={details} booking={booking} />
        )
    );
};

export default BookingDetailsView;
