import React from 'react';
import { useTitle } from 'htcore';
import { useParams } from 'react-router-dom';
import { setInvite } from 'tasks/signup/invitation';
import { OidcInstance } from 'htcore/auth';

const AcceptInvite = () => {
    useTitle('Sign Up');
    const { code, email } = useParams();

    if (code && email) {
        setInvite(code);
        OidcInstance()
            .removeUser()
            .then(() => {
                OidcInstance().signinRedirect({
                    extraQueryParams: {
                        customRedirectUrl: window.location.origin + '?invCode=' + code,
                        redirectToRegister: true,
                        userMail: email,
                    },
                });
            });
    }

    return null;
};

export default AcceptInvite;
