import React, { useState, useEffect } from 'react';
import { observer } from 'mobx-react';
import { useNavigate } from 'react-router-dom';
import { useTitle } from 'htcore';
import SimpleHeader from 'common/template/header/simple-header';
import PageTemplate from 'common/template/page-template';
import { Loader } from 'components/simple';
import { FORM_NAMES } from 'components/form';
import { registerAgency } from 'tasks/signup/signup-finish';
import { forgetInvite } from 'tasks/signup/invitation';
import SignUpCompanyForm from './signup-company-form';
import SignUpModal from './signup-modal';
import SignUpModalSuccess from './signup-modal-success';
import { $auth, $ui } from 'stores';
import Consent from '../../landing/components/consent';
import useGoogleAnalytics from '../../landing/hooks/use-google-analytics';

const SignUpPage = observer(() => {
    useTitle('Sign Up');
    useGoogleAnalytics();

    const [loading, setLoading] = useState(false);
    const [modalVisibility, setModalVisibility] = useState(false);
    const [successModalVisibility, setSuccessModalVisibility] = useState(false);
    const navigate = useNavigate();

    const submitAgencyForm = (agencyForm, captcha) => {
        setLoading(true);
        registerAgency({ ...agencyForm, captcha }, setLoading, () => {
            setSuccessModalVisibility(true);
            $ui.dropFormCache(FORM_NAMES.RegistrationCompanyForm);

            window.dataLayer = window.dataLayer || [];
            function gtag() {
                dataLayer.push(arguments);
            }
            gtag('event', 'access-request-form-success', { value: 'submitted' });
        });
    };

    if ($auth.information?.email) {
        forgetInvite();
        navigate('/search');
    }

    return (
        <PageTemplate AlternativeHeader={() => null} AlternativeFooter={() => null}>
            <Consent />
            <div className="account block white">
                <SimpleHeader />
                {loading && <Loader page />}
                <section className="section">
                    <SignUpCompanyForm initialValues={{}} submit={submitAgencyForm} />
                </section>
            </div>
            <SignUpModal isOpen={modalVisibility} onClose={() => setModalVisibility(false)} authOverlay />
            <SignUpModalSuccess
                isOpen={successModalVisibility}
                onClose={() => {
                    setSuccessModalVisibility(false);
                    window.location.href = '/';
                    // todo: navigate('/'); // google analytics issue
                }}
                authOverlay
            />
        </PageTemplate>
    );
});

export default SignUpPage;
