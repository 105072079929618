[
    {
        "position": "Senior Marketing Manager",
        "location": "Dubai, UAE"
    },
    {
        "position": "Senior Marketing Manager",
        "location": "Dubai, UAE"
    },
    {
        "position": "Senior Marketing Manager",
        "location": "Dubai, UAE"
    }
]
