import React, { useState, useEffect } from 'react';
import { observer } from 'mobx-react';
import { useTitle } from 'htcore';
import { Loader } from 'components/simple';
import { CachedForm, FieldSelect, FieldSwitch, FORM_NAMES } from 'components/form';
import FieldCountry from 'components/complex/field-country';
import { loadAgentSettings, saveAgentSettings } from 'tasks/utils/agent-settings';
import { $ui, $auth } from 'stores';

const ApplicationSettings = observer(() => {
    useTitle('Application Settings');
    const [loading, setLoading] = useState(false);

    const submitAgentSettings = (values) => {
        const shouldDropSearchCache =
            values.nationality !== $auth.settings.nationality ||
            values.residency !== $auth.settings.residency;
        setLoading(true);
        saveAgentSettings(values, () => {
            if (shouldDropSearchCache) {
                $ui.dropFormCache(FORM_NAMES.SearchForm);
            }
            setLoading(false);
        });
    };

    useEffect(() => {
        loadAgentSettings();
    }, []);

    if (loading) return <Loader page />;

    return (
        <div className="cabinet block">
            <section>
                <h2>Application Settings</h2>
                <CachedForm
                    initialValues={$auth.settings}
                    onSubmit={submitAgentSettings}
                    render={(formik) => (
                        <div className="form app-settings" style={{ maxWidth: 500 }}>
                            {$auth.permitted('ObserveBalance') && (
                                <div className="row">
                                    <FieldSwitch
                                        formik={formik}
                                        id="availableCredit"
                                        label="Show Available Credit"
                                    />
                                </div>
                            )}
                            <div className="row">
                                <FieldSelect
                                    formik={formik}
                                    id="weekStarts"
                                    label="Week starts on"
                                    placeholder="Monday"
                                    options={[
                                        { value: 7, text: 'Sunday' },
                                        { value: 1, text: 'Monday' },
                                        { value: 2, text: 'Tuesday' },
                                        { value: 3, text: 'Wednesday' },
                                        { value: 4, text: 'Thursday' },
                                        { value: 5, text: 'Friday' },
                                        { value: 6, text: 'Saturday' },
                                    ]}
                                />
                            </div>
                            <div className="row">
                                <FieldCountry
                                    formik={formik}
                                    id="nationality"
                                    anotherField="residency"
                                    label="Nationality"
                                    placeholder="Choose your nationality"
                                    clearable
                                />
                            </div>
                            <div className="row">
                                <FieldCountry
                                    formik={formik}
                                    id="residency"
                                    anotherField="nationality"
                                    label="Residency"
                                    placeholder="Choose your residency"
                                    clearable
                                />
                            </div>
                            <div className="row controls">
                                <div className="field">
                                    <div className="inner">
                                        <button
                                            type="submit"
                                            className={
                                                'button' +
                                                __class(!formik.isValid || !formik.dirty, 'disabled')
                                            }
                                            disabled={!formik.isValid || !formik.dirty}
                                        >
                                            Save Changes
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}
                />
            </section>
        </div>
    );
});

export default ApplicationSettings;
