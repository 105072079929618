import React, { useState } from 'react';
import { API } from 'htcore';
import apiMethods from 'common/api-methods';
import { INVOICE_TYPES } from 'enum';
import SendDocumentModal from './send-document-modal';
import { $notifications } from 'stores';

const createUrlParams = (allInvoices, isService) => {
    if (isService) {
        return allInvoices ? apiMethods.SERVICE_ALL_INVOICES_ARCHIVE : apiMethods.SERVICE_INVOICE_PDF;
    }
    return allInvoices ? apiMethods.BOOKING_ALL_INVOICES_ARCHIVE : apiMethods.BOOKING_INVOICE_PDF;
};

const createRequestParams = (allInvoices, isService) => ({
    url: createUrlParams(allInvoices, isService),
    errorMessage: allInvoices ? "Couldn't get an Invoices archive" : "Couldn't get an Invoice file",
    format: allInvoices ? 'zip' : 'pdf',
});

const AccommodationConfirmationInvoiceButtons = ({
    isService,
    isItineraryBooking,
    bookingId,
    referenceCode,
}) => {
    const [modalVisibility, setModalVisibility] = useState(false);

    const downloadInvoice = (allInvoices) => {
        const { url, errorMessage, format } = createRequestParams(allInvoices, isService);

        API.get({
            url: url(bookingId),
            response: (res) => {
                if (res.status === 400) $notifications.addNotification(errorMessage);
                if (res.status === 200)
                    res.blob().then((blobby) => {
                        let anchor = document.createElement('a');
                        document.body.appendChild(anchor);

                        const objectUrl = window.URL.createObjectURL(blobby);
                        anchor.href = objectUrl;
                        anchor.download = `invoice-${referenceCode}.${format}`;
                        anchor.click();

                        window.URL.revokeObjectURL(objectUrl);
                    });
            },
        });
    };

    return (
        <>
            <div onClick={() => downloadInvoice()} className="line">
                Invoice File
            </div>
            {isItineraryBooking && (
                <div onClick={() => downloadInvoice(true)} className="line">
                    Load All Invoices
                </div>
            )}
            <div onClick={() => setModalVisibility(Number(new Date()))} className="line">
                Send Invoice Email
            </div>
            <SendDocumentModal
                isService={isService}
                key={modalVisibility}
                isOpen={modalVisibility}
                onClose={() => setModalVisibility(false)}
                type={INVOICE_TYPES.INVOICE}
                bookingId={bookingId}
                referenceCode={referenceCode}
            />
        </>
    );
};

export default AccommodationConfirmationInvoiceButtons;
