import React from 'react';

const Flag = ({ code }) => {
    if (!code) return null;

    return (
        <span className="flag">
            <span className={'fp ' + code.toLowerCase()} />
        </span>
    );
};

export default Flag;
