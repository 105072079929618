import React, { useEffect } from 'react';
import { CookieConsent, getCookieConsentValue } from 'react-cookie-consent';

const consentApply = () => {
    function gtag() {
        dataLayer.push(arguments);
    }

    gtag('consent', 'update', {
        ad_user_data: 'granted',
        ad_personalization: 'granted',
        ad_storage: 'granted',
        analytics_storage: 'granted',
    });
};

const Consent = () => {
    useEffect(() => {
        if (getCookieConsentValue()) {
            consentApply();
        }
    }, []);

    return (
        <>
            <CookieConsent
                buttonText="I agree"
                style={{ background: '#f4f4f7', color: '#212121', alignItems: 'center' }}
                buttonStyle={{
                    color: '#212121',
                    fontSize: '15px',
                    borderRadius: 22,
                    padding: '7px 20px',
                    background: '#fdca00',
                }}
                declineButtonStyle={{
                    color: '#212121',
                    fontSize: '15px',
                    borderRadius: 22,
                    padding: '7px 20px',
                    background: '#ccc',
                }}
                enableDeclineButton
                onAccept={consentApply}
            >
                <h5 style={{ fontWeight: 500, fontSize: '18px', lineHeight: '30px' }}>We use cookies</h5>
                <p style={{ maxWidth: 900, fontSize: '14px', lineHeight: '21px' }}>
                    We use cookies and other tracking technologies to improve your browsing experience on our
                    website, to show you personalized content and targeted ads, to analyze our website
                    traffic, and to understand where our visitors are coming from.
                </p>
            </CookieConsent>
        </>
    );
};

export default Consent;
