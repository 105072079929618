import React from 'react';

const mapType = {
    Single: 'Single',
    TwinOrSingle: 'Twin/Single',
    Twin: 'Twin',
    Double: 'Double',
    Triple: 'Triple',
    Quadruple: 'Quadruple',
    Family: 'Family Room',
};

export const GroupRoomTypesAndCount = ({ contracts, text }) => {
    let count = {};
    let result = [];
    for (let i = 0; i < contracts.length; i++) {
        let description = (contracts[i].contractName || '').trim();
        if (contracts[i].contractDescription) {
            if (description) {
                description += ` (${contracts[i].contractDescription})`;
            } else {
                description = contracts[i].contractDescription;
            }
        }
        if ('NotSpecified' !== contracts[i].type) {
            description =
                (mapType[contracts[i].type] || contracts[i].type) +
                (description && description !== contracts[i].type ? ': ' + description : '');
        }
        if (!description) {
            description = 'Room';
        }
        if (count.hasOwnProperty(description)) {
            count[description]++;
        } else {
            count[description] = 1;
        }
    }

    for (let item in count) result.push((count[item] > 1 ? count[item] + ' x ' : '') + item);

    if (text || result.length === 1) return result.join(', ');

    return result.map((item, index) => <div key={index}>{item}</div>);
};
