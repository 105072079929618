import React from 'react';
import { useParams } from 'react-router-dom';
import { useTitle } from 'htcore';
import DeniedPageTemplate from './templates/denied-page-template';

const DeactivatedPage = () => {
    useTitle('Not Allowed');
    const { subject } = useParams();

    let text =
        'Your account has been deactivated. Kindly ask you to contact your Agency administrator to process this issue.';

    if (subject === 'agency') {
        text =
            'Your agency has been deactivated. Kindly ask you to contact the Happytravel.com support to process this issue.';
    }

    return <DeniedPageTemplate title="Not Allowed" text={text} linkTo="/logout" linkText="Log out" />;
};

export default DeactivatedPage;
