import { useEffect, useState } from 'react';
import { HTCore } from '../base/init-application';
import { authorized, OidcInstance } from './index';
import { useNavigate } from 'react-router-dom';

const AuthKeeperComponent = ({ children }) => {
    const [isVisible, setVisibility] = useState(false);
    const navigate = useNavigate();
    const onLogin = HTCore.onLogin;
    const Loader = HTCore.Loader;

    useEffect(() => {
        OidcInstance()
            .getUser()
            .then((auth) => {
                if (auth?.access_token && authorized()) {
                    onLogin(auth, navigate);
                    setVisibility(true);
                } else {
                    if (authorized()) {
                        OidcInstance()
                            .signinSilent()
                            .then((authSilent) => {
                                if (authSilent?.access_token) {
                                    onLogin(authSilent, navigate);
                                    setVisibility(true);
                                }
                            })
                            .catch(() => {
                                OidcInstance().signinRedirect({ state: window.location.pathname });
                            });
                    } else {
                        OidcInstance().signinRedirect({ state: window.location.pathname });
                    }
                }
            });
    }, []);

    if (!isVisible) {
        return <Loader />;
    }

    return children;
};

export default AuthKeeperComponent;
