import { $notifications } from 'stores';

export const copyToClipboard = (text) => {
    $notifications.addNotification('Copied to Clipboard!', null, 'success');
    if (window.clipboardData && window.clipboardData.setData) {
        return clipboardData.setData('Text', text);
    } else if (document.queryCommandSupported && document.queryCommandSupported('copy')) {
        const textarea = document.createElement('textarea');
        textarea.textContent = text;
        textarea.style.position = 'fixed';
        document.body.appendChild(textarea);
        textarea.select();
        try {
            return document.execCommand('copy');
        } catch (ex) {
            return false;
        } finally {
            document.body.removeChild(textarea);
        }
    }
};
