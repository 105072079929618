import React, { useState } from 'react';
import { observer } from 'mobx-react';
import { API } from 'htcore';
import apiMethods from 'common/api-methods';
import { hotelConfirmationCodeValidator } from 'components/form/validation';
import { Loader } from 'components/simple';
import { CachedForm, FieldText } from 'components/form';
import { $auth } from 'stores';

const BookingHotelConfirmationCode = observer(({ serviceCode, loadBooking, bookingDetails }) => {
    const [loading, setLoading] = useState(false);
    const [editor, setEditor] = useState(false);
    const { referenceCode, hotelConfirmationCode = '' } = bookingDetails;
    const isButtonVisible =
        $auth.permitted('ModifyBooking') &&
        $auth.agencySettings.canModifyBookingSupplierReferenceCode &&
        !['ManualCorrectionNeeded', 'Rejected', 'Discarded', 'Completed'].includes(bookingDetails.status);

    const submit = (body) => {
        setLoading(true);
        API.put({
            url: apiMethods.BOOKING_HOTEL_CONFIRMATION_CODE(referenceCode, !!serviceCode),
            body,
            success: loadBooking,
            after: () => {
                setLoading(false);
                setEditor(false);
            },
        });
    };

    if (editor) {
        return (
            <div className="additional-reference">
                {loading && <Loader page />}
                <div className="title">Hotel Confirmation Code</div>
                <CachedForm
                    initialValues={{ hotelConfirmationCode }}
                    validationSchema={hotelConfirmationCodeValidator}
                    onSubmit={submit}
                    render={(formik) => (
                        <>
                            <div className="form">
                                <div className="row">
                                    <FieldText
                                        formik={formik}
                                        id="hotelConfirmationCode"
                                        placeholder="New Code"
                                    />
                                </div>
                                <div className="bottom">
                                    <button className="button main" type="submit">
                                        Save
                                    </button>
                                </div>
                            </div>
                        </>
                    )}
                />
            </div>
        );
    }

    if (!hotelConfirmationCode || hotelConfirmationCode === referenceCode) {
        if (isButtonVisible) {
            return (
                <div className="additional-reference">
                    <button className="button small" onClick={() => setEditor(true)}>
                        Set Hotel Confirmation Code
                    </button>
                </div>
            );
        }
        return null;
    }

    return (
        <div className="additional-reference">
            <div className="title">Hotel Confirmation Code</div>
            <div>
                <span className="status">{hotelConfirmationCode}</span>
            </div>

            {isButtonVisible && (
                <button className="button small" onClick={() => setEditor(true)} style={{ marginTop: 10 }}>
                    Change Hotel Confirmation Code
                </button>
            )}
        </div>
    );
});

export default BookingHotelConfirmationCode;
