import React from 'react';
import { Link } from 'react-router-dom';
import SimpleHeader from 'common/template/header/simple-header';

const DeniedPageTemplate = ({
    title = 'Denied',
    text = 'You need more permissions to access this page',
    linkTo = '/search',
    linkText = 'Back to Homepage',
}) => (
    <div className="denied-page">
        <SimpleHeader />
        <div className="picture">
            <div className="text">
                <h1>{title}</h1>
                <h3>{text}</h3>
            </div>
            <Link to={linkTo}>
                <span className="button">{linkText}</span>
            </Link>
        </div>
    </div>
);

export default DeniedPageTemplate;
