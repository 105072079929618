import React, { useState, useEffect, useRef } from 'react';

const Swiper = ({ children, className, slideWidth = 670, CustomArrows }) => {
    const [startX, setStartX] = useState(0);
    const [isMouseDown, setIsMouseDown] = useState(false);
    const [scrollLeft, setScrollLeft] = useState(0);
    const [hideRightArrow, setHideRightArrow] = useState(false);

    const swiperRef = useRef(null);

    const getIsHideRightArrow = () => {
        const scrollWidth = swiperRef.current.scrollWidth - swiperRef.current.offsetWidth;
        const rightPosition = Math.round(swiperRef.current.scrollLeft);

        setHideRightArrow(!(scrollWidth - rightPosition));
    };

    const handleOnMouseDown = (event) => {
        setStartX(event.clientX);
        setIsMouseDown(true);
    };

    const handleOnMouseMove = (event) => {
        if (!isMouseDown || !swiperRef.current) return;
        event.preventDefault();
        const deltaX = (event.clientX - startX) * 0.115;
        swiperRef.current.scrollLeft = scrollLeft - deltaX;
    };

    const handleOnMouseUp = () => setIsMouseDown(false);

    const handleOnScroll = () => {
        getIsHideRightArrow();
        setScrollLeft(swiperRef.current.scrollLeft);
    };

    const changeScrollStyle = () => {
        swiperRef.current.style.scrollBehavior = 'smooth';

        setTimeout(() => {
            swiperRef.current.style.scrollBehavior = 'auto';
        }, 0);
    };

    const scrollToLeft = () => {
        changeScrollStyle();
        swiperRef.current.scrollLeft = 0;
    };

    const scrollToRight = () => {
        changeScrollStyle();
        swiperRef.current.scrollLeft = swiperRef.current.scrollLeft + slideWidth;
    };

    useEffect(() => {
        swiperRef.current && getIsHideRightArrow();
    }, [swiperRef.current]);

    return (
        <div className={`new-swiper-lg ${className}`}>
            {CustomArrows ? (
                CustomArrows({ hideRightArrow, hideLeftArrow: scrollLeft === 0, scrollToLeft, scrollToRight })
            ) : (
                <div
                    className={`${scrollLeft === 0 ? 'hide-arrow' : ''} circle-arrow prev-arrow`}
                    onClick={scrollToLeft}
                >
                    <div className="icon icon-arrow" />
                </div>
            )}

            <div
                className="slider-list"
                onMouseDown={(event) => handleOnMouseDown(event)}
                onMouseMove={(event) => handleOnMouseMove(event)}
                onMouseUp={handleOnMouseUp}
                onMouseLeave={() => setIsMouseDown(false)}
                onScroll={handleOnScroll}
                ref={swiperRef}
            >
                <div className="slider-track">{children}</div>
            </div>

            {!CustomArrows && (
                <div
                    className={`${hideRightArrow ? 'hide-arrow' : ''} circle-arrow next-arrow`}
                    onClick={scrollToRight}
                >
                    <div className="icon icon-arrow " />
                </div>
            )}
        </div>
    );
};

export default Swiper;
