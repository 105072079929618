import React, { useState, useCallback, useRef, useEffect } from 'react';
import { getIn } from 'formik';
import { observer } from 'mobx-react';
import { decorate, useDropdown } from 'simple';

const FieldText = observer(
    ({
        label,
        placeholder,
        Icon,
        AfterIcon,
        clearable,
        password,
        className,
        id,
        Dropdown,
        caption,
        value,
        disabled,
        required,
        readOnly,
        options,
        ValueObject,
        maxLength,
        maxValue,
        numeric,
        suggestion,
        formik,
        setValue,
        additionalFieldForValidation,
        autoComplete,
        onChange,
        onClear,
        noInput,
        onFocusChanged,
    }) => {
        const [focused, setFocused] = useState(false);
        const [optionIndex, setOptionIndex] = useState(null);

        const refElement = useRef(null);
        const refDropdown = useRef(null);
        const [dropdownOpen, toggleDropdown] = useDropdown(refElement, refDropdown);

        const focus = useCallback(() => {
            if (Dropdown) {
                toggleDropdown(true);
                setOptionIndex(null);
            }
            if (noInput && formik) formik.setFieldTouched(id, true);
            setFocused(true);
            if (onFocusChanged) onFocusChanged(true);
        }, []);

        const blur = useCallback(
            (event, onlyStyles) => {
                if (formik && event) formik.handleBlur(event);
                if (onFocusChanged) onFocusChanged(false, onlyStyles);
                setFocused(false);
            },
            [suggestion]
        );

        const onKeyDown = (event) => {
            if (!Dropdown || !options?.length) return;

            const scroll = document.querySelectorAll(`.dropdown .scroll > div:not(.subtitle)`),
                scrollElem = document.querySelector(`.dropdown .scroll`);

            switch (event.key) {
                case 'Enter':
                case 'ArrowRight':
                    if (optionIndex !== null || suggestion?.value) {
                        event.preventDefault();
                        setValue(optionIndex !== null ? options[optionIndex] : suggestion?.value, false);
                        blur(null, true);
                        toggleDropdown(false);
                    }
                    break;
                case 'ArrowUp':
                case 'ArrowDown':
                    let newIndex = optionIndex === null ? options.length - 1 : optionIndex - 1;
                    if ('ArrowDown' === event.key) {
                        newIndex = optionIndex === null ? 0 : optionIndex + 1;
                    }
                    if (newIndex >= 0 && newIndex < options.length) {
                        setOptionIndex(newIndex);
                        scrollElem?.scrollTo(0, scroll?.[newIndex]?.offsetTop - 20);
                    }
                    if (setValue) setValue(options[optionIndex], true);
                    break;
                default:
                    return;
            }
        };

        const clear = useCallback(() => {
            if (formik) {
                formik.setFieldTouched(id, false);
                formik.setFieldValue(id, '');
                if (additionalFieldForValidation) formik.setFieldValue(additionalFieldForValidation, false);
            }
            if (onClear) onClear();
        }, []);

        const changing = useCallback((event) => {
            if (Dropdown) {
                toggleDropdown(true);
                setOptionIndex(null);
            }
            if (numeric) {
                if ('/' === numeric) event.target.value = event.target.value.replace(/[^0-9.\/ ]/g, '');
                else if ('.' === numeric) event.target.value = event.target.value.replace(/[^0-9.,]/g, '');
                else if ('-' === numeric) event.target.value = event.target.value.replace(/[^0-9\-.,]/g, '');
                else event.target.value = event.target.value.replace(/[^0-9]/g, '');
                if (maxValue !== undefined && parseInt(event.target.value) > maxValue)
                    event.target.value = String(maxValue);
            }
            if (onChange) onChange(event, formik, id);
            if (formik) {
                formik.setFieldTouched(id, true);
                formik.handleChange(event);
            }
        }, []);

        const dropdownSetValue = (...params) => {
            if (setValue) setValue(...params);
            blur(null, true);
            toggleDropdown(false);
        };

        if (Dropdown) {
            useEffect(() => {
                if (dropdownOpen === false) {
                    blur(null);
                }
            }, [dropdownOpen]);
        }

        const fieldValue = getIn(formik?.values, id);

        let suggestionText = decorate.cutFirstPart(suggestion?.text, fieldValue);

        let errorText = getIn(formik?.errors, id);
        let additionalFieldError =
            additionalFieldForValidation && getIn(formik?.errors, additionalFieldForValidation);
        if (Dropdown && dropdownOpen) {
            errorText = !!errorText;
            additionalFieldError = !!additionalFieldError;
        }

        // todo: short search destination field workaround. rewrite in future
        if (noInput === 'destination') {
            if (dropdownOpen) {
                noInput = false;
            } else {
                value = true;
                ValueObject = formik.values.destinationInput;
            }
        }

        if (ValueObject !== undefined) {
            if (ValueObject) ValueObject = <div className="value-object">{ValueObject}</div>;
            else ValueObject = <div className="value-object placeholder">{placeholder}</div>;
        }

        let finalValue = '';
        if (!ValueObject) {
            finalValue = value || (formik?.values ? fieldValue : '');
            if (finalValue === 0) finalValue = '0';
            if (!finalValue) finalValue = '';
        }

        return (
            <div
                className={
                    'field' +
                    __class(className) +
                    __class(focused, 'focus') +
                    __class(disabled, 'disabled') +
                    __class(noInput, 'no-input') +
                    __class((errorText || additionalFieldError) && formik.touched[id], 'error') +
                    __class(!errorText && finalValue, 'valid')
                }
                ref={refElement}
            >
                <label onClick={noInput ? focus : null}>
                    {label && (
                        <div className="label">
                            <span className={__class(required, 'required')}>{label}</span>
                        </div>
                    )}
                    <div className="input">
                        {!!Icon && <div className="icon-wrap">{Icon}</div>}
                        {!noInput ? (
                            <div className="inner">
                                <input
                                    name={id}
                                    type={password ? 'password' : 'text'}
                                    placeholder={!ValueObject ? placeholder : ''}
                                    onFocus={focus}
                                    onChange={changing}
                                    onBlur={Dropdown ? null : blur}
                                    value={finalValue}
                                    onKeyDown={onKeyDown}
                                    disabled={!!disabled}
                                    maxLength={maxLength}
                                    autoComplete={autoComplete ? autoComplete : 'off'}
                                    {...(readOnly ? { readOnly: 'readonly' } : {})}
                                />
                                {ValueObject}
                                {suggestionText && (
                                    <div className={'suggestion' + __class(numeric, 'solid')}>
                                        <span>{fieldValue || value}</span>
                                        {suggestionText}
                                    </div>
                                )}
                            </div>
                        ) : (
                            <div className="inner">
                                {value || finalValue ? (
                                    ValueObject ? (
                                        ValueObject
                                    ) : (
                                        finalValue
                                    )
                                ) : (
                                    <span className="placeholder">{placeholder}</span>
                                )}
                            </div>
                        )}
                        {AfterIcon && <div className="after-icon-wrap">{AfterIcon}</div>}
                        {clearable && (
                            <div hidden={!finalValue}>
                                <div className="clear" onClick={clear} />
                            </div>
                        )}
                    </div>
                    {errorText?.length > 1 && formik.touched[id] && (
                        <div
                            className={
                                'error-holder' + __class(!formik.touched[id] || focused, 'possible-hide')
                            }
                        >
                            {errorText}
                        </div>
                    )}
                </label>
                {Boolean(Dropdown) && (
                    <div hidden={!dropdownOpen} ref={refDropdown}>
                        <Dropdown
                            formik={formik}
                            connected={id}
                            setValue={dropdownSetValue}
                            value={fieldValue}
                            options={options}
                            caption={caption}
                            focusIndex={optionIndex}
                            close={() => toggleDropdown(false)}
                        />
                    </div>
                )}
            </div>
        );
    }
);

export default FieldText;
