import React, { useEffect, useState } from 'react';
import { FieldCheckbox } from 'components/form';
import { API } from 'htcore';
import apiMethods from 'common/api-methods';
import { Formik } from 'formik';
import { Loader } from 'components/simple';
import { $auth } from 'stores';

const AgentPaymentSettings = ({ agentId }) => {
    const [paymentTypes, setPaymentTypes] = useState(null);
    const contractKind = $auth.agency?.contractKind;
    const isUAE = $auth.agency?.countryCode === 'AE';

    useEffect(() => {
        API.get({
            url: apiMethods.AGENT_PAYMENT_SETTINGS(agentId),
            success: setPaymentTypes,
        });
    }, []);

    const submit = (values) => {
        const result = Object.keys(values).filter((v) => values[v]);
        const previous = paymentTypes;
        setPaymentTypes(null);
        API.put({
            url: apiMethods.AGENT_PAYMENT_SETTINGS(agentId),
            body: result,
            success: () => setPaymentTypes(result),
            error: () => setPaymentTypes(previous),
        });
    };

    if (
        contractKind !== 'OfflineOrCreditCardPayments' &&
        contractKind !== 'VirtualAccountOrCreditCardPayments'
    ) {
        return null;
    }

    if (isUAE) {
        return null;
    }

    if (!paymentTypes) {
        return <Loader />;
    }

    return (
        <div>
            <h2>Agent Payment Options</h2>
            <div>
                <Formik
                    onSubmit={submit}
                    initialValues={paymentTypes.reduce((prev, cur) => ({ ...prev, [cur]: true }), {})}
                >
                    {(formik) => (
                        <form onSubmit={formik.handleSubmit}>
                            <div className="form">
                                <div style={{ marginBottom: 15 }}>
                                    <FieldCheckbox
                                        formik={formik}
                                        id="CreditCard"
                                        label="Credit Card Payment"
                                    />
                                </div>
                                {contractKind === 'OfflineOrCreditCardPayments' && (
                                    <div style={{ marginBottom: 15 }}>
                                        <FieldCheckbox formik={formik} id="Offline" label="Offline Payment" />
                                    </div>
                                )}
                                {contractKind === 'VirtualAccountOrCreditCardPayments' && (
                                    <div style={{ marginBottom: 15 }}>
                                        <FieldCheckbox
                                            formik={formik}
                                            id="VirtualAccount"
                                            label="Payment Using Virtual Account"
                                        />
                                    </div>
                                )}
                                <div className="row controls">
                                    <div className="field">
                                        <div className="inner">
                                            <button
                                                type="submit"
                                                className={
                                                    'button button-controls' +
                                                    __class(!formik.dirty, 'disabled')
                                                }
                                                disabled={!formik.dirty}
                                            >
                                                Save Agent Payment Options
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </form>
                    )}
                </Formik>
            </div>
        </div>
    );
};

export default AgentPaymentSettings;
