import { API } from 'htcore';
import apiMethods from 'common/api-methods';

export const loadInvitationData = (code, setResult) => {
    if (code)
        API.get({
            url: apiMethods.INVITATION_DATA(code),
            success: setResult,
        });
};
