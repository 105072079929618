import React from 'react';

const formatAmenity = (amenity) => {
    let words = amenity.toLowerCase().split(' ');
    words.forEach((word, index) => {
        if (['spa', 'atm', 'tv'].includes(word)) words[index] = words[index].toUpperCase();
    });
    return words.join(' ');
};

const Amenities = ({ hotel }) => {
    if (!hotel.accommodationAmenities?.length) return null;

    const list = hotel.accommodationAmenities;

    const result = [];
    for (let i = 0; i < 3; i++) {
        let sub = [];
        for (let j = 0; j < list.length; j++) {
            if (i === j % 3) {
                sub.push(<li key={list[j]}>{formatAmenity(list[j])}</li>);
            }
        }
        result.push(
            <ul className="allotment" key={i}>
                {sub}
            </ul>
        );
    }

    return (
        <>
            <h2>Accommodation Amenities</h2>
            <div className="amenities">{result}</div>
        </>
    );
};

export default Amenities;
