import React, { useEffect } from 'react';
import { Route, BrowserRouter, Routes } from 'react-router-dom';
import ScrollToTop from './misc/scroll-to-top';
import { AuthCallback, AuthSilent, AuthLogout } from 'htcore/auth/pages';
import NotificationsComponent from './notifications/notifications-component';
import PublicRoutesPlusLanding from './public-routes-plus-landing';
import { initVersionAndStatics } from 'tasks/init/init-statics';

const RootRoutes = () => {
    useEffect(() => {
        initVersionAndStatics();
    }, []);

    return (
        <div className="body-wrapper">
            <BrowserRouter>
                <NotificationsComponent />
                <ScrollToTop>
                    <Routes>
                        <Route path="/auth/callback" element={<AuthCallback />} />
                        <Route path="/auth/silent" element={<AuthSilent />} />
                        <Route path="/logout" element={<AuthLogout />} />
                        <Route path="*" element={<PublicRoutesPlusLanding />} />
                    </Routes>
                </ScrollToTop>
            </BrowserRouter>
        </div>
    );
};

export default RootRoutes;
