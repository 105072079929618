import React from 'react';
import ReactDOM from 'react-dom/client';
import RootRoutes from './common/root-routes';
import { initApplication } from './htcore';
import settings from './settings';
import { Loader } from 'components/simple';
import { initAgent, destroyAgent } from './tasks/init/init-agent';
import { initViewport } from './tasks/init/init-viewport';
import { initSentry } from './tasks/init/init-sentry';
import { $notifications } from 'stores';

initSentry();
initViewport();

initApplication({
    settings,
    showNotification: (text, type, traceId) => {
        $notifications.addNotification(text, type, undefined, traceId);
    },
    onLogin: initAgent,
    onLogout: destroyAgent,
    Loader: () => <Loader white page />,
});

ReactDOM.createRoot(document.getElementById('app')).render(<RootRoutes />);
