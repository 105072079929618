import React, { useState, useEffect } from 'react';
import { API, useTitle } from 'htcore';
import apiMethods from 'common/api-methods';
import { Formik } from 'formik';
import { observer } from 'mobx-react';
import { Flag, Loader } from 'components/simple';
import { FieldCheckbox, FieldText, FieldTextarea } from 'components/form';
import { companyDetailsValidator } from 'components/form/validation';
import { loadAgency } from 'tasks/utils/agent-settings';
import { $auth, $notifications } from 'stores';

const AgencyLegalInformation = observer(() => {
    useTitle('Agency Details');
    const [loading, setLoading] = useState(true);
    const [agency, setAgency] = useState(null);

    useEffect(() => {
        loadAgency((result) => {
            setLoading(false);
            setAgency(result);
        });
    }, []);

    const submitAgencyData = (values) => {
        setLoading(true);
        API.put({
            url: apiMethods.AGENCY,
            body: values,
            success: (result) => setAgency(result),
            after: () => setLoading(false),
        });
    };

    const downloadContract = () => {
        API.get({
            url: apiMethods.CONTRACT_FILE,
            response: (res) => {
                if (res.status === 400) $notifications.addNotification("Couldn't get a contract file");
                if (res.status === 200)
                    res.blob().then((blobby) => {
                        let anchor = document.createElement('a');
                        document.body.appendChild(anchor);

                        const objectUrl = window.URL.createObjectURL(blobby);
                        anchor.href = objectUrl;
                        anchor.download = 'contract.pdf';
                        anchor.click();

                        window.URL.revokeObjectURL(objectUrl);
                    });
            },
        });
    };

    if (loading) {
        return <Loader />;
    }

    return (
        <div className="cabinet block">
            <section>
                {$auth.permitted('ObserveAgencyContract') && (
                    <div style={{ float: 'right' }}>
                        {$auth.agency?.isContractUploaded ? (
                            <div className="pdf-button-holder" style={{ marginTop: -12 }}>
                                <button className="button" onClick={downloadContract}>
                                    Download Contract File
                                </button>
                            </div>
                        ) : (
                            <span style={{ color: '#999' }}>No Contract Uploaded</span>
                        )}
                    </div>
                )}
                <h2>Agency Details</h2>

                <Formik
                    onSubmit={submitAgencyData}
                    validationSchema={companyDetailsValidator}
                    initialValues={{ ...agency, nonTRN: !agency.taxRegistrationNumber } || {}}
                    enableReinitialize
                >
                    {(formik) => {
                        const params = {
                            formik: formik,
                            placeholder: 'Not provided',
                            disabled: !$auth.permitted('PermissionManagement'),
                        };

                        return (
                            <form onSubmit={formik.handleSubmit}>
                                <div className="form">
                                    {formik.values.countryCode === 'AE' && (
                                        <div style={{ marginBottom: 16 }}>
                                            <FieldCheckbox
                                                {...params}
                                                label="Non VAT Registered Agency"
                                                id="nonTRN"
                                                onChange={(val) =>
                                                    val &&
                                                    setTimeout(
                                                        () =>
                                                            formik.setFieldValue('taxRegistrationNumber', ''),
                                                        10
                                                    )
                                                }
                                            />
                                        </div>
                                    )}

                                    <div className="row">
                                        {!formik.values.nonTRN && (
                                            <FieldText
                                                {...params}
                                                numeric
                                                id="taxRegistrationNumber"
                                                label="TRN"
                                                required
                                            />
                                        )}

                                        <div
                                            style={formik.values.nonTRN ? { width: '49%' } : { flexGrow: 1 }}
                                        >
                                            <FieldText {...params} id="vatNumber" label="VAT No." />
                                        </div>
                                    </div>
                                    <div className="row">
                                        <FieldText
                                            {...params}
                                            id="notificationEmail"
                                            label="Email For Notifications"
                                        />
                                        <FieldText
                                            {...params}
                                            id="billingEmail"
                                            label="Billing Email"
                                            required
                                        />
                                    </div>
                                    <div className="row">
                                        <FieldText {...params} id="phone" label="Phone" required />
                                        <FieldText {...params} id="fax" label="Fax" />
                                    </div>
                                    <div className="row">
                                        <FieldText
                                            {...params}
                                            id="countryName"
                                            label="Country"
                                            className="size-half"
                                            disabled={true}
                                            Icon={
                                                formik.values.countryCode ? (
                                                    <Flag code={formik.values.countryCode} />
                                                ) : null
                                            }
                                        />
                                        <FieldText
                                            {...params}
                                            id="city"
                                            label="City"
                                            className="size-half"
                                            disabled={true}
                                        />
                                    </div>
                                    <div className="row">
                                        <FieldText {...params} id="postalCode" label="Zip/Postal Code" />
                                        <FieldText {...params} id="website" label="Website" />
                                    </div>
                                    <div className="row">
                                        <FieldTextarea {...params} id="address" label="Address" required />
                                    </div>
                                    {$auth.permitted('PermissionManagement') && (
                                        <div className="row controls">
                                            <div className="field">
                                                <div className="inner">
                                                    <button
                                                        type="submit"
                                                        className={
                                                            'button' +
                                                            __class(
                                                                !formik.isValid || !formik.dirty,
                                                                'disabled'
                                                            )
                                                        }
                                                        disabled={!formik.isValid || !formik.dirty}
                                                    >
                                                        Save Changes
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    )}
                                </div>
                            </form>
                        );
                    }}
                </Formik>
            </section>
        </div>
    );
});

export default AgencyLegalInformation;
