import React, { useState } from 'react';
import { BOOKING_STATUS } from 'htcore/enum';
import { PassengerName, GroupRoomTypesAndCount, PassengersCount, PassengerNameAndAge } from 'simple';
import { Allotment } from 'components/accommodation';

const BookingRoom = ({ room, details, booking }) => {
    const [isExpanded, setExpanded] = useState(false);

    const isRoomAwaitingCancellation = room.awaitingCancellation;
    const isRoomCancelled =
        isRoomAwaitingCancellation ||
        room.cancelled ||
        booking.bookingDetails.status === BOOKING_STATUS.Cancelled;

    const adultsCount = room?.passengers?.filter((p) => p.age === null || p.age >= 18).length;

    return (
        <div
            className={
                'room-container' +
                (isRoomCancelled ? ' cancelled-room' : '') +
                (isExpanded ? ' expanded' : '')
            }
            onClick={() => !isExpanded && setExpanded((cur) => !cur)}
        >
            <h2>
                {room._index && `Room ${room._index}: `}
                <GroupRoomTypesAndCount text contracts={[room]} />
            </h2>

            {room.roomReferenceCode && <p className="room-code">Room Code: {room.roomReferenceCode}</p>}

            {Boolean(isRoomCancelled) && (
                <>
                    <span
                        className={'icon icon-big-expand' + (isExpanded ? ' expanded' : '')}
                        onClick={() => setExpanded(false)}
                    />
                    <div className="status">
                        Status:{' '}
                        <span style={{ color: 'red' }}>
                            {isRoomAwaitingCancellation ? 'Awaiting Cancellation' : 'Cancelled'}
                        </span>
                    </div>
                </>
            )}

            {(!isRoomCancelled || isExpanded) && (
                <>
                    <ul className="allotment">
                        <li className="gray">
                            {room.passengers && (
                                <>
                                    <div className="primary">
                                        Guests (
                                        <PassengersCount
                                            adults={adultsCount}
                                            children={room.passengers.length - adultsCount}
                                        />
                                        )
                                    </div>
                                    <div className="additional guests">
                                        <div>
                                            <strong>
                                                1. <PassengerName passenger={room.passengers[0]} />
                                            </strong>
                                        </div>
                                        {room.passengers.slice(1).map((item, index) => (
                                            <div key={index}>
                                                {index + 2}. <PassengerNameAndAge passenger={item} />
                                            </div>
                                        ))}
                                    </div>
                                </>
                            )}
                        </li>
                        {!!booking.specialRequest && booking.isOffline ? (
                            <li className="important">
                                <div className="primary">Remarks</div>
                                <div className="additional">{booking.specialRequest}</div>
                            </li>
                        ) : null}
                        {!!details.agentReference && (
                            <li>
                                <div className="primary">Supplier Reference Code</div>
                                <div className="additional">{details.agentReference}</div>
                            </li>
                        )}
                    </ul>
                    <Allotment
                        room={room}
                        contract={details}
                        booking={booking}
                        checkInDate={details.checkInDate}
                        initiallyExpanded={isRoomCancelled}
                    />
                </>
            )}
        </div>
    );
};

export default BookingRoom;
