import React, { useState } from 'react';
import { API } from 'htcore';
import apiMethods from 'common/api-methods';
import { CachedForm, FieldTextarea } from 'components/form';
import { Loader } from 'components/simple';
import { observer } from 'mobx-react';
import ModalTemplate from 'common/template/modal-template';

const EditSpecialRequestModal = observer((props) => {
    const { booking, onSuccess } = props;

    const [loading, setLoading] = useState(false);

    const submit = (values) => {
        setLoading(true);
        API.post({
            url: apiMethods.BOOKING_SPECIAL_REQUEST(booking.bookingDetails.referenceCode),
            body: values,
            success: () => {
                onSuccess();
            },
            after: () => setLoading(false),
        });
    };

    if (!props.isOpen) return null;

    return (
        <ModalTemplate addClass="wide" {...props}>
            <h2>{booking.specialRequest ? 'Update Special Request to Hotel' : 'Special Request to Hotel'}</h2>

            {loading && <Loader page />}

            <CachedForm
                initialValues={{ specialRequest: booking.specialRequest }}
                onSubmit={submit}
                render={(formik) => (
                    <div className="form">
                        <div>
                            <FieldTextarea
                                formik={formik}
                                id="specialRequest"
                                label="Special Request"
                                placeholder="Please Enter Your Special Request Here"
                                maxLength={250}
                            />
                        </div>
                        <div style={{ marginTop: 12, marginRight: 30 }}>
                            Please note that the special request is not guaranteed from Happytravel.com side
                        </div>
                        <div className="bottom">
                            <button
                                className={'button main' + __class(!formik.values.specialRequest, 'disabled')}
                                disabled={!formik.values.specialRequest}
                                type="submit"
                            >
                                Update Special Request
                            </button>
                        </div>
                    </div>
                )}
            />
        </ModalTemplate>
    );
});

export default EditSpecialRequestModal;
