import React from 'react';
import { date } from 'simple';
import { $accommodation, $auth } from 'stores';

const SearchNothingFound = ({ resultCount, filters, clearFilters }) => (
    <div className="nothing-found" style={!resultCount && !filters ? { marginLeft: 0 } : {}}>
        {!!resultCount && filters ? (
            <>
                <h2>There are no results that match selected filters</h2>
                <div className="button" onClick={clearFilters}>
                    Clear Filters
                </div>
            </>
        ) : (
            <h2>No accommodations available</h2>
        )}
        <div className="head">
            <div className="title">
                <h3>Can't find what you're looking for?</h3>
                {!$auth.agencySettings.acceptLastMinuteBookings &&
                    $accommodation.search.request.checkInDate === date.format.apiDateOnly(new Date()) &&
                    !filters && (
                        <div>
                            Booking under the deadline, please contact your account manager and process the
                            search for arrival date starting tomorrow.
                        </div>
                    )}
                <div>
                    You can contact our Operations team directly, and they can help you with your
                    accommodations.
                </div>
                <div>
                    Email:{' '}
                    <a href="mailto:reservations@happytravel.com" className="link">
                        reservations@happytravel.com
                    </a>
                </div>
            </div>
        </div>
    </div>
);

export default SearchNothingFound;
