import React from 'react';
import ModalTemplate from 'common/template/modal-template';

const SignUpModalSuccess = (props) => {
    if (!props.isOpen) return null;

    return (
        <ModalTemplate {...props} addClass="auth-modal-background">
            <div style={{ textAlign: 'center', marginBottom: '40px' }}>
                <span className="icon icon-big-mail" />
            </div>
            <h2 style={{ textAlign: 'center' }}>Your request has been sent</h2>
            <div style={{ color: '#777', textAlign: 'center' }}>
                You already start the registration process. Our managers will contact you shortly to finish
                the registration process.
            </div>
            <div style={{ margin: '50px 0 0' }}>
                <button className="button main" onClick={props.onClose} style={{ width: '100%' }}>
                    Back to Main Page
                </button>
            </div>
        </ModalTemplate>
    );
};

export default SignUpModalSuccess;
