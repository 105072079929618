import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { observer } from 'mobx-react';
import CabinetNavigation from './cabinet-navigation';
import CabinetSecondNavigation from './cabinet-second-navigation';
import { loadAgency } from 'tasks/utils/agent-settings';
import { $auth } from 'stores';
import AccountSelector from './account-selector';

const CabinetHeader = observer(() => {
    const [accounts, setAccounts] = useState(null);

    useEffect(() => {
        if ($auth.permitted('ObserveBalance') && !$auth.agency) {
            loadAgency();
        }
    }, [$auth.permitted('ObserveBalance')]);

    return (
        <>
            <div className="cabinet-header">
                <section>
                    <div className="agent">
                        <div className="photo">
                            <div className="no-avatar" />
                        </div>
                        <div className="data">
                            <h1>
                                {$auth.information?.firstName} {$auth.information?.lastName}
                            </h1>
                            {Boolean($auth.activeAgency) && (
                                <div className="company section">
                                    <span className="name">{$auth.activeAgency.agencyName}</span>
                                    <b className={'status ' + $auth.activeAgency.agencyVerificationState} />
                                    {$auth.activeAgency.agencyVerificationState
                                        ?.replace(/([A-Z])/g, ' $1')
                                        .trim()}
                                </div>
                            )}
                            {$auth.permitted('ObserveBalance') && (
                                <div className="section">
                                    <AccountSelector accounts={accounts} setAccounts={setAccounts} />
                                </div>
                            )}
                        </div>
                    </div>
                    <div className="logout-wrapper">
                        <Link to="/logout" className="button">
                            <i className="icon icon-logout" />
                            Log out
                        </Link>
                    </div>
                </section>
                <CabinetNavigation accounts={accounts} />
            </div>
            <CabinetSecondNavigation accounts={accounts} />
        </>
    );
});

export default CabinetHeader;
