import { useEffect } from 'react';
import OidcInstance from '../oidc-instance';
import { authSetToStorage } from '../index';
import { useNavigate } from 'react-router-dom';
import { HTCore } from '../../base/init-application';

import { $notifications } from 'stores';

const AuthCallbackComponent = () => {
    const navigate = useNavigate();
    const Loader = HTCore.Loader;

    const onRedirectSuccess = (auth) => {
        OidcInstance().clearStaleState();
        authSetToStorage(auth);
        navigate(auth.state || '/search');
    };

    const onRedirectError = (err) => {
        let text = 'Unable to authorize, please contact support';
        if (err?.message) {
            text = err.message;

            if (text.indexOf('iat is in the future') >= 0) {
                text = 'Please check local device system date and time';
            }
        }
        $notifications.addNotification(text, 'Authorization Error');

        navigate('/search');
    };

    useEffect(() => {
        OidcInstance().signinRedirectCallback().then(onRedirectSuccess).catch(onRedirectError);
    }, []);

    return <Loader />;
};

export default AuthCallbackComponent;
