import React from 'react';
import { observer } from 'mobx-react';
import ViewFailed from 'common/misc/view-failed';
import { PAYMENT_METHODS } from 'enum';
import { $payment } from 'stores';

const PaymentInformation = observer(() => {
    if ($payment.paymentMethod !== PAYMENT_METHODS.CARD) return null;

    const { error, status } = $payment.paymentResult;

    if (error)
        return (
            <ViewFailed
                reason={
                    <>
                        Payment failed
                        <br />
                        {error}
                        <br />
                        <br />
                        Your payment did not go through
                    </>
                }
                button="Try to pay again"
                link={
                    $payment.paymentMethod === PAYMENT_METHODS.CARD
                        ? '/payment/form'
                        : '/accommodation/booking'
                }
            />
        );

    if (status)
        return (
            <div className="accent-frame">
                <div className="before">
                    <span className="icon icon-success" />
                </div>
                <div className="data">
                    <div className="first">
                        <div className="title">Payment Result</div>
                        <span className={'status ' + status}>{status || 'Unknown'}</span>
                    </div>
                </div>
            </div>
        );

    return null;
});

export default PaymentInformation;
