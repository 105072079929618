import React from 'react';
import { observer } from 'mobx-react';
import { Link } from 'react-router-dom';

const ViewFailed = observer(({ title = 'Oops! Something went wrong!', reason, button, link }) => (
    <div className="error-page inside">
        <div>
            <div className="text">
                <h1>{title}</h1>
                <p>{reason}</p>
            </div>

            {!!button && (
                <Link to={link}>
                    <span className="button">{button}</span>
                </Link>
            )}
        </div>
    </div>
));

export default ViewFailed;
