import React, { useEffect } from 'react';
import { createPortal } from 'react-dom';

const ModalTemplate = ({
    children,
    isOpen,
    withCross = true,
    onClose = () => null,
    addClass,
    authOverlay,
    withBlur = true,
}) => {
    useEffect(() => {
        const bodyClasses = document.getElementsByTagName('body')?.[0]?.classList;
        if (withBlur) {
            bodyClasses.toggle('modal-open', isOpen);
        }
        if (authOverlay) {
            bodyClasses.toggle('auth-modal-overlay', isOpen);
        }
        return () => {
            if (withBlur) {
                bodyClasses.toggle('modal-open', false);
                bodyClasses.toggle('auth-modal-overlay', false);
            }
        };
    }, [isOpen]);

    if (!isOpen) return null;

    return createPortal(
        <div className="modal-wrapper">
            <div className="overlay" onClick={onClose} />
            <div className="modal-scroll">
                <div className={'modal' + __class(addClass)}>
                    {withCross && (
                        <div className="close-button" onClick={onClose}>
                            <span className="icon icon-close" />
                        </div>
                    )}
                    {children}
                </div>
            </div>
        </div>,
        document.body
    );
};

export default ModalTemplate;
