import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import { NOTIFICATION_TYPES } from 'enum';
import NotificationList from './notification-list';
import { HttpTransportType, HubConnectionBuilder } from '@microsoft/signalr';
import settings from 'settings';
import { authorized } from 'htcore';
import { OidcInstance } from 'htcore/auth';
import { $notifications } from 'stores';

const NotificationsComponent = observer(() => {
    const [token, setToken] = useState(null);

    useEffect(() => {
        if (!authorized()) return;
        OidcInstance()
            .getUser()
            .then((user) => user?.access_token && setToken(user.access_token));
    }, [authorized()]);

    useEffect(() => {
        if (!token) return;

        try {
            window.notificationConnection = new HubConnectionBuilder()
                .withUrl(settings.edo + 'signalr/notifications/agents', {
                    accessTokenFactory: () => token,
                    skipNegotiation: true,
                    transport: HttpTransportType.WebSockets,
                })
                .build();

            window.notificationConnection.on('ReceiveMessage', (id, type, message) => {
                try {
                    if (
                        type in NOTIFICATION_TYPES &&
                        message &&
                        // todo: temporary disabled server notifications
                        ![
                            NOTIFICATION_TYPES.BookingFinalized,
                            NOTIFICATION_TYPES.BookingStatusChanged,
                            NOTIFICATION_TYPES.CreditCardPaymentReceived,
                            NOTIFICATION_TYPES.BookingInvoice,
                        ].includes(type)
                    ) {
                        $notifications.addServerNotification({
                            id,
                            type,
                            message,
                            sendingStatus: 0,
                            created: new Date(),
                        });
                    }
                } catch (e) {}
            });

            window.notificationConnection.start().catch(() => null);
        } catch (e) {}

        return () => {
            window.notificationConnection = null;
        };
    }, [token]);

    return <NotificationList />;
});

export default NotificationsComponent;
