import React, { useState, useEffect } from 'react';
import { observer } from 'mobx-react';
import { useNavigate } from 'react-router-dom';
import { useTitle } from 'htcore';
import SimpleHeader from 'common/template/header/simple-header';
import PageTemplate from 'common/template/page-template';
import { Loader } from 'components/simple';
import { loadInvitationData } from 'tasks/signup/signup-initialization';
import { registerAgent } from 'tasks/signup/signup-finish';
import { getAuthBlockStyle } from 'tasks/signup/signup-background';
import { getInvite, forgetInvite } from 'tasks/signup/invitation';
import SignUpAgentForm from './signup-agent-form';
import { $auth } from 'stores';

const SignUpPage = observer(() => {
    useTitle('Sign Up');
    const [loading, setLoading] = useState(false);
    const [invitationData, setInvitationData] = useState(null);
    const invitationCode = getInvite();
    const navigate = useNavigate();

    useEffect(() => {
        loadInvitationData(invitationCode, setInvitationData);
    }, []);

    const submitAgentForm = (agentForm) => {
        setLoading(true);
        registerAgent(agentForm, setLoading, invitationData, invitationCode, navigate);
    };

    if ($auth.information?.email) {
        forgetInvite();
        navigate('/search');
    }

    return (
        <PageTemplate AlternativeHeader={() => <></>}>
            <div className="account block" style={getAuthBlockStyle()}>
                <SimpleHeader />
                {loading && <Loader page />}
                <section className="section">
                    <SignUpAgentForm
                        initialValues={invitationData ? invitationData.userRegistrationInfo : {}}
                        submit={submitAgentForm}
                    />
                </section>
            </div>
        </PageTemplate>
    );
});

export default SignUpPage;
