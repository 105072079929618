import React, { useState } from 'react';
import { localStorage } from 'htcore';

const PREFIX = 'filters-';

const AccommodationFiltersExpandable = ({ children, title, id, isStatic }) => {
    const key = `${PREFIX}${id}`;

    const [isHidden, setHidden] = useState(localStorage.get(key) === 'true' || false);

    const handleClick = () => {
        if (isStatic) {
            return;
        }
        setHidden((value) => {
            const newValue = !value;
            localStorage.set(key, newValue);
            return newValue;
        });
    };

    return (
        <div>
            <h3 className="hide-desktop">{title}</h3>
            <h3 className={'clickable' + (isStatic ? ' static' : '')} onClick={handleClick}>
                {title}

                {!isStatic ? (
                    <span className={'icon icon-big-expand' + (!isHidden ? ' expanded' : '')} />
                ) : null}
            </h3>
            <div className="show-mobile" hidden={isHidden && !isStatic}>
                {children}
            </div>
        </div>
    );
};

export default AccommodationFiltersExpandable;
