import registrationAgentValidator, {
    emailFormValidator,
    registrationAgentValidatorWithEmail,
    registrationAgentValidatorWithEmailAndAgencyName,
} from './validator-registration-agent';
import registrationCompanyValidator from './validator-registration-company';
import accommodationSearchValidator from './validator-accommodation-search';
import accommodationBookingValidator from './validator-accommodation-booking';
import companyDetailsValidator from './validator-company-details';
import { creditCardValidator } from './validator-credit-card';
import confirmationExternalValidator from './confirmation-external-validator';
import billingDetailsValidator from './validator-billing-details';
import hotelConfirmationCodeValidator from './hotel-confirmation-code';

export {
    registrationAgentValidator,
    registrationAgentValidatorWithEmail,
    registrationAgentValidatorWithEmailAndAgencyName,
    registrationCompanyValidator,
    accommodationSearchValidator,
    accommodationBookingValidator,
    companyDetailsValidator,
    creditCardValidator,
    emailFormValidator,
    confirmationExternalValidator,
    billingDetailsValidator,
    hotelConfirmationCodeValidator,
};
