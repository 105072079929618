import { useState, useEffect } from 'react';

export default function useDropdown(holderEl, dropdownEl) {
    const [isOpen, setIsOpen] = useState(false);

    const toggleCurrent = (toggleState) => {
        setIsOpen(toggleState !== undefined ? Boolean(toggleState) : !isOpen);
    };

    const onWindowClick = (event) => {
        const clickOnHolder =
            holderEl.current &&
            (event.target === holderEl.current || holderEl.current.contains(event.target));
        const clickOnDropdown =
            dropdownEl.current &&
            (event.target === dropdownEl.current || dropdownEl.current.contains(event.target));
        if (!clickOnHolder && !clickOnDropdown) {
            toggleCurrent(false);
        }
    };

    const onEsc = (event) => {
        if (event.key === 'Escape') {
            toggleCurrent(false);
        }
    };

    useEffect(() => {
        if (isOpen) {
            setTimeout(() => {
                window.addEventListener('click', onWindowClick);
            }, 50);
            window.addEventListener('keyup', onEsc);
        }
        return () => {
            window.removeEventListener('click', onWindowClick);
            window.removeEventListener('keyup', onEsc);
        };
    }, [isOpen]);

    return [isOpen, toggleCurrent];
}
