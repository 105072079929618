import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { API, useTitle } from 'htcore';
import apiMethods from 'common/api-methods';
import Table from 'components/table/table';
import AccountStatementDownload from './account-statement-download';
import { Columns, Searches } from './table-data';
import { $auth } from 'stores';
import { date } from '../../../simple';

const AccountStatementPage = () => {
    useTitle('Account Statement');

    const { accountId } = useParams();
    const [history, setHistory] = useState(null);
    const [range, setRange] = useState([date.addMonth(new Date(), -3), new Date()]);

    const fetchBillingHistory = () => {
        if (!$auth.information?.agencyRelations?.length) return;
        if (!range?.[0] || !range?.[1]) {
            return;
        }

        setHistory(null);

        API.get({
            url: apiMethods.AGENCY_ACCOUNT(accountId),
            body: {
                $filter: `date ge ${date.format.api(range[0])} and date lt ${date.format.apiDateOnly(
                    range[1]
                )}T23:59:59Z`,
                $orderBy: 'date desc, created desc',
            },
            success: setHistory,
        });
    };

    useEffect(() => {
        fetchBillingHistory();
        document.body.className = 'big-modal-wrapper';

        return () => {
            document.body.className = '';
        };
    }, [accountId, range[0], range[1]]);

    return (
        <div className="management block payments-history">
            <section className="content">
                <Table
                    columns={Columns}
                    list={history}
                    textEmptyResult="You don`t have any payment history on this account"
                    searches={Searches}
                    CustomFilter={
                        <AccountStatementDownload range={range} setRange={setRange} accountId={accountId} />
                    }
                />
            </section>
        </div>
    );
};

export default AccountStatementPage;
