import React, { useState, useEffect } from 'react';
import { DayPicker } from 'react-day-picker';
import { $auth } from 'stores';
import { date } from 'simple';

import 'react-day-picker/dist/style.css';

const formatCaption = (day) => {
    let y = '';
    if (day.getFullYear() !== new Date().getFullYear()) {
        y = day.getFullYear();
    }
    const m = date.format.month(day);
    return `${m} ${y}`;
};

const DateDropdown = ({ close, options, setValue }) => {
    const [selected, setSelected] = useState(options);

    useEffect(() => {
        setSelected(options);
    }, [options]);

    const setDays = (result) => {
        if (result.to && result.from > result.to)
            result = {
                to: result.from,
                from: result.to,
            };
        setSelected(result);
        return [result.from, result.to];
    };

    const handleDayClick = (day, modifiers) => {
        if (modifiers.disabled) return;

        const { from, to } = selected;

        if (from && !to) {
            if (modifiers.start) return;
            setValue(
                setDays({
                    from,
                    to: day,
                })
            );
            close();
            return;
        }
        setDays({
            from: day,
            to: null,
        });
    };

    let disabledDays = [];
    if (options.future) {
        disabledDays = [
            {
                before: new Date(),
            },
        ];
    }
    if (options.past) {
        disabledDays = [
            {
                after: new Date(),
            },
        ];
    }
    if (options.disabledDates) {
        disabledDays = options.disabledDates;
    }

    return (
        <div className="date dropdown">
            <DayPicker
                mode="range"
                numberOfMonths={2}
                fromMonth={options.future ? new Date() : new Date('2019-01-01')}
                defaultMonth={selected.from}
                disabled={disabledDays}
                selected={selected}
                onDayClick={handleDayClick}
                formatters={{ formatCaption }}
                weekStartsOn={$auth.settings.weekStarts !== undefined ? $auth.settings.weekStarts % 7 : 1}
                modifiers={{
                    start: selected.from,
                    firstInRange: date.addDay(selected.from, 1),
                    lastInRange: date.addDay(selected.to, -1),
                }}
                modifiersClassNames={{
                    firstInRange: 'first-in-range',
                    lastInRange: 'last-in-range',
                }}
            />
        </div>
    );
};

export default DateDropdown;
