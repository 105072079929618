import React from 'react';
import { Link } from 'react-router-dom';
import { date } from 'simple';

export default ({ message }) => (
    <div className="content">
        <div className="style information">
            <i />
        </div>
        <div className="text">
            <h2>Booking {message.referenceCode} Deadline is Approaching</h2>
            <div>
                Reservation from {date.format.day(message.checkInDate)}
                <br />
                Cancellation deadline will pass at {date.format.day(message.deadline)}
            </div>
            <span>
                <Link to={`/booking/${message.referenceCode}`} target="_blank">
                    Open Booking
                </Link>
            </span>
        </div>
    </div>
);
