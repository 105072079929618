import React, { useState } from 'react';
import { getIn } from 'formik';
import { Range, getTrackBackground } from 'react-range';
import { price } from 'simple';

const formatLabel = (v, label, currency) => ('max' === label && v === 3000 ? 'Any' : price(currency, v));

const FieldRangeSlider = ({ formik, min, max, id, onChange, currency }) => {
    const [values, setValues] = useState(getIn(formik?.values, id) || { min, max });

    const changing = (newValues) => {
        setValues({ min: newValues[0], max: newValues[1] });

        if (formik) formik.setFieldValue(id, { min: newValues[0], max: newValues[1] });
    };

    const changeComplete = (newValues) => {
        changing(newValues);
        if (onChange) {
            onChange();
        }
    };

    return (
        <Range
            max={max}
            min={min}
            step={1}
            values={[values.min, values.max]}
            formatLabel={formatLabel}
            onChange={changing}
            onFinalChange={changeComplete}
            renderTrack={({ props, children }) => (
                <div className="input-range">
                    <span className="input-range__label input-range__label--min">
                        <span className="input-range__label-container">
                            {formatLabel(values.min, 'min', currency)}
                        </span>
                    </span>
                    <div
                        {...props}
                        className="input-range__track input-range__track--active"
                        style={
                            values
                                ? {
                                      background: getTrackBackground({
                                          min: min,
                                          max: max,
                                          colors: ['#f4f4f4', '#fdca00', '#f4f4f4'],
                                          values: [values.min, values.max],
                                      }),
                                  }
                                : null
                        }
                    >
                        {children}
                    </div>
                    <span className="input-range__label input-range__label--max">
                        <span className="input-range__label-container">
                            {formatLabel(values.max, 'max', currency)}
                        </span>
                    </span>
                </div>
            )}
            renderThumb={({ props }) => <div {...props} className="input-range__slider" />}
        />
    );
};

export default FieldRangeSlider;
