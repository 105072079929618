import React from 'react';
import { Link } from 'react-router-dom';
import { date, remapStatus } from 'simple';

export default ({ message }) => (
    <div className="with-photo">
        {message?.accommodationPhoto?.sourceUrl ? (
            <div className="photo">
                <Link to={`/booking/${message.referenceCode}`} target="_blank">
                    <img
                        src={message.accommodationPhoto.sourceUrl}
                        alt={message.accommodationPhoto.caption}
                    />
                </Link>
            </div>
        ) : (
            <div className="style information">
                <i />
            </div>
        )}
        <div className="text">
            <h2>Booking {message.referenceCode}</h2>
            <div>
                Reservation in {message?.accommodationName} from{' '}
                {date.format.day(date.day(message.checkInDate))}
                <br />
                Status changed to <b>{remapStatus(message.status)}</b>
            </div>
            <span>
                <Link to={`/booking/${message.referenceCode}`} target="_blank">
                    Open Booking
                </Link>
            </span>
        </div>
    </div>
);
