import React, { useState, useEffect } from 'react';
import { observer } from 'mobx-react';
import { API } from 'htcore';
import apiMethods from 'common/api-methods';
import { remapStatus } from 'simple';

const NotificationsReceived = observer(() => {
    const [notificationSettings, setNotificationSettings] = useState(null);

    useEffect(() => {
        API.get({
            url: apiMethods.NOTIFICATION_SETTINGS,
            success: setNotificationSettings,
        });
    }, []);

    if (!notificationSettings) {
        return null;
    }

    return (
        <div style={{ paddingTop: 40, lineHeight: 1.6 }}>
            <h2>Notifications that you receive</h2>
            <ul className="allotment">
                {Object.keys(notificationSettings).map((key) => (
                    <li key={key}>
                        {remapStatus(key)}{' '}
                        {notificationSettings[key].enabledProtocols?.Email &&
                            !notificationSettings[key].enabledProtocols?.WebSocket &&
                            '(Email only)'}
                        {!notificationSettings[key].enabledProtocols?.Email &&
                            notificationSettings[key].enabledProtocols?.WebSocket &&
                            '(Application only)'}
                    </li>
                ))}
            </ul>
        </div>
    );
});

export default NotificationsReceived;
