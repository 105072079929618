import React from 'react';
import Breadcrumbs from 'components/navigation/breadcrumbs';
import { CachedForm, FORM_NAMES } from 'components/form';
import { registrationAgentValidator } from 'components/form/validation';
import FormAgentData from 'pages/signup/form-agent-data';

const SignUpAgentForm = ({ initialValues, submit }) => (
    <div>
        <Breadcrumbs
            items={[
                {
                    text: 'Sign In',
                    link: '/logout',
                },
                {
                    text: 'Registration',
                    link: '/logout',
                },
                {
                    text: 'Agent Information',
                },
            ]}
            noBackButton
        />
        <h2>Agent Information</h2>
        <div className="paragraph">Create a free Happytravel.com account and start booking today</div>

        <CachedForm
            id={FORM_NAMES.RegistrationAgentForm}
            initialValues={initialValues}
            validationSchema={registrationAgentValidator}
            onSubmit={submit}
            render={(formik) => (
                <div className="form">
                    <FormAgentData formik={formik} />
                    <div className="row">
                        <div className="field">
                            <div className="inner">
                                <button type="submit" className="button main">
                                    Finish Registration
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        />
    </div>
);

export default SignUpAgentForm;
