import React, { useState, useEffect } from 'react';
import { observer } from 'mobx-react';
import { Formik } from 'formik';
import { useNavigate, useParams } from 'react-router-dom';
import { API, useTitle } from 'htcore';
import apiMethods from 'common/api-methods';
import { PassengerName } from 'simple';
import { Loader } from 'components/simple';
import Breadcrumbs from 'components/navigation/breadcrumbs';
import PermissionsSelector from './permissions-selector';
import AgentPaymentSettings from './parts/agent-payment-settings';
import { $auth } from 'stores';

const AgentManagement = observer(() => {
    useTitle('Agent Management');
    const [loading, setLoading] = useState(true);
    const [agent, setAgent] = useState({});
    const navigate = useNavigate();
    const { agentId } = useParams();
    const { inAgencyRoleIds } = agent;

    useEffect(() => {
        API.get({
            url: apiMethods.AGENCY_AGENT(agentId),
            success: (result) => {
                setAgent(result);
                setLoading(false);
            },
        });
    }, []);

    const enable = () => {
        API.post({
            url: apiMethods.AGENT_ENABLE(agentId),
            success: () =>
                setAgent({
                    ...agent,
                    isActive: true,
                }),
        });
    };

    const disable = () => {
        API.post({
            url: apiMethods.AGENT_DISABLE(agentId),
            success: () =>
                setAgent({
                    ...agent,
                    isActive: false,
                }),
        });
    };

    const submit = ({ roleIds }) => {
        setLoading(true);
        API.put({
            url: apiMethods.AGENT_ROLES(agentId),
            body: Object.keys(roleIds)
                .map((key) => (roleIds[key] ? parseInt(key) : false))
                .filter((item) => item),
            success: () => navigate('/settings/agency/agents'),
            after: () => setLoading(false),
        });
    };

    if (loading) {
        return <Loader />;
    }

    return (
        <div className="cabinet block">
            <section>
                <Breadcrumbs backLink="/settings/agency/agents" backText="Back to Agents List" />

                <div className="billet" style={{ marginTop: 50, maxWidth: 450 }}>
                    <h3>{PassengerName({ passenger: agent })}</h3>
                    <div className="row">
                        <b>Email</b>: {agent.email}
                    </div>
                    <div className="row">
                        <b>Position</b>: {agent.position}
                    </div>
                    <div className="row">
                        <b>Status</b>: {agent.isActive ? 'Active' : 'Inactive'}
                    </div>
                    {$auth.permitted('AgentStatusManagement') && $auth.information.id !== agent.agentId && (
                        <div>
                            {!agent.isActive ? (
                                <button className="button" onClick={enable}>
                                    Activate agent
                                </button>
                            ) : (
                                <button className="button" onClick={disable}>
                                    Deactivate agent
                                </button>
                            )}
                        </div>
                    )}
                </div>

                {$auth.permitted('PermissionManagement') && <AgentPaymentSettings agentId={agentId} />}

                {$auth.permitted('PermissionManagement') && (
                    <Formik
                        onSubmit={submit}
                        initialValues={{
                            roleIds: inAgencyRoleIds.reduce(
                                (obj, key) => ({ ...obj, [key]: inAgencyRoleIds.includes(key) }),
                                {}
                            ),
                        }}
                    >
                        {(formik) => (
                            <form onSubmit={formik.handleSubmit}>
                                <div className="form">
                                    <PermissionsSelector formik={formik} />
                                    <div className="row controls">
                                        <div className="field">
                                            <div className="label" />
                                            <div className="inner">
                                                <button
                                                    type="submit"
                                                    className={
                                                        'button button-controls' +
                                                        __class(!formik.dirty, 'disabled')
                                                    }
                                                    disabled={!formik.dirty}
                                                >
                                                    Save Agent Roles
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        )}
                    </Formik>
                )}
            </section>
        </div>
    );
});

export default AgentManagement;
