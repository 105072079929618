import { BOOKING_STATUS } from 'htcore/enum';

export const remapStatus = (status = '', templates) => {
    if (templates) return templates[status];

    status = String(status);

    return (
        {
            [BOOKING_STATUS.Pending]: 'Awaiting Confirmation',
            [BOOKING_STATUS.ManualCorrectionNeeded]: 'Awaiting Confirmation',
        }[status] || status.replace(/([A-Z])/g, ' $1').trim()
    );
};

export const remapStatusExtendedWithRooms = (status = '', rooms) => {
    if (status === BOOKING_STATUS.ManualCorrectionNeeded || rooms.some((room) => room.awaitingCancellation)) {
        return 'Awaiting Cancellation';
    }

    return remapStatus(status);
};
