import React from 'react';
import ModalTemplate from 'common/template/modal-template';
import OidcInstance from 'htcore/auth/oidc-instance';

const SignUpModal = (props) => {
    if (!props.isOpen) return null;

    const auth = () => {
        OidcInstance().signinRedirect();
    };

    return (
        <ModalTemplate {...props}>
            <h2>Dear Partner</h2>
            <div>
                If your agency is already registered, please contact your Administrator to receive
                credentials. If you are a new agency, please proceed with the registration process.
            </div>
            <div style={{ margin: '20px 0 35px' }}>
                <button className="button main" onClick={props.onClose} style={{ width: '100%' }}>
                    Yes, become a Partner
                </button>
            </div>
            <div style={{ color: '#777', textAlign: 'center' }}>
                Already have an account?{' '}
                <button className="link" onClick={auth} style={{ padding: 0 }}>
                    Sign In
                </button>
            </div>
        </ModalTemplate>
    );
};

export default SignUpModal;
