import React from 'react';
import { BOOKING_STATUS } from 'htcore/enum';
import { PassengersCount, date } from 'simple';

const BookingServiceRoom = ({ room, booking }) => {
    const isRoomCancelled = booking.bookingDetails.status === BOOKING_STATUS.Cancelled;
    const deadlineDate = booking?.bookingDetails?.deadlineDate;

    return (
        <div className={'room-container' + (isRoomCancelled ? ' cancelled-room' : '')}>
            <h2>
                {room._index && `Room ${room._index}: `}
                {room.roomName}
            </h2>

            {room.roomCode && <p className="room-code">Room Code: {room.roomCode}</p>}

            {isRoomCancelled && (
                <div className="status">
                    Status: <span style={{ color: 'red' }}>Cancelled</span>
                </div>
            )}

            {!isRoomCancelled && (
                <>
                    <ul className="allotment">
                        <li className="warn">
                            <div className="primary">Service Type</div>
                            <div className="additional">{booking?.bookingDetails?.serviceName}</div>
                        </li>

                        <li className="gray">
                            <div className="primary">Guests ({PassengersCount(room)})</div>
                            <div className="additional guests">
                                <div>
                                    <strong>
                                        {room.title} {room.firstName} {room.lastName}
                                    </strong>
                                </div>
                            </div>
                        </li>

                        {booking?.bookingDetails?.isNonRefundable ? (
                            <li className="warning">
                                <div className="primary">Non-Refundable</div>
                            </li>
                        ) : date.isFuture(deadlineDate) ? (
                            <li className={__class(true, 'warning', 'important')}>
                                <div className="primary">
                                    {deadlineDate
                                        ? 'Cancellation Deadline' +
                                          ': ' +
                                          date.format.deadline(
                                              deadlineDate,
                                              booking?.bookingDetails?.checkInDate
                                          )
                                        : 'FREE Cancellation - Without Prepayment'}
                                </div>
                            </li>
                        ) : (
                            <li className={__class(true, 'warning', 'important')}>
                                <div className="primary">
                                    Within Deadline: {date.format.deadline(deadlineDate)}
                                </div>
                            </li>
                        )}
                    </ul>
                </>
            )}
        </div>
    );
};

export default BookingServiceRoom;
