import React, { useEffect, useState } from 'react';
import { useTitle, API } from 'htcore';
import { Loader } from 'components/simple';
import { loadAgency } from 'tasks/utils/agent-settings';
import { $notifications } from 'stores';

const LogoManagement = () => {
    useTitle('Agency Logo Management');
    const [loading, setLoading] = useState(true);
    const [agency, setAgency] = useState(null);

    useEffect(() => {
        loadAgency((result) => {
            setLoading(false);
            setAgency(result);
        });
    }, []);

    const remove = () => {
        setLoading(true);
        API.delete({
            url: '/agency/logo',
            after: () => {
                loadAgency((result) => {
                    setLoading(false);
                    setAgency(result);
                });
            },
        });
    };

    const submit = (e) => {
        e.preventDefault();
        setLoading(true);
        API.post({
            url: '/agency/logo',
            formDataBody: new FormData(document.getElementById('formElem')),
            success: () => {
                loadAgency((result) => {
                    setLoading(false);
                    setAgency(result);
                });
                $notifications.addNotification('Agency Logo Uploaded!', null, 'success');
            },
            error: () => {
                setLoading(false);
            },
        });
    };

    if (loading) {
        return <Loader />;
    }

    return (
        <div className="cabinet block">
            <section>
                <h2>Agency Logo</h2>
                {agency?.logoUrl ? (
                    <>
                        <div className="box">
                            <img src={`${agency.logoUrl}?${Date.now()}`} alt={agency.name + ' Logo'} />
                        </div>
                        <div style={{ marginTop: 80 }}>
                            <button className="button small" onClick={remove}>
                                Remove Logo
                            </button>
                        </div>
                    </>
                ) : (
                    <>
                        <div style={{ marginBottom: 30 }}>
                            No agency logo uploaded. So you can upload it here.
                            <br />
                            Preferred size: 400x400
                        </div>
                        <div className="box">
                            <form id={'formElem'} onSubmit={submit}>
                                <label className="button file-upload">
                                    Upload Image
                                    <input type="file" name="file" accept="image/*" onChange={submit} />
                                </label>
                            </form>
                        </div>
                    </>
                )}
            </section>
        </div>
    );
};

export default LogoManagement;
