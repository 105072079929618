import React, { useState, useEffect } from 'react';
import FieldCountry from 'components/complex/field-country';
import { FieldCheckbox } from 'components/form';

const ResidencyDropdown = ({ formik }) => {
    const [same, setSame] = useState(true);

    useEffect(() => {
        if (formik.values.nationalityCode !== formik.values.residencyCode) setSame(false);
    }, []);

    return (
        <div className="residency dropdown">
            <FieldCountry
                formik={formik}
                id="nationality"
                anotherField="residency"
                label="Nationality"
                placeholder="Choose your nationality"
                forceAnotherField={same}
                clearable
            />
            <div hidden={same}>
                <FieldCountry
                    formik={formik}
                    id="residency"
                    anotherField="nationality"
                    label="Residency"
                    placeholder="Choose your residency"
                    clearable
                />
            </div>
            <div hidden={!same}>
                <FieldCheckbox onChange={setSame} value={true} label="Residency similar as Nationality" />
            </div>
        </div>
    );
};

export default ResidencyDropdown;
