import React from 'react';
import { observer } from 'mobx-react';
import { useTitle } from 'htcore';
import Breadcrumbs from 'components/navigation/breadcrumbs';
import PaymentInformation from './parts/payment-information';
import BookingConfirmationView from './parts/booking-confirmation-view';
import { $payment } from 'stores';

const AccommodationConfirmationPage = observer(() => {
    useTitle('Booking Confirmation');
    return (
        <div className="booking block">
            <section>
                {!$payment.paymentResult.error && (
                    <Breadcrumbs backLink="/bookings" backText="Back to Bookings List" />
                )}
                {$payment.paymentResult.error && <PaymentInformation />}
                {!$payment.paymentResult.error && (
                    <BookingConfirmationView
                        serviceCode={
                            $payment.subject?.referenceCode?.includes('MPS')
                                ? $payment.subject.referenceCode
                                : null
                        }
                        referenceCode={$payment.subject.referenceCode}
                        PaymentInformation={<PaymentInformation />}
                    />
                )}
            </section>
        </div>
    );
});

export default AccommodationConfirmationPage;
