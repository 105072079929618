import settings from 'settings';
import * as Sentry from '@sentry/react';

export const initSentry = () => {
    if (settings.sentry_dsn) {
        Sentry.init({
            dsn: settings.sentry_dsn,
            environment: settings.sentry_environment,
            ignoreErrors: ['"status":400', '"status":500'],
            integrations: [
                Sentry.browserTracingIntegration(),
                Sentry.replayIntegration({
                    maskAllText: false,
                    blockAllMedia: false,
                }),
                Sentry.replayCanvasIntegration(),
            ],
            // Performance Monitoring
            tracesSampleRate: 1.0, //  Capture 100% of the transactions
            // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
            tracePropagationTargets: ['localhost', 'https://hapytravel.com', 'https://dev.hapytravel.com'],
            // Session Replay
            replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
            replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
        });
    }
};
