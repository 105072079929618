import React, { useEffect } from 'react';
import { OidcInstance } from 'htcore/auth';
import { Loader } from 'components/simple';

const RegistrationResolver = () => {
    useEffect(() => {
        OidcInstance().signinRedirect({
            extraQueryParams: {
                redirectToRegister: true,
            },
        });
    }, []);

    return <Loader white page />;
};

export default RegistrationResolver;
