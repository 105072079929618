import React, { useEffect, useState } from 'react';
import { API, useTitle } from 'htcore';
import apiMethods from 'common/api-methods';
import { PAYMENT_PROCESSORS } from 'enum';
import { loadPayfortPaymentServiceData, payByForm } from 'tasks/payment/service';
import { observer } from 'mobx-react';
import { Loader } from 'components/simple';
import ReactTooltip from 'react-tooltip';
import Breadcrumbs from 'components/navigation/breadcrumbs';
import PaymentForm from './parts/payment-form';
import { $auth, $payment } from 'stores';

const getIsMealService = (refCode) => refCode.includes('MPS');

const PaymentPage = observer(() => {
    useTitle('Payment');
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const load = async () => {
            let processor = PAYMENT_PROCESSORS.Payfort;

            const { currency, amount } = $payment.subject.price;

            if (
                (currency === 'AED' && amount >= 49999) ||
                (currency === 'USD' && amount >= 13599) ||
                currency === 'EUR' ||
                currency === 'SAR' ||
                currency === 'QAR' ||
                currency === 'GBP'
            ) {
                processor = PAYMENT_PROCESSORS.Payfort;
            } else {
                processor = PAYMENT_PROCESSORS.PayTabs;
            }

            if (PAYMENT_PROCESSORS.Payfort === processor) {
                const service = await loadPayfortPaymentServiceData();
                $payment.setService(service, $payment.subject.referenceCode);
                setLoading(false);
            } else if (PAYMENT_PROCESSORS.NGenius === processor) {
                API.post({
                    url: apiMethods.NGENIUS_PAY(
                        $payment.subject.referenceCode,
                        getIsMealService($payment.subject.referenceCode)
                    ),
                    success: ({ paymentLink }) => {
                        window.location.href = paymentLink;
                    },
                });
            } else if (PAYMENT_PROCESSORS.PayTabs === processor) {
                API.post({
                    url: apiMethods.PAYTABS_PAY(
                        $payment.subject.referenceCode,
                        getIsMealService($payment.subject.referenceCode)
                    ),
                    // todo: remove this temporary workaround of information submition
                    body: {
                        email: $auth.information?.email,
                        firstName: $auth.information?.firstName,
                        lastName: $auth.information?.lastName,
                    },
                    success: ({ redirectUrl }) => {
                        window.location.href = redirectUrl;
                    },
                });
            }
        };
        load();
    }, []);

    if (loading) return <Loader white page />;

    return (
        <div className="payment block">
            <section>
                {$payment.subject.previousPaymentMethod && (
                    <Breadcrumbs
                        backText={'Back to ' + $payment.subject.referenceCode}
                        backLink={`/booking/${$payment.subject.referenceCode}`}
                    />
                )}
                {!$payment.subject.previousPaymentMethod && (
                    <Breadcrumbs backText="Back to Your Booking" backLink="/accommodation/booking" />
                )}

                <div className="accent-frame">
                    <div className="data only">
                        <strong>Please note:</strong>
                        When paying by card, we hold funds on your account until the deadline date approach.
                        In case of cancellation, funds will be released in accordance with the service
                        cancellation policy as soon as possible.
                    </div>
                </div>

                <h2>Please Enter Your Card Details</h2>
                <PaymentForm total={$payment.subject.price} pay={payByForm} />
            </section>
            <ReactTooltip place="top" type="dark" effect="solid" />
        </div>
    );
});

export default PaymentPage;
