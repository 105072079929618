import React, { useState } from 'react';
import { API, useTitle } from 'htcore';
import apiMethods from 'common/api-methods';
import { Loader } from 'components/simple';
import { copyToClipboard } from 'simple/logic';
import { CachedForm, FORM_NAMES, FieldText } from 'components/form';
import { registrationAgentValidatorWithEmail } from 'components/form/validation';
import FormAgentData from 'pages/signup/form-agent-data';
import { $ui, $auth } from 'stores';
import PermissionsSelector from './permissions-selector';

const InvitationSendPage = () => {
    useTitle('Invite an Agent');
    const [success, setSuccess] = useState(false);
    const [name, setName] = useState('');

    const submit = (values) => {
        setSuccess(null);
        API.post({
            url: values.send ? apiMethods.AGENT_INVITE_SEND : apiMethods.AGENT_INVITE_GENERATE,
            body: {
                agencyId: $auth.activeAgency.agencyId,
                registrationInfo: {
                    email: values.email,
                    firstName: values.firstName,
                    lastName: values.lastName,
                    position: values.position,
                    title: values.title,
                    mobilePhone: values.mobilePhone,
                },
                roleIds: Object.keys(values.roleIds)
                    .map((key) => (values.roleIds[key] ? parseInt(key) : false))
                    .filter((item) => item),
            },
            success: (data) => {
                $ui.dropFormCache(FORM_NAMES.CreateInviteForm);
                setSuccess(
                    values.send || !data
                        ? true
                        : window.location.origin + '/signup/invite/' + values.email + '/' + data
                );
                setName(
                    values.firstName || values.lastName ? values.firstName + ' ' + values.lastName : null
                );
            },
            error: () => setSuccess(false),
        });
        window.scrollTo(0, 0);
    };

    const submitButtonClick = (send, formik) => {
        formik.setFieldValue('send', send);
        formik.handleSubmit();
    };

    if (success === null) {
        return (
            <div className="cabinet block">
                <section>
                    <h2>Invite an Agent</h2>
                    <Loader />
                </section>
            </div>
        );
    }

    if (success) {
        return (
            <div className="cabinet block">
                <section>
                    <h2>Invite an Agent</h2>
                    <div>
                        {success === true ? (
                            <div>
                                {name ? <h3>Your invitation sent to {name}</h3> : <h3>Invitation sent</h3>}
                                <br />
                            </div>
                        ) : (
                            <div>
                                <div className="form">
                                    <h3>Send this link as an invitation</h3>
                                    <br />
                                    <FieldText value={success} />
                                </div>
                                <br />
                                <button
                                    className="button"
                                    style={{ marginBottom: 20 }}
                                    onClick={() => copyToClipboard(success)}
                                >
                                    Copy to Clipboard
                                </button>
                            </div>
                        )}
                        <button className="button" onClick={() => setSuccess(false)}>
                            Send one more invite
                        </button>
                    </div>
                </section>
            </div>
        );
    }

    return (
        <div className="cabinet block">
            <section>
                <h2>Invite an Agent</h2>
                <p style={{ marginBottom: 30 }}>
                    Invite someone to create a free Happytravel.com account and start booking today
                </p>
                <CachedForm
                    id={FORM_NAMES.CreateInviteForm}
                    initialValues={{
                        email: '',
                        title: '',
                        firstName: '',
                        lastName: '',
                        position: '',
                        roleIds: [],
                    }}
                    validationSchema={registrationAgentValidatorWithEmail}
                    onSubmit={submit}
                    render={(formik) => (
                        <div className="form agent-data">
                            <div className="row">
                                <FieldText
                                    formik={formik}
                                    id="email"
                                    label="Email"
                                    placeholder="Email"
                                    required
                                />
                            </div>
                            <FormAgentData formik={formik} />
                            <PermissionsSelector formik={formik} />
                            <div className="row" style={{ marginTop: 30, width: 'auto' }}>
                                <div className="field" style={{ width: '50%' }}>
                                    <div className="inner">
                                        <button
                                            onClick={() => submitButtonClick(true, formik)}
                                            className={'button' + __class(!formik.isValid, 'disabled')}
                                        >
                                            Send Invitation
                                        </button>
                                    </div>
                                </div>
                                <div className="field" style={{ width: '50%' }}>
                                    <div className="inner">
                                        <button
                                            onClick={() => submitButtonClick(false, formik)}
                                            className={'button' + __class(!formik.isValid, 'disabled')}
                                        >
                                            Generate Invitation Link
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}
                />
            </section>
        </div>
    );
};

export default InvitationSendPage;
