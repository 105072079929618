import React, { useEffect } from 'react';
import { FieldCheckbox } from 'components/form';
import ReactTooltip from 'react-tooltip';

const PERMISSION_DESCRIPTION = {
    Agency: {
        AgentInvitation: 'Invite Agents',
        ObserveAgencyInvitations: 'View All Agents Invitations',
        ObserveAgents: 'View Agents List',
        PermissionManagement: 'Manage Agent Roles and Agency Details',
        AgentStatusManagement: 'Deactivate Agents',
        ObserveAgencyContract: 'Download Agency Contract',
    },
    Booking: {
        AccommodationAvailabilitySearch: 'Search Accommodations',
        CreateBooking: 'Make a Reservation',
        ViewAgencyBookings: 'View Bookings of All Agents',
        ViewBooking: 'View Booking Actions History',
        ModifyBooking: 'Bookings Pay and Cancel',
        BookingDocuments: 'Send and Download Voucher and Proforma-Invoice',
    },
    Payments: {
        ObserveBalance: 'View Account Balance',
        ObservePaymentHistory: 'View Payment History',
    },
    Other: {
        ApiConnectionManagement: 'Create a New API Connection',
        ReceiveBookingSummary: 'Receive a List of Summary of Approaching Next Day Deadlines',
    },
};

const normalizeRoleName = (str) => str.split('&').join(' & ');
const temporarySorter = (a, b) => a.name.includes('API') - b.name.includes('API');
const temporaryDisabledCheckbox = (permissions, role) => role.name.includes('API') && !permissions.length;

const PermissionsSelectorElement = ({ formik, rolesList }) => {
    useEffect(() => {
        ReactTooltip.rebuild();
    }, [rolesList?.length]);

    const selectedPermissions = [
        ...new Set(
            rolesList
                .filter((role) => formik.values['roleIds'][role.id])
                .map((role) => role.permissions)
                .flat()
        ),
    ];

    const temporaryOnChange = (formik, newValue, roleId) => {
        if (newValue) return;
        if (
            !rolesList
                .filter((role) => formik.values['roleIds'][role.id])
                .filter((role) => !role.name.includes('API'))
                .filter((role) => role.id !== roleId).length
        ) {
            rolesList.forEach((role) => formik.setFieldValue('roleIds.' + role.id, false));
        }
    };

    return (
        <div className="roles-wrapper">
            <div className="roles" style={{ minWidth: 300 }}>
                <h2>Agent Roles</h2>
                {rolesList.sort(temporarySorter).map((role) => (
                    <div key={role.id}>
                        <div className="item" style={{ textTransform: 'capitalize' }}>
                            <FieldCheckbox
                                formik={formik}
                                id={'roleIds.' + role.id}
                                label={normalizeRoleName(role.name)}
                                disabled={temporaryDisabledCheckbox(selectedPermissions, role)}
                                onChange={(value) => temporaryOnChange(formik, value, role.id)}
                            />
                        </div>
                    </div>
                ))}
                <ReactTooltip place="top" type="dark" effect="solid" multiline={true} />
            </div>
            <div className="permissions">
                <h2>Agent will obtain next permissions</h2>
                <ul>
                    {Object.keys(PERMISSION_DESCRIPTION).map((group) => (
                        <li key={group}>
                            <span className="title">{group}</span>
                            <ul className="allotment">
                                {Object.keys(PERMISSION_DESCRIPTION[group]).map((permission) => (
                                    <li
                                        key={permission}
                                        className={selectedPermissions.includes(permission) ? null : 'gray'}
                                    >
                                        {PERMISSION_DESCRIPTION[group][permission]}
                                    </li>
                                ))}
                            </ul>
                        </li>
                    ))}
                </ul>
            </div>
        </div>
    );
};

export default PermissionsSelectorElement;
