import React from 'react';
import { API } from 'htcore';
import apiMethods from 'common/api-methods';
import { $ui } from 'stores';

export const initVersionAndStatics = () => {
    API.get({
        noAuth: true,
        url: apiMethods.BASE_VERSION,
        ignoreErrors: true,
        success: (result) => {
            if ($ui.currentAPIVersion !== result || !$ui.regions?.length) {
                API.get({
                    noAuth: true,
                    url: apiMethods.BASE_REGIONS,
                    success: (result) => $ui.setRegions(result),
                });
                API.get({
                    noAuth: true,
                    url: apiMethods.OUR_COMPANY,
                    success: (result) => $ui.setOurCompanyInfo(result),
                });
            }
            $ui.setCurrentAPIVersion(result);
        },
    });
};
