import { $payment } from 'stores';

export const directPaymentCallback = (data, error, navigate) => {
    if ('Secure3d' === data?.status) {
        window.location.href = data.secure3d;
        return;
    }

    $payment.setPaymentResult(
        data?.status,
        error ||
            (data?.status !== 'Invoiced' &&
                data?.status !== 'Success' &&
                data?.status !== 'Captured' &&
                data?.status !== 'Authorized' &&
                data?.message)
    );
    navigate('/pay/confirmation');
};
