import React from 'react';
import { observer } from 'mobx-react';
import { price } from 'simple';
import { NavLink, Route, Routes, Navigate } from 'react-router-dom';
import { $auth } from 'stores';

const CabinetSecondNavigation = observer(({ accounts }) => (
    <Routes>
        <Route path="/api/*" element={null} />
        <Route
            path="/agency/*"
            element={
                <div className="navigation cabinet-second-navigation linear">
                    <section>
                        <NavLink end to="/settings/agency">
                            Details
                        </NavLink>
                        {$auth.permitted('ObserveAgents') && (
                            <NavLink to="/settings/agency/agents">Agents</NavLink>
                        )}
                        {($auth.permitted('AgentInvitation') ||
                            $auth.permitted('ObserveAgencyInvitations')) && (
                            <NavLink to="/settings/agency/invitations">Invitations</NavLink>
                        )}
                        {$auth.permitted('ObserveAgencyContract') && (
                            <NavLink to="/settings/agency/logo">Agency Logo</NavLink>
                        )}
                    </section>
                </div>
            }
        />
        <Route
            path="/account/*"
            element={
                accounts?.length > 1 ? (
                    <div className="navigation cabinet-second-navigation linear">
                        <section>
                            {accounts.map(({ id, balance }) => (
                                <NavLink end to={`/settings/account/${id}`}>
                                    {price(balance)}
                                </NavLink>
                            ))}
                        </section>
                    </div>
                ) : null
            }
        />
        <Route
            path="/account"
            element={accounts?.[0]?.id ? <Navigate to={`/settings/account/${accounts[0].id}`} /> : null}
        />
        <Route
            path="/*"
            element={
                <div className="navigation cabinet-second-navigation linear">
                    <section>
                        <NavLink end to="/settings/agent">
                            Notifications
                        </NavLink>
                        <NavLink to="/settings/agent/application">Application Settings</NavLink>
                        <NavLink to="/settings/agent/personal">Personal Details</NavLink>
                    </section>
                </div>
            }
        />
    </Routes>
));

export default CabinetSecondNavigation;
