import React from 'react';
import { API } from 'htcore';
import apiMethods from 'common/api-methods';
import { loadAgentSettings } from 'tasks/utils/agent-settings';
import { $auth } from 'stores';

export const initAgent = (auth, navigate) => {
    $auth.setToken(auth.profile?.email);

    if (window.location.href.includes('/auth') || window.applicationInitialized) {
        return;
    }
    window.applicationInitialized = true;

    API.get({
        url: apiMethods.AGENT,
        ignoreErrors: true,
        success: (agent) => {
            if (agent?.email) {
                if (!agent.agencyRelations.length) {
                    navigate('/deactivated/agency'); // todo: API incorrect message
                    return null;
                }
                $auth.setInformation(agent);
                loadAgentSettings();
                API.get({
                    url: apiMethods.AGENCY_SETTINGS,
                    success: (result) => $auth.setAgencySettings(result),
                });
            }
        },
        after: (agent, error, response) => {
            if (!response) {
                return;
            }
            if (response.status === 400) {
                if ('Unable to Locate Agent User' === error?.detail) {
                    navigate('/signup/agent');
                }
                if ('Agent is deactivated' === error?.detail) {
                    navigate('/deactivated/agent');
                }
            }
        },
    });
};

export const destroyAgent = () => {
    $auth.setToken(null);
    $auth.setInformation(null);
};
