import React from 'react';
import ModalTemplate from 'common/template/modal-template';

const ReadOnlyModal = (props) => {
    if (!props.isOpen) return null;

    return (
        <ModalTemplate {...props}>
            <h2>We’re verifying your agency</h2>
            <div>
                Thank you for joining us at Happytravel.com. You do not yet have access to the app, but your
                request has been sent to one of our officers. Once it has been fulfilled, you will receive
                notification by email.
            </div>
        </ModalTemplate>
    );
};

export default ReadOnlyModal;
