import React from 'react';
import ModalTemplate from 'common/template/modal-template';

const SearchOverloadModal = (props) => {
    if (!props.isOpen) return null;

    return (
        <ModalTemplate {...props}>
            <h2>Would you like an exclusive offer?</h2>
            <div>
                You can contact our Operations team directly, and they can help you with your accommodations.
                <br />
                <br />
                Email:{' '}
                <a href="mailto:reservations@happytravel.com" className="link">
                    reservations@happytravel.com
                </a>
            </div>
        </ModalTemplate>
    );
};

export default SearchOverloadModal;
