import React from 'react';
import { createPortal } from 'react-dom';
import { observer } from 'mobx-react';
import NotificationItem from './notification-item';
import { $notifications } from 'stores';

const MAXIMUM_VISIBLE_NOTIFICATIONS = 3;

const NotificationList = observer(() => {
    if (!$notifications.list.length) return null;

    return createPortal(
        <div className="notifications fixed">
            <div className="wrapper">
                <div className="list">
                    {$notifications.list.map(
                        (notification, index) =>
                            $notifications.list.length - index <= MAXIMUM_VISIBLE_NOTIFICATIONS && (
                                <NotificationItem notification={notification} key={notification.id} />
                            )
                    )}
                </div>
            </div>
        </div>,
        document.body
    );
});

export default NotificationList;
