import React, { useEffect, useState } from 'react';
import { API, useTitle } from 'htcore';
import apiMethods from 'common/api-methods';
import { Loader } from 'components/simple';
import { date, remapStatus } from 'simple';
import { CachedForm, FieldSelect, FieldText, FieldTextarea } from 'components/form';
import { confirmationExternalValidator } from 'components/form/validation';
import { $notifications } from 'stores';
import { useParams } from 'react-router-dom';

const BookingExternalConfirmationPage = () => {
    useTitle('Confirm Booking Page');
    const [booking, setBooking] = useState(null);
    const [history, setHistory] = useState(null);
    const [loaded, setLoaded] = useState(null);
    const { code } = useParams();

    const load = () => {
        API.get({
            noAuth: true,
            url: apiMethods.PROPERTY_BOOKING(code),
            success: (data) => {
                setBooking(data);
                API.get({
                    noAuth: true,
                    url: apiMethods.PROPERTY_HISTORY(code),
                    success: setHistory,
                    after: () => {
                        setLoaded(true);
                    },
                });
            },
            error: () => {
                setLoaded(true);
            },
        });
    };

    useEffect(() => {
        load();
    }, []);

    const submit = (values, formik) => {
        API.put({
            noAuth: true,
            url: apiMethods.PROPERTY_BOOKING(code),
            body: {
                ...values,
                createdAt: date.format.apiTime(new Date()),
            },
            success: () => {
                load();
                formik.resetForm();
                formik.setErrors({});
                $notifications.addNotification('Booking Status Changed', 'Completed!', 'success');
            },
        });
    };

    if (!loaded) return <Loader page />;

    if (!booking)
        return (
            <div className="error-page inside">
                <div>
                    <div className="text">
                        <h1>Link is broken or expired</h1>
                        <p>Unable to load a booking confirmation</p>
                    </div>
                </div>
            </div>
        );

    return (
        <div className="booking block external">
            <section>
                <h1>{booking.referenceCode}</h1>
                <div className="short-summary">
                    <div>
                        Status: <b>{booking.status}</b>
                    </div>
                    <div>
                        Confirmation Code: <b>{booking.confirmationCode || 'None'}</b>
                    </div>
                </div>

                {!!history?.length && (
                    <div className="history-status" style={{ marginBottom: 70 }}>
                        <h2>History of Booking Status Changes</h2>
                        <ol reversed>
                            {history.map((item) => (
                                <li key={item.createdAt}>
                                    <b>{date.format.a(item.createdAt)}</b> at{' '}
                                    {date.format.time(item.createdAt)} changed to{' '}
                                    <b>{remapStatus(item.status)}</b> by <b>{item.initiator}</b>
                                    <br />
                                    {item.confirmationCode && (
                                        <>
                                            Confirmation Code: <b>{item.confirmationCode}</b>
                                            <br />
                                        </>
                                    )}
                                    {item.comment && (
                                        <>
                                            Commentary: {item.comment}
                                            <br />
                                        </>
                                    )}
                                </li>
                            ))}
                        </ol>
                    </div>
                )}

                {'Cancelled' !== booking.status && (
                    <>
                        <h2>Booking Management</h2>
                        <CachedForm
                            onSubmit={submit}
                            initialValues={{
                                confirmationCode: booking.confirmationCode,
                                status: booking.status,
                                initiator: '',
                                comment: '',
                            }}
                            validationSchema={confirmationExternalValidator}
                            render={(formik) => (
                                <div className="form" style={{ width: 400 }}>
                                    <div className="row">
                                        <FieldText
                                            formik={formik}
                                            id="confirmationCode"
                                            label="Confirmation Code"
                                            placeholder="Please Enter Booking Confirmation Code"
                                            required
                                        />
                                    </div>
                                    <div className="row">
                                        <FieldSelect
                                            formik={formik}
                                            id="status"
                                            label="New Status"
                                            placeholder="Please Select"
                                            required
                                            options={[
                                                { value: 'OnRequest', text: 'On Request' },
                                                { value: 'Amended', text: 'Amended' },
                                                { value: 'Confirmed', text: 'Confirmed' },
                                                { value: 'Cancelled', text: 'Cancelled' },
                                            ]}
                                        />
                                    </div>
                                    <div className="row">
                                        <FieldText
                                            formik={formik}
                                            id="initiator"
                                            label="Your Name"
                                            placeholder="Please Enter Your Name"
                                            required
                                        />
                                    </div>
                                    <div className="row">
                                        <FieldTextarea
                                            formik={formik}
                                            id="comment"
                                            label="Reason"
                                            placeholder="Please Enter any Commentary"
                                        />
                                    </div>
                                    <div className="row">
                                        <button type="submit" className="button" style={{ width: '100%' }}>
                                            Update Booking
                                        </button>
                                    </div>
                                </div>
                            )}
                        />
                    </>
                )}
            </section>
        </div>
    );
};

export default BookingExternalConfirmationPage;
