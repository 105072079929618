import React from 'react';
import { observer } from 'mobx-react';
import { date } from 'simple';
import { FieldCheckbox } from '../../../components/form';
import { $accommodation } from 'stores';

const AccommodationSearchRefundableFilter = observer(({ secondStep }) => {
    const { selected } = $accommodation;
    const { filters } = selected;
    const selectedAccommodation = $accommodation.selected.accommodation;

    if (
        !selectedAccommodation?.roomContractSets?.find(
            (set) =>
                set.roomContractSets.filter(
                    (item) =>
                        !item.isNonRefundable &&
                        (item.deadline.date ? date.isFuture(item.deadline.date) : true)
                ).length
        )
    ) {
        return null;
    }

    if (!secondStep) {
        return null;
    }

    const onChange = (value) => {
        const newFilters = JSON.parse(JSON.stringify(filters)) || {};
        newFilters.isOnlyRefundable = value;
        $accommodation.setSearchSelectedFilters(newFilters);
    };

    return (
        <div
            className="item"
            style={{
                display: 'inline-flex',
                alignItems: 'center',
                marginLeft: '10px',
            }}
        >
            <FieldCheckbox
                label="Refundable Rates Only"
                onChange={onChange}
                value={filters?.isOnlyRefundable}
            />
        </div>
    );
});

export default AccommodationSearchRefundableFilter;
