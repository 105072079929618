import React from 'react';
import { observer } from 'mobx-react';
import { Highlighted } from 'simple';
import { $auth } from 'stores';

const DestinationDropdown = observer(({ connected, focusIndex, setValue, options, value }) => {
    if (!options?.length) return null;

    return (
        <div className="dropdown" id={connected}>
            <div className="scroll">
                {options.map?.((item, index) => (
                    <React.Fragment key={index}>
                        <div
                            className={'line' + __class(focusIndex === index, 'focused')}
                            onClick={() => setValue(item)}
                        >
                            <Highlighted str={item.predictionText} highlight={value} />
                            {(__devEnv ||
                                __localhost ||
                                $auth.activeAgency?.agencyId === 3 ||
                                $auth.activeAgency?.agencyId === 503) && (
                                <>
                                    {item.htId.includes('Accommodation_') && (
                                        <a
                                            className="temporary-technical-button"
                                            onClick={(e) => {
                                                e.stopPropagation();
                                            }}
                                            href={'/htid/' + item.htId}
                                            target="_blank"
                                        >
                                            <i className="icon icon-info" /> Details
                                        </a>
                                    )}
                                </>
                            )}
                        </div>
                    </React.Fragment>
                ))}
            </div>
        </div>
    );
});

export default DestinationDropdown;
