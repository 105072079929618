import React from 'react';
import { authorized } from 'htcore';
import { observer } from 'mobx-react';
import { Link } from 'react-router-dom';
import OidcInstance from 'htcore/auth/oidc-instance';
import { $auth } from 'stores';

const AgentMenu = observer(() => {
    if (authorized()) {
        const agentName = ($auth.information?.firstName || '') + ' ' + ($auth.information?.lastName || '');
        return (
            <div className="agent-menu">
                {$auth.permitted('ViewAgencyBookings') && (
                    <Link className="button" to="/bookings">
                        Bookings
                    </Link>
                )}
                <Link to="/settings/agent" className="button agent-link" title={agentName}>
                    <span className="icon icon-burger" />
                    <span className="avatar" />
                </Link>
            </div>
        );
    }

    const auth = () => {
        OidcInstance().signinRedirect();
    };

    return (
        <div className="agent-menu">
            <Link className="button main" to="/signup">
                Join Us
            </Link>
            <button className="button" onClick={auth}>
                Sign In
            </button>
        </div>
    );
});

export default AgentMenu;
