import React from 'react';
import { $notifications } from 'stores';

const NotificationInternalItem = ({ notification }) => {
    const hideAlert = () => {
        $notifications.closeNotification(notification.id);
    };

    const { text, title, style, temporary, traceId } = notification;

    return (
        <div className={'item' + __class(style)}>
            <div className="content">
                {style && (
                    <div className="style">
                        <i />
                    </div>
                )}
                <div className="holder">
                    <div className="text">
                        {title && <h2>{title}</h2>}
                        <div>{text}</div>
                        {traceId && (
                            <div>
                                <a
                                    className="button small"
                                    href={`mailto:support@happytravel.com?subject=Error%20TraceId%20${traceId}&body=Do%20Not%20Edit%20Subject.`}
                                >
                                    Send Report Email
                                </a>
                            </div>
                        )}
                    </div>
                </div>
            </div>
            {temporary && (
                <div className="progress-timer">
                    <div className="bar" />
                </div>
            )}
            <div className="close-button" onClick={hideAlert}>
                <span className="icon icon-close" />
            </div>
        </div>
    );
};

export default NotificationInternalItem;
