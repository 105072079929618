import React from 'react';
import { Routes, Route } from 'react-router-dom';

import PaymentResultFirst from 'pages/payment/result-first';
import PaymentResultSecond from 'pages/payment/result-second';
import PaymentDirectLink from 'pages/payment/external/direct-link-page';
import PaymentDirectLinkConfirm from 'pages/payment/external/direct-link-confirmation';
import AcceptInvite from 'pages/signup/accept-invite';
import SignUpPage from 'pages/signup/signup';
import SignUpAgentPage from 'pages/signup/signup-agent';
import AccommodationExternalConfirmation from 'pages/public/confirmation-external';
import RegistrationResolver from 'pages/signup/registration-resolver';
import DeactivatedPage from 'pages/public/deactivated-page';
import ChangeEmailConfirmation from 'pages/public/change-email-confirmation';
import PrivateRoutes from './private-routes';

import '../../styles/index.sass';

const PublicRoutes = () => (
    <Routes>
        <Route path="/accommodation/confirmation/:code" element={<AccommodationExternalConfirmation />} />
        <Route path="/payment/result/:referenceCode" element={<PaymentResultFirst />} />
        <Route path="/payments/callback" element={<PaymentResultSecond />} />
        <Route path="/pay/confirmation" element={<PaymentDirectLinkConfirm />} />
        <Route path="/pay/:orderCode" element={<PaymentDirectLink />} />
        <Route path="/signup/invite/:email/:code" element={<AcceptInvite />} />
        <Route path="/signup" element={<SignUpPage />} />
        <Route path="/signup/agent" element={<SignUpAgentPage />} />
        <Route path="/registration" element={<RegistrationResolver />} />
        <Route path="/deactivated/:subject" element={<DeactivatedPage />} />
        <Route path="/change-email-confirmation/:code" element={<ChangeEmailConfirmation />} />
        <Route path="*" element={<PrivateRoutes />} />
    </Routes>
);

export default PublicRoutes;
