import React from 'react';
import { Link } from 'react-router-dom';
import { remapStatus } from 'simple';

export default ({ message }) => (
    <div className="content">
        <div className="style information">
            <i />
        </div>
        <div className="text">
            <h2>Booking {message.referenceCode} Invoice</h2>
            <div>
                {message?.invoiceItems?.[0] && (
                    <>
                        Reservation in {message.invoiceItems[0].accommodationName} invoice for{' '}
                        {message.invoiceItems[0].total} is ready
                        <br />
                    </>
                )}
                {!!message?.paymentStatus && (
                    <>
                        Payment Status is <b>{remapStatus(message.paymentStatus)}</b>
                    </>
                )}
            </div>
            <span>
                <Link to={`/booking/${message.referenceCode}`} target="_blank">
                    Open Booking
                </Link>
            </span>
        </div>
    </div>
);
