import React from 'react';
import { observer } from 'mobx-react';
import { HotelStars } from 'components/accommodation';
import Gallery from 'components/gallery';
import ModalTemplate from 'common/template/modal-template';
import Amenities from './amenities';
import { $accommodation } from 'stores';

const HotelDetailsModal = observer((props) => {
    const selectedAccommodation = $accommodation.selected.accommodation;
    const details = $accommodation.selected.accommodationFullDetails || selectedAccommodation?.accommodation;

    const description =
        details.textualDescriptions?.[0]?.description || details.textualDescriptions?.[1]?.description;

    return (
        <ModalTemplate {...props}>
            <div className="search-results block with-padding">
                <div className="head">
                    <div className="title">
                        <div className="request">
                            {details.location.locality}, {details.location.address}
                            {details.contacts?.phones?.length ? ', ' + details.contacts.phones[0] : ''}
                        </div>
                        <h1>{details.name}</h1>
                        <HotelStars count={details.rating} />
                    </div>
                </div>
                <div className="inner">
                    <Gallery pictures={details?.photos} />
                    <h2>Accommodation Information</h2>
                    <div className="text">
                        <div dangerouslySetInnerHTML={{ __html: description }} />
                    </div>

                    <Amenities hotel={details} />
                </div>
            </div>
        </ModalTemplate>
    );
});

export default HotelDetailsModal;
