import React, { useState } from 'react';
import { observer } from 'mobx-react';
import { useNavigate } from 'react-router-dom';
import { CachedForm, FORM_NAMES } from 'components/form';
import { accommodationSearchValidator } from 'components/form/validation';
import { searchSaveForm } from 'tasks/accommodation/search-create';
import { searchFormValuesCorrection } from 'tasks/accommodation/search-form-formatter';
import SearchFormFullsizePart from './search-form-fullsize';
import SearchFormShortPart from './search-form-short';
import ReadOnlyModal from './read-only-modal';
import { $auth, $ui } from 'stores';

const AccommodationSearchForm = observer(({ fullsize }) => {
    const [modalVisibility, setModalVisibility] = useState(false);
    const navigate = useNavigate();

    return (
        <div className="search block" style={$ui.itnMode && fullsize ? { marginTop: -40 } : null}>
            <div className="hide">{JSON.stringify($auth.settings)}</div>
            <CachedForm
                id={FORM_NAMES.SearchForm}
                initialValues={{
                    destination: '',
                    destinationInput: '',
                    residency: '',
                    residencyCode: '',
                    nationality: '',
                    nationalityCode: '',
                    checkInDate: null,
                    checkOutDate: null,
                    roomDetails: [
                        {
                            adultsNumber: 2,
                            childrenAges: [],
                        },
                    ],
                }}
                valuesOverwrite={searchFormValuesCorrection}
                validationSchema={accommodationSearchValidator}
                onSubmit={(values) => {
                    searchSaveForm(values, setModalVisibility, navigate);
                }}
                render={(formik) =>
                    !fullsize ? (
                        <SearchFormShortPart formik={formik} />
                    ) : (
                        <section>
                            <SearchFormFullsizePart formik={formik} />
                        </section>
                    )
                }
            />
            <ReadOnlyModal isOpen={modalVisibility} onClose={() => setModalVisibility(false)} />
        </div>
    );
});

export default AccommodationSearchForm;
