import { remapBoardBasis } from 'simple';

const getIsDifferentBoardPlans = (allRooms) => {
    const firstRoomPlan = allRooms?.[0]?.boardBasis;
    return allRooms?.find((room) => room?.boardBasis !== firstRoomPlan);
};

const MealPlan = ({ allRooms, room, short }) => {
    if (allRooms && getIsDifferentBoardPlans(allRooms)) {
        return 'Different Board Types';
    }
    if (!room?.boardBasis) {
        return null;
    }
    if ('NotSpecified' === room.boardBasis) {
        return room.mealPlan;
    }
    if ('RoomOnly' === room.boardBasis) {
        return remapBoardBasis(room.boardBasis);
    }
    if ('SelfCatering' === room.boardBasis) {
        return 'Self Catering';
    }
    if ('AllInclusive' === room.boardBasis) {
        return 'All-inclusive';
    }
    if ('NotSpecified' === room.boardBasis) {
        return 'Other';
    }
    if ((room.boardBasis || '').toLowerCase() === (room.mealPlan || '').toLowerCase()) {
        return room.mealPlan;
    }

    if (short) {
        if (room.boardBasis && 'NotSpecified' !== room.boardBasis) {
            return remapBoardBasis(room.boardBasis);
        }

        return room.mealPlan;
    }

    return [remapBoardBasis(room.boardBasis), room.mealPlan].filter((v) => v).join(' – ');
};

export default MealPlan;
