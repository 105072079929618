import { API } from 'htcore';
import apiMethods from 'common/api-methods';
import { countPassengers } from 'simple/logic';
import { SEARCH_STATUSES } from 'enum';
import { searchFormFormatter } from './search-form-formatter';
import { $accommodation, $auth, $notifications } from 'stores';

export const searchSaveForm = (values, setReadOnlyModal, navigate) => {
    $notifications.closeAllNotifications();

    if (!$auth.permitted('AccommodationAvailabilitySearch')) {
        setReadOnlyModal(true);
        return;
    }

    const body = searchFormFormatter(values);
    $accommodation.setNewSearchRequest({
        ...body,
        destination: values.destinationInput,
        adultsTotal: countPassengers(values, 'adultsNumber'),
        childrenTotal: countPassengers(values, 'childrenNumber'),
    });

    navigate('/search/results');
};

export const searchCreate = () => {
    if (!$accommodation.search.request) {
        return null;
    }
    const { destination, adultsTotal, childrenTotal, ...body } = $accommodation.search.request;
    return API.post({
        url: apiMethods.A_SEARCH_ONE_CREATE,
        body,
        success: (result) => $accommodation.setSearchId(result),
        error: () => $accommodation.updateSearchResultStatus({ taskState: SEARCH_STATUSES.BROKEN }),
    });
};
