import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import { API, useTitle } from 'htcore';
import apiMethods from 'common/api-methods';
import { windowLocalStorage } from 'htcore/misc/window-local-storage';
import SimpleHeader from 'common/template/header/simple-header';
import { Dual } from 'components/simple';
import { price } from 'simple';
import ViewFailed from 'common/misc/view-failed';
import { $payment } from 'stores';

const messageFormatter = (str) => str.split('+').join(' ');

const DirectLinkConfirmationPage = observer(() => {
    useTitle('Confirmation');
    const [booking, setBooking] = useState({});

    useEffect(() => {
        const code = windowLocalStorage.get($payment.subject.referenceCode);
        if (!code) {
            setBooking({ error: 'error' });
            return;
        }
        API.get({
            noAuth: true,
            url: apiMethods.DIRECT_LINK_PAY.GET_INFO(code),
            after: (result) => {
                setBooking(result || { error: 'error' });
            },
        });
    }, []);

    const { status, error } = $payment.paymentResult;
    const { subject } = $payment;

    return (
        <>
            <SimpleHeader />
            <div className="payment block">
                <section>
                    {'Invoiced' === status ||
                    'Success' === status ||
                    'Captured' === status ||
                    'Authorized' === status ? (
                        <>
                            <h2>Order has been paid successfully</h2>

                            <div className="accent-frame">
                                <div className="before">
                                    <span className="icon icon-success" />
                                </div>
                                <div className="data">
                                    <div className="first">
                                        <div>Order reference number</div>
                                        <span className="status Success">{subject.referenceCode}</span>
                                    </div>
                                    <div className="second">
                                        <div>Payment Result</div>
                                        <span className="status Success">{status}</span>
                                    </div>
                                </div>
                            </div>
                            <div className="part">
                                <div className="icon-holder">
                                    <span className="icon icon-confirmation-price" />
                                </div>
                                <div className="line">
                                    <Dual a="Amount" b={price(subject.price)} />
                                </div>
                            </div>
                            {booking.comment && (
                                <div className="part">
                                    <div className="line">
                                        <div className="icon-holder">
                                            <span className="icon icon-confirmation-hotel" />
                                        </div>
                                        <Dual
                                            className="line"
                                            a="Additional Information"
                                            b={booking.comment}
                                        />
                                    </div>
                                </div>
                            )}
                        </>
                    ) : (
                        <>
                            {error && (
                                <div className="accent-frame">
                                    <div className="before">
                                        <span className="icon icon-warning" />
                                    </div>
                                    <div className="data">
                                        <div className="first">
                                            Payment message
                                            <br />
                                            <strong>{messageFormatter(error)}</strong>
                                        </div>
                                    </div>
                                </div>
                            )}
                            <ViewFailed
                                reason={
                                    <>
                                        Payment failed
                                        <br />
                                        {error}
                                    </>
                                }
                                button="Try to pay again"
                                link={`/pay/${booking.code}`}
                            />
                        </>
                    )}
                </section>
            </div>
        </>
    );
});

export default DirectLinkConfirmationPage;
